import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsRoutine = {
  name: 'routine',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/routine`,
  title: 'What describes your ideal hair routine?',
  tip: {
    title: 'Why we ask:',
    content:
      'We’ll make sure your custom routine has the number of products and steps you actually enjoy doing every day—nothing more and nothing less.',
  },
  options: [
    {
      value: 'low',
      label: 'Streamlined & minimal',
    },
    {
      value: 'medium',
      label: 'More than basic, but nothing fancy',
    },
    {
      value: 'high',
      label: 'Long & luxurious',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_routine_effort,
  getAnswers: ({ selected }) => ({ diag_routine_effort: selected }),
};

export default myTreatmentsRoutine;
