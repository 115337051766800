import { MY_LIFESTYLE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

const vices = {
  name: 'vices',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/vices`,
  title: 'How would you describe your diet?',
  subtitle: 'Select all that apply.',
  options: [
    { label: 'I eat fast or processed food 3 times or more per week', value: 'processed_food' },
    { label: 'I drink alcohol 3 times or more per week', value: 'alcohol' },
    { label: 'I drink coffee 3 times or more per day', value: 'coffee' },
    { label: 'I eat sweets or dessert regularly', value: 'sweets' },
    { label: 'I smoke or am exposed to cigarette smoke regularly', value: 'smoke' },
    { label: 'None of these apply to me', value: 'none', unique: true },
  ],
  multi: true,
  tip: {
    title: 'Why we ask:',
    content:
      'We’re not here to judge, so be honest. We ask about these habits because they can lead to imbalances in our skin like dryness, breakouts, or premature aging.',
  },
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_vices,
  getAnswers: ({ selected }) => ({ profile_vices: selected }),
};

export default vices;
