import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  showConsentBanner: false,
};

const consentManagementSlice = createSlice({
  name: 'consentManagement',
  initialState,
  reducers: {
    onConsentBannerToggled(draft, action) {
      draft.showConsentBanner = action.payload;
    },
  },
});

export const { reducer, actions } = consentManagementSlice;
