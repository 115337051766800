import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

import type { InputValues } from 'Blocks/FormGift';

import type { dateToUtc } from 'utils/dateUtils';

import { setSelectedGift } from './actions';

// This shape is derived from the current usage of `values` in FormGift.tsx
// Probably, this type here should be a contract defined by/with API, rather than something derived from UI code only
export type CreateGiftPayload = {
  giftee_info: {
    email: InputValues['emailGiftee'];
    last_name: InputValues['lastnameGiftee'];
    first_name: InputValues['firstnameGiftee'];
  };
  message: InputValues['message'];
  enabled_at: ReturnType<typeof dateToUtc>;
  occasion: InputValues['occasion'];
  variant_slug: InputValues['selectedGiftCard'];
};

type GiftRawData = {
  pubkey: string;
};

const giftApiSlice = createApi({
  reducerPath: 'giftApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    createGiftMutation: build.mutation<GiftRawData, CreateGiftPayload>({
      query: (values) => ({
        url: `v1/gifts/`,
        method: 'POST',
        json: values,
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(setSelectedGift({ pubkey: data.pubkey }));
        });
      },
    }),
  }),
});

export default giftApiSlice;

export const { createGiftMutation } = giftApiSlice.endpoints;
