import { MY_LIFESTYLE } from '../../constants';
import * as consultationComponents from '../../constants/consultationComponents';
import { LIFESTYLE } from '../../constants/progressCategories';

const zipCode = {
  name: 'zipcode',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  title: 'Where will you be spending most of your time in the next 2 months?',
  subtitle:
    'Knowing your location helps us formulate products that shield your hair and skin from environmental stressors.',
  shouldBeIncluded: () => true,
  getInitialValue: ({ profile_zipcode, profile_country }) => ({
    profile_zipcode,
    profile_country,
  }),
  getAnswers: ({ profile_zipcode, profile_country }) => ({
    profile_zipcode,
    profile_country,
  }),
  Component: consultationComponents.ZipCodeContainer,
  skipSave: true, // The component will manage its own save strategy, we will just redirect
};

export default zipCode;
