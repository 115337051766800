import * as consultationComponents from '../../constants/consultationComponents';

import skincareIntroDesktop from 'assets/images/consultation/welcomeScreens/d-skin_welcome.jpg';
import skincareIntroMobile from 'assets/images/consultation/welcomeScreens/m-skin_welcome.jpg';
import skincareIntroTablet from 'assets/images/consultation/welcomeScreens/t-skin_welcome.jpg';

const skinWelcome = {
  name: 'welcome',
  route: `/consultation/skincare`,
  title: (
    <>
      We’re excited to get started on your <i>skin</i> consultation.
    </>
  ),
  subtitle:
    'We’ll ask a series of questions to better understand your lifestyle, environment, and unique skin needs so we can formulate your custom routine.',
  cta: 'Begin',
  image: {
    mobile: skincareIntroMobile,
    tablet: skincareIntroTablet,
    desktop: skincareIntroDesktop,
  },
  shouldBeIncluded: ({ isAuthenticated }) => !isAuthenticated,
  public: true,
  Component: consultationComponents.Welcome,
  skipSave: true,
};

export default skinWelcome;
