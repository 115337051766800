import * as consultationComponents from '../../constants/consultationComponents';
import { HAIR_SCALP } from '../../constants/progressCategories';

const signIn = {
  name: 'signin',
  progressCategory: HAIR_SCALP,
  shouldBeIncluded: ({ isAuthenticated }) => !isAuthenticated,
  public: true,
  Component: consultationComponents.SignIn,
  skipSave: true,
  // Content Specific to sign-up / sign-in
  title: "Before we get started, what's your name and email?",
  subtitle: 'We’ll use your email address to save your answers.',
  terms: (
    <>
      By clicking &apos;NEXT&apos; I acknowledge I have read and agree to the{' '}
      <a href="/terms" target="_blank">
        Prose End User License and Terms of Service
      </a>{' '}
      and{' '}
      <a href="/privacy" target="_blank">
        Privacy Policy
      </a>
    </>
  ),
  signinQuestion: 'Already gave us your email?',
  signinCta: 'Resume here.',
};

export default signIn;
