import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isFunction from 'lodash/fp/isFunction';

// we have this util since many state elements share the same shape
export const createApiSelectors = selector => [
  flow(selector, get('data')),
  flow(selector, get('error')),
  flow(selector, get('status')),
];

// fetchSomething -> creates a thunk that does the classical fetching sequence
export const fetchSomething =
  ([REQUEST, SUCCESS, FAILURE], fetch) =>
  (...params) =>
  async dispatch => {
    dispatch({ type: REQUEST });
    try {
      const data = await fetch(...params);
      dispatch({ type: SUCCESS, data });
    } catch (error) {
      dispatch({ type: FAILURE, error });
    }
  };

export const initialApiState = {
  status: 'idle',
  data: null,
  error: null,
};

// common reducer for simple api calls
export const apiReducer = ([REQUEST, SUCCESS, FAILURE], state = initialApiState, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case SUCCESS:
      return {
        ...state,
        status: 'success',
        data: action.data,
      };
    case FAILURE:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };
    default:
      return state;
  }
};

// This is used to create a reducer function from dictionnary of actionTypes to function
// (replacing classic switch case)
export const createReducer =
  (reducerToActionTypeMap = {}, initialState = {}) =>
  (state = isFunction(initialState) ? initialState() : initialState, { type, ...actionPayload }) =>
    reducerToActionTypeMap[type] ? reducerToActionTypeMap[type](state, actionPayload) : state;
