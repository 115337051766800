import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

const myHairGeneticHairLoss = {
  name: 'hairloss_genetic',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/genetic-hair-loss`,
  title: 'Is hair loss a genetic issue in your family?',
  tip: {
    title: 'Why we ask:',
    content: `We see hair loss as a multi-factorial issue, and will customize your supplements to address as many of the aggravating factors in your case as possible. We can't reverse genetic-based hair loss in one fell swoop, but we can give you the best possible foundation for healthy hair.`,
  },
  options: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
    { value: 'not_sure', label: `I'm not sure` },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_hairloss_genetic,
  getAnswers: ({ selected }) => ({ diag_hairloss_genetic: selected }),
};

export default myHairGeneticHairLoss;
