import myGoals from './questions/myGoals';
import myHairGeneticHairLoss from './questions/myHairGeneticHairLoss';
import myLifestyleDiet from './questions/myLifestyleDiet';
import myLifestyleHormones from './questions/myLifestyleHormones';
import myLifestyleOtherHairLossCauses from './questions/myLifestyleOtherHairLossCauses';
import hydration from './questions/shared/hydration';
import supplementsConsultationWelcome from './questions/supplementsConsultationWelcome';
import { MY_LIFESTYLE } from './constants';

const supplementQuestionSet = [
  supplementsConsultationWelcome,
  { ...myHairGeneticHairLoss, public: true },
  { ...myLifestyleHormones, public: true },
  { ...myLifestyleDiet, public: true },
  { ...hydration, route: `/consultation/haircare/${MY_LIFESTYLE}/hydration`, public: true },
  { ...myLifestyleOtherHairLossCauses, public: true },
  myGoals,
];

export default supplementQuestionSet;
