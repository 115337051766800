import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsColorTreatmentsType = {
  name: 'color-treatments-type',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/color-treatments-type`,
  multi: true,
  title: 'What kind of color do you get?',
  subtitle:
    'This helps us prevent and treat specific kinds of color-related damage. Select all that apply.',
  tip: {
    title: 'Not sure what you got?',
    content: `Certain types of color can lead to damage, dryness, and even breakage, Knowing what kind of color you get tells us what level of repair and protection you hair needs.

&nbsp;

**Bleach** is used to dramatically lighten your natural hair color, whether in part or in full. A lightning spray like Sun-In also counts.

&nbsp;

**Permanent color** is used to permanently cover your natural hair color. It offers 100% gray coverage, and is almost always used to darken hair, but some variants can slightly lighten it too.

&nbsp;

**Temporary color** is used to slightly change hair color, cover early grays, or neutralize brassy shades after bleaching. These can include gloss, glaze, toner, color-depositing haircare (like masks or conditioners), henna, or other semi-permanent options. These typically wash out in 1-5 washes.

&nbsp;

**Other** refers to henna rinses or paint-on hair makeup that washes out quickly, like colored chalks, waxes, etc.`,
  },
  options: [
    {
      value: 'bleach',
      label: 'Bleach',
      description: 'Your hair got lighter, e.g. ombre, balayage, highlights, double process',
    },
    {
      value: 'permanent',
      label: 'Permanent color',
      description:
        'Your hair either got darker, or slightly lighter e.g. grey coverage, lowlights, all-over color',
    },
    {
      value: 'temporary',
      label: 'Temporary color',
      description: 'Your hair got toned or glossed, then eventually washed out',
    },
    {
      value: 'others',
      label: 'Other',
      description: 'You used henna powder or hair-makeup that washes out',
    },
  ],
  // Dynamic logic
  shouldBeIncluded: ({ answers }) => answers.diag_color_treatments === true,
  getInitialValue: answers => answers.diag_color_treatments_type,
  getAnswers: ({ selected }) => ({
    diag_color_treatments_type: selected,
  }),
};

export default myTreatmentsColorTreatmentsType;
