import { useEffect } from 'react';

import { useAppSelector as useSelector } from 'dux/app/hooks';

import { isFlagsDoneFetching } from 'dux/featureFlags/selectors';

import onChoiceFunction from '../onChoice';

// Generic hook to use in specific AB test hooks below
const useOnChoice = ({
  isInExperiment,
  variantName,
  experimentConfig,
  isTrackingOnMount,
  flagsDoneFetching,
}) => {
  const onChoice = () => onChoiceFunction(experimentConfig, variantName);

  useEffect(() => {
    if (isTrackingOnMount && flagsDoneFetching && isInExperiment) {
      onChoice();
    }
  }, [isTrackingOnMount, isInExperiment, flagsDoneFetching]);

  return { onChoice };
};

export const createABTestHook =
  ({ isInExperimentSelector, variantNameSelector, experimentConfig }) =>
  ({ isTrackingOnMount } = {}) => {
    const flagsDoneFetching = useSelector(isFlagsDoneFetching);
    const isInExperiment = useSelector(isInExperimentSelector);
    const variantName = useSelector(variantNameSelector);

    const { onChoice } = useOnChoice({
      isInExperiment,
      variantName,
      experimentConfig,
      isTrackingOnMount,
      flagsDoneFetching,
    });

    return { onChoice, flagsDoneFetching, isInExperiment, variantName };
  };
