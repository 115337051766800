import { MY_PREFERENCES } from '../constants';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

const myPreferencesExceptions = {
  name: 'exceptions',
  category: MY_PREFERENCES,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/haircare/${MY_PREFERENCES}/exceptions`,
  multi: true,
  title: 'Any specific haircare ingredient preferences?',
  subtitle: (
    <>
      All Prose products are already free of parabens, mineral oils, sulfates, GMOs, and animal
      cruelty.
      <br />
      <br />
      Select all that apply
    </>
  ),
  tip: {
    title: 'What is silicone again?',
    content:
      'Silicone helps with hair detangling, softness, and smoothness. We use a professional, high-quality silicone that won’t leave any residue on your scalp or strands. Silicone is commonly found in hair care products. If you haven’t tried silicone-free products before, it may take a few weeks to get used to as it will give your hair a more natural touch/hair feeling.',
  },
  options: [
    { value: 'vegan', label: 'Vegan' },
    { value: 'siliconeFree', label: 'Silicone-free' },
    { value: 'none', label: 'No Thanks', unique: true },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => {
    // transform server fields to array of values
    const initialValue = [];

    if (answers.pref_vegan) {
      initialValue.push('vegan');
    }
    if (answers.pref_silicone === 'no') {
      initialValue.push('siliconeFree');
    }

    /*
     * `none` is a frontend only value for UX purpose, it translates
     * on the backend as pref_vegan === false && pref_silicone === 'no'
     */
    if (!answers.pref_vegan && answers.pref_silicone === 'yes') {
      initialValue.push('none');
    }
    return initialValue;
  },
  getAnswers: ({ selected }) => {
    // transform array of selection to multiple server fields
    let vegan = false;
    let silicone = 'yes';

    selected.forEach(item => {
      if (item === 'vegan') {
        vegan = true;
      } else if (item === 'siliconeFree') {
        silicone = 'no';
      }
    });

    return {
      pref_vegan: vegan,
      pref_silicone: silicone,
    };
  },
};

export default myPreferencesExceptions;
