import { MY_LIFESTYLE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

const hormones = {
  name: 'hormones',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/hormones`,
  title: 'Are you currently...',
  subtitle: (
    <>
      Changes in your body can impact your skin.
      <br /> Select all that apply.
    </>
  ),
  tip: {
    title: 'why we ask:',
    content: `
Changes in your hormone levels trigger changes in your skin throughout your lifetime. It’s important to know that there is no “normal” state for your skin, and it will continue to change as you age.

&nbsp;

We'll never share this data -- we just want to create a routine that's truly tailored to you.
`,
  },
  options: [
    {
      label: 'Pregnant, trying to get pregnant, or gave birth within the last 12 months',
      value: 'pregnancy',
    },
    { label: 'Experiencing menopause, perimenopause, or andropause', value: 'menopause' },
    { label: 'Diabetic or hypoglycemic', value: 'diabetic' },
    { label: 'None of the above', value: 'none', unique: true },
  ],
  multi: true,
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_hormones_skin,
  getAnswers: ({ selected }) => ({ profile_hormones_skin: selected }),
};

export default hormones;
