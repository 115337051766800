import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

import type { PrescriptionRaw } from './types';

const prescriptionApiSlice = createApi({
  reducerPath: 'prescriptionApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    prescriptionQuery: build.query<PrescriptionRaw, void>({
      query: () => ({
        url: `v1/account/hair_profile/prescription`,
        endpointName: 'prescriptionQuery',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default prescriptionApiSlice;

export const { prescriptionQuery } = prescriptionApiSlice.endpoints;
