// Type 1 - Straight
export const ONE = 'smooth';
// Type 2 - Wavy - Deprecated since styling-gel launch
export const WAVY = 'wavy';
// deprecated on backend but some user might still have it within their consultation
export const DEPRECATED_CURLY = 'curly';
// Type 2A - Soft Waves
export const TWO_A = 'type_2a';
// Type 2B - Wavy
export const TWO_B = 'type_2b';
// Type 2C - Deep Waves or Loose Curls
export const TWO_C = 'type_2c';

// Type 3A - Curly
export const THREE_A = 'type_3a';
// Type 3B - Very Curly
export const THREE_B = 'type_3b';
// Type 3C - Tight Curls
export const THREE_C = 'type_3c';

// Type 4A - Coily
export const VERY_CURLY = 'very_curly';
// Type 4B - Zig-zag Coils
export const FRIZZY = 'frizzy';
// Type 4C - Tight Coils
export const ZIGZAG = 'zigzag';

// export const DEPRECATED_WAVY = 'wavy';
