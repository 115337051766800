/* basic */
export const ON = 'on';
export const OFF = 'off';

/* in case of promotions */
export const CLOSED = 'closed';

/* in case of a/b test */
export const CONTROL = '0';
export const VARIANT1 = '1';
export const VARIANT2 = '2';
