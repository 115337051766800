import * as Sentry from '@sentry/nextjs';
import { HTTPError as KyError, type KyResponse } from 'ky';

import logSentryError from 'utils/logSentry';

import * as Auth from './Auth';
import { httpClient } from './HTTPClient';
import HTTPError, { isUnknownUser, type ServerErrorResponse } from './HTTPError';

type SignupParams = { first_name: string; last_name: string; email: string };

/**
 * refresh the access token, if needed
 */
export const refresh = async () => {
  const tokens = Auth.getTokens();
  const guestTokens = Auth.getGuestTokens();
  if (tokens === null && guestTokens == null) {
    throw new Auth.AuthError('Unauthenticated');
  }

  if (
    (!tokens?.refreshToken || Auth.isTokenExpired(tokens.refreshToken)) &&
    (!guestTokens?.refreshToken || Auth.isTokenExpired(guestTokens.refreshToken))
  ) {
    throw new Auth.AuthRefreshError('Cannot refresh token, must signin');
  }

  const params = {
    refresh:
      tokens && Auth.isTokenExpired(tokens.accessToken)
        ? tokens?.refreshToken
        : guestTokens?.refreshToken,
  };
  let response: KyResponse;
  try {
    response = await httpClient.post('v1/auth/token/refresh/', { json: params });
  } catch (err) {
    if (err instanceof Error) {
      logSentryError('[AuthClient] refresh', err);
    }
    throw new Auth.AuthRefreshError('Cannot refresh token, must signin', { cause: err });
  }

  const result = await response.json<Auth.JWTResponse>();
  if (tokens && Auth.isTokenExpired(tokens.accessToken)) {
    tokens.accessToken = result.access;
    Auth.setTokens(tokens);
    return { tokens, refreshed: true } as const;
  }
  if (guestTokens) {
    guestTokens.accessToken = result.access;
    Auth.setGuestTokens(guestTokens);
    return { tokens: guestTokens, refreshed: true } as const;
  }

  return { refreshed: false } as const;
};

/** try refreshing the access token, if needed */
export const tryRefresh = async () => {
  const tokens = Auth.getTokens();
  const guestTokens = Auth.getGuestTokens();

  if (
    (tokens?.refreshToken && Auth.isTokenExpired(tokens.accessToken)) ||
    (guestTokens?.refreshToken && Auth.isTokenExpired(guestTokens.accessToken))
  ) {
    try {
      return await refresh();
    } catch (error) {
      if (error instanceof Error) {
        logSentryError('[AuthService] tryRefresh', error);
      }
    }
  }

  return { refreshed: false } as const;
};

export const signup = async (params: SignupParams) => {
  const guestToken = Auth.getGuestTokens()?.accessToken;
  try {
    const response = await httpClient.post('v1/auth/signup/', {
      headers: { Authorization: guestToken ? `Bearer ${guestToken}` : undefined },
      json: params,
    });
    const result = await response.json<Auth.JWTResponse>();
    const auth: Auth.JWT = {
      accessToken: result.access,
      refreshToken: result.refresh,
    };
    Auth.setTokens(auth);
    return auth;
  } catch (error) {
    if (error instanceof Error) {
      logSentryError('[AuthService] signup', error);
    }
    if (error instanceof KyError) {
      const body = (await error.response.json()) as ServerErrorResponse;

      throw new HTTPError(body);
    }
    throw error;
  }
};

export const signout = Auth.clearTokens;

export const verifySigninToken = async (token: string) => {
  try {
    const response = await httpClient.post('v1/auth/signin/verify/', { json: { token } });
    const payload = await response.json<Auth.JWTResponse>();
    const auth = {
      accessToken: payload.access,
      refreshToken: payload.refresh,
    };

    Auth.setTokens(auth);
  } catch (error) {
    if (error instanceof Error) {
      logSentryError('[AuthService] verifySigninToken', error);
    }
  }
};

export const isAuthenticated = async () => {
  const auth = Auth.getTokens();
  if (auth === null) {
    return false;
  }
  if (!Auth.isTokenExpired(auth.accessToken)) {
    // Access token present and valid
    return true;
  }
  try {
    // refresh will throw if not authenticated
    await refresh();
    return true;
  } catch (_err) {
    return false;
  }
};

/*
 * Request magic link to be sent by email
 *
 * params = {
 *   username: the user email (mandatory)
 *   next: the url to redirect after authentication
 *   coupon: user coupon to recover
 * }
 *
 * returns String
 *  "success" on success
 *  "unknownUser" for inexistent emails
 *  "error" on other errors
 */
type MagicLinkRequestParams = {
  username?: string;
  next?: string;
  coupon?: string;
};
export const requestMagicLink = async (params: MagicLinkRequestParams = {}) => {
  // TODO: make username required once all calling sites have benn migrated to TypeScript
  if (!params.username) {
    Sentry.captureMessage('Called requestMagicLink without username param');
    return 'error';
  }
  const guestToken = Auth.getGuestTokens()?.accessToken;

  try {
    await httpClient.post('v1/auth/signin/', {
      headers: { Authorization: guestToken ? `Bearer ${guestToken}` : undefined },
      json: params,
    });
    return 'success';
  } catch (error) {
    if (isUnknownUser(error)) {
      return 'unknownUser';
    }

    logSentryError('[Auth Service] requestMagicLink', error);
    return 'error';
  }
};

export const postGuestUserSignup = async () => {
  const response = await httpClient.post('v1/auth/signup/guest/');
  const result = await response.json<Auth.JWTResponse>();
  const auth = {
    accessToken: result.access,
    refreshToken: result.refresh,
  };
  Auth.setGuestTokens(auth);
  return auth;
};
