import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

import image from 'assets/images/consultation/age/hairAgeQuestion.png';

const myHairAge = {
  name: 'age',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/age`,
  title: 'Let’s start personalizing your haircare',
  subtitle: 'From millions of possible formulas, only one is uniquely yours.',
  guideText: 'First, please select your age: ',
  descriptionImage: {
    alt: 'Prose haircare set',
    source: image,
    width: 117,
    height: 87,
  },
  tip: {
    title: 'Why we ask:',
    content: `Over the course of a lifetime, hair goes through changes in texture, density, and strength. We’ll use this info to give you extra support at any stage.

&nbsp;

*Please note our haircare products are not intended for use by individuals under the age of 18. These products have not been specifically formulated or tested for this age group.`,
    couldBounce: true,
  },
  options: [
    { value: '20', label: 'Under 30' },
    { value: '30', label: 'In my 30s' },
    { value: '40', label: 'In my 40s' },
    { value: '50', label: 'In my 50s' },
    { value: '60', label: 'In my 60s' },
    { value: '70', label: '70 or over' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_age_range,
  getAnswers: ({ selected }) => ({ profile_age_range: selected }),
};

export default myHairAge;
