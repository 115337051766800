import { MY_LIFESTYLE } from '../../constants';
import * as consultationComponents from '../../constants/consultationComponents';
import { LIFESTYLE } from '../../constants/progressCategories';

const diet = {
  name: 'diet',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/diet`,
  Component: consultationComponents.SkincareDiet,
  title: 'How would you describe your diet?',
  tip: {
    title: 'Why we ask:',
    content:
      'Everything you eat becomes a part of your body — and that includes your skin. Fresh, healthier foods help keep our skin looking and feeling its best. Our diet is also our main source of antioxidants, which are found in colorful produce like dark leafy greens, red bell peppers, and berries.',
  },
  labels: ['meat', 'dairy', 'seafood', 'vegetables & fruits', 'carbs & starch'],
  descriptions: {
    never: 'I __when__ eat __what__',
    sometimes: 'I eat __what__ __when__ a week or less',
    between: 'I eat __what__ __when__ a week',
    always: 'I eat __what__ __when__',
  },
  options: [
    { value: 'never', label: 'Never', summaryLabel: 'never' },
    { value: 'between_never_often', label: '', summaryLabel: '1-2 times' },
    { value: 'often', label: '3 times a week', summaryLabel: '3 times' },
    { value: 'between_often_every_day', label: '', summaryLabel: '5-6 times' },
    { value: 'every_day', label: 'Every day', summaryLabel: 'every day' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => [
    answers.profile_diet_meat,
    answers.profile_diet_dairy,
    answers.profile_diet_seafood,
    answers.profile_diet_fruit,
    answers.profile_diet_carbs,
  ],
  getAnswers: ({ selected }) => ({
    profile_diet_meat: selected[0],
    profile_diet_dairy: selected[1],
    profile_diet_seafood: selected[2],
    profile_diet_fruit: selected[3],
    profile_diet_carbs: selected[4],
  }),
};

export default diet;
