import { productsLabels, productsSlugs } from 'constants/products';

import aminoAcid from 'assets/images/ingredients/amino-acid.png';
import bakuchiol from 'assets/images/ingredients/bakuchiol.png';
import caviarLimeExtract from 'assets/images/ingredients/caviar-lime-extract.png';
import ceramides from 'assets/images/ingredients/ceramides.png';
import fermentedRiceWater from 'assets/images/ingredients/fermented-rice-water.png';
import hyaluronicAcid from 'assets/images/ingredients/hyaluronic-acid.png';
import mangoLeaf from 'assets/images/ingredients/mango-leaf.png';
import niacinamide from 'assets/images/ingredients/niacinamide.png';
import redAlgae from 'assets/images/ingredients/red-algae.png';
import redFungi from 'assets/images/ingredients/red-fungi.png';
import rosehipOil from 'assets/images/ingredients/rosehip-oil.png';
import squalane from 'assets/images/ingredients/squalane.png';
import vitaminE from 'assets/images/ingredients/vitamin-e.png';
import peptidesImg from 'assets/images/landingPages/skincare/peptides.png';
import vitaminCImg from 'assets/images/landingPages/skincare/vitamin_c.png';
import andyImg from 'assets/images/productPages/andy-sample-formula-card.jpg';
import dPanthenolImg from 'assets/images/productPages/ingredients/d_panthenol.png';
import ectoinImg from 'assets/images/productPages/ingredients/ectoin.png';
import glycerinImg from 'assets/images/productPages/ingredients/glycerin.png';
import seaLilyExtractImg from 'assets/images/productPages/ingredients/sea_lily_extract.png';
import xyltolImg from 'assets/images/productPages/ingredients/xylitol.png';
import kassieImg from 'assets/images/productPages/kassie-sample-formula-card.jpg';
import leslieImg from 'assets/images/productPages/leslie-sample-formula-card.jpg';

const ingredientsBySlugByProfile = {
  [productsSlugs.CLEANSER]: {
    andy: [
      {
        label: 'Bakuchiol',
        description: 'Helps minimize the appearance of wrinkles and fine lines',
        image: bakuchiol,
      },
      {
        label: 'Vitamin E',
        description: 'Helps protect skin cells from damage',
        image: vitaminE,
      },
      {
        label: 'Glycerin',
        description: 'Boosts hydration and supports water retention',
        image: glycerinImg,
      },
    ],
    kassie: [
      {
        label: 'Niacinamide',
        description: 'Helps improve elasticity and reduce the look of fine lines',
        image: niacinamide,
      },
      {
        label: 'Rosehip Oil',
        description: 'Nourishes the skin and evens out skin tone',
        image: rosehipOil,
      },
      {
        label: 'Caviar Lime',
        description: 'Gently exfoliates for a brighter complexion',
        image: caviarLimeExtract,
      },
    ],
    leslie: [
      {
        label: 'Aminoacid',
        description: 'Helps reduce blemishes',
        image: aminoAcid,
      },
      {
        label: 'Xylitol',
        description: 'Promotes hydration for a reinforced skin barrier',
        image: xyltolImg,
      },
      {
        label: 'D-Panthenol',
        description: 'Helps soothe the skin',
        image: dPanthenolImg,
      },
    ],
  },
  [productsSlugs.MOISTURIZER]: {
    andy: [
      {
        label: 'Ceramides',
        description: 'Hydrates and reinforces skin barrier',
        image: ceramides,
      },
      {
        label: 'Vitamin E',
        description: 'Helps protect skin cells from damage',
        image: vitaminE,
      },
      {
        label: 'Squalane',
        description: 'Helps minimize the appearance of wrinkles',
        image: squalane,
      },
    ],
    kassie: [
      {
        label: 'Vitamin C',
        description: 'Luminates skin and reduces dark spots',
        image: vitaminCImg,
      },
      {
        label: 'Red Fungi Extract',
        description: 'Shown to promote firmness',
        image: redFungi,
      },
      {
        label: 'Ectoin',
        description: 'Helps improve skin elasticity',
        image: ectoinImg,
      },
    ],
    leslie: [
      {
        label: 'Mango Leaf Extract',
        description: 'Helps minimize blemishes and controls shine',
        image: mangoLeaf,
      },
      {
        label: 'Fermented Rice',
        description: 'Helps soothe and minimize the look of redness',
        image: fermentedRiceWater,
      },
      {
        label: 'Amino Acid',
        description: 'Helps reduce blemishes',
        image: aminoAcid,
      },
    ],
  },
  [productsSlugs.SERUM]: {
    andy: [
      {
        label: 'Hyaluronic acid',
        description: 'Hydrates and helps skin look lifted',
        image: hyaluronicAcid,
      },
      {
        label: 'Red Fungi Extract',
        description: 'Shown to promote firmness',
        image: redFungi,
      },
      {
        label: 'Bakuchiol',
        description: 'Helps decrease the appearance of hyperpigmentation',
        image: bakuchiol,
      },
    ],
    kassie: [
      {
        label: 'Peptides',
        description: 'Supports a brighter, more even skin tone',
        image: peptidesImg,
      },
      {
        label: 'Vitamin C',
        description: "Helps protect the skin's reserves of collagen",
        image: vitaminCImg,
      },
      {
        label: 'Sea Lily Extract',
        description: 'Reduces the appearance of dark spots',
        image: seaLilyExtractImg,
      },
    ],
    leslie: [
      {
        label: 'Mango Leaf Extract',
        description: 'Helps minimize blemishes and controls shine',
        image: mangoLeaf,
      },
      {
        label: 'Amino Acid',
        description: 'Helps reduce blemishes',
        image: aminoAcid,
      },
      {
        label: 'Red Algae Extract',
        description: 'Helps soothe and reduce discomforts',
        image: redAlgae,
      },
    ],
  },
};

const cards = productSlug => ({
  subTitle: `MADE-TO-ORDER ${productsLabels[productSlug]} FORMULAS`,
  title: 'Customized for your skin',
  cards: [
    {
      title: 'Andy',
      description: (
        <>
          30 years old, <br />
          New York, 10013
        </>
      ),
      image: { src: andyImg, alt: 'Selfie of a man smiling' },
      concerns: 'Dryness, hyperpigmentation, fine lines and wrinkles',
      environment: 'Pollution, strong UV rays, low humidity',
      ingredients: ingredientsBySlugByProfile[productSlug].andy,
    },
    {
      title: 'Kassie',
      description: (
        <>
          32 years old, <br />
          Washington, 20001
        </>
      ),
      image: { src: kassieImg, alt: 'Selfie of a woman smiling' },
      concerns: 'Oily skin, hyperpigmentation, fine lines and wrinkles',
      environment: 'Low UV rays, hard water, seasonal humidity',
      ingredients: ingredientsBySlugByProfile[productSlug].kassie,
    },
    {
      title: 'Leslie',
      description: (
        <>
          29 years old, <br />
          Los Angeles, 91011
        </>
      ),
      image: { src: leslieImg, alt: 'Selfie of a woman smiling' },
      concerns: 'Oily skin, breakouts, sensitivity, redness',
      environment: 'Pollution, seasonal humidity, wind',
      ingredients: ingredientsBySlugByProfile[productSlug].leslie,
    },
  ],
});

export default cards;
