import blemishesAfter from 'assets/images/landingPages/skincare/ba-blemishes-after-cleanser.jpg';
import blemishesBefore from 'assets/images/landingPages/skincare/ba-blemishes-before-cleanser.jpg';
import darkSpotsAfter from 'assets/images/landingPages/skincare/ba-dark-spots-after-serum.jpg';
import darkSpotsBefore from 'assets/images/landingPages/skincare/ba-dark-spots-before-serum.jpg';
import fineLinesAfter from 'assets/images/landingPages/skincare/ba-fine-lines-after-serum.jpg';
import fineLinesBefore from 'assets/images/landingPages/skincare/ba-fine-lines-before-serum.jpg';
import formulaModuleSerumImg from 'assets/images/productPages/formulaModuleSerum.jpg';
import aminoAcidImg from 'assets/images/productPages/ingredients/aminoacid.png';
import bakuchiolImg from 'assets/images/productPages/ingredients/bakuchiol_2.png';
import hyaluronicAcidImg from 'assets/images/productPages/ingredients/hyaluronic_acid_2.png';
import mangoExtractImg from 'assets/images/productPages/ingredients/mango_extract.png';
import peptidesImg from 'assets/images/productPages/ingredients/peptides.png';
import redFunghiExtractImg from 'assets/images/productPages/ingredients/red_funghi_extract.png';
import redAlgaeExtractImg from 'assets/images/productPages/ingredients/red-algae.png';
import seaLilyExtractImg from 'assets/images/productPages/ingredients/sea_lily_extract.png';
import vitaminCImg from 'assets/images/productPages/ingredients/vitamin_c_2.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-serum.jpg';
import carouselImg2 from 'assets/images/productPages/serum_carousel_2.jpg';
import carouselImg3 from 'assets/images/productPages/serum_carousel_3.jpg';
import carouselImg4 from 'assets/images/productPages/serum_carousel_4.jpg';

import { expertsModule, scienceVideo } from '../landingPages/skincare';

const content = {
  expertsModule: {
    ...expertsModule,
    headlineDesktop: 'A serum developed by experts and loved by dermatologists',
    headlineMobile: `A serum developed by experts and
    loved by dermatologists`,
  },
  beforeAndAfter: {
    title: 'results you can see',
    subtitle: 'One Custom Cleanser for all of your concerns, including:',
    results: [
      {
        title: 'fine lines',
        beforeImage: fineLinesBefore,
        beforeImageAlt:
          'A close up on a man’s crows feet wrinkles before using Prose skin care lines',
        afterImage: fineLinesAfter,
        afterImageAlt:
          'A close up on a man’s crows feet wrinkles after using Prose skin care lines',
      },
      {
        title: 'blemishes',
        beforeImage: blemishesBefore,
        beforeImageAlt: 'A close up on a woman’s pimples before using skin care products by Prose',
        afterImage: blemishesAfter,
        afterImageAlt: 'A close up on a woman’s pimples after using skin care products by Prose',
      },
      {
        title: 'Dark spots',
        beforeImage: darkSpotsBefore,
        beforeImageAlt: 'A close up on a woman’s dry skin before using Prose skin care routine',
        afterImage: darkSpotsAfter,
        afterImageAlt:
          'A close up on a woman’s dry skin immediately after using Prose skin care routine',
      },
    ],
    disclaimer:
      'Individual results after 4 weeks of using Prose Cleanser, Serum, and Moisturizer twice a day - or 1 application of Prose Moisturizer. Results may vary',
  },
  metrics: scienceVideo,
  formulaModule: {
    header: 'A formula that couldn’t exist without you',
    description:
      'Formulated for your one-and-only skin, every product is made to order with clinically proven ingredients tailored to your unique concerns and goals.',
    hero: {
      imageDesktop: formulaModuleSerumImg,
      imageMobile: formulaModuleSerumImg,
      alt: 'woman with creative hair style',
    },
  },
  sampleFormulasSlider: [
    {
      title: 'formula no. 842414 for BRYSON',
      ingredients: [
        {
          image: hyaluronicAcidImg,
          name: 'Hyaluronic Acid',
          benefit: 'Hydrates and improves elasticity',
        },
        {
          image: redFunghiExtractImg,
          name: 'Red Funghi Extract',
          benefit: 'Improves appearance of firmness',
        },
        {
          image: bakuchiolImg,
          name: 'Bakuchiol',
          benefit: 'Balances complexion',
        },
      ],
    },
    {
      title: 'formula no. 842415 for HANNAH',
      ingredients: [
        {
          image: mangoExtractImg,
          name: 'Mango Extract',
          benefit: 'Minimizes blemishes and controls shine',
        },
        {
          image: aminoAcidImg,
          name: 'Amino Acid',
          benefit: 'Helps reduce hardening of post-blemish skin',
        },
        {
          image: redAlgaeExtractImg,
          name: 'Red Algae Extract',
          benefit: 'Soothes and reduces discomfort',
        },
      ],
    },
    {
      title: 'formula no. 842412 for JOELLE',
      ingredients: [
        {
          image: peptidesImg,
          name: 'Peptides',
          benefit: 'Supports a brighter skin tone',
        },
        {
          image: vitaminCImg,
          name: 'Vitamin C',
          benefit: 'Protects skin’s reserves of collagen',
        },
        {
          image: seaLilyExtractImg,
          name: 'Sea Lily Extract',
          benefit: 'Reduces apearance of dark spots',
        },
      ],
    },
  ],
  faq: {
    title: 'Frequently asked questions',
    questions: [
      {
        question: 'How do I use my custom serum?',
        answer: `Your custom serum can be used twice daily, as part of your morning and night routine. Warm 2-3 drops between hands, then apply evenly to face and neck.`,
      },
      {
        question: 'Is a serum necessary for my skincare routine?',
        answer: `Your Prose serum is formulated with a high concentration of active ingredients for optimal penetration through your skin barrier. Tailored to your individual skin concerns as determined by your consultation, your serum plays a critical role in supporting your skin health. Please note that your Prose serum is not medical-grade or intended to treat any skin conditions. We recommend consulting with your doctor if you're looking to treat a specific condition.`,
      },
      {
        question: 'What can I expect from the serum texture?',
        answer: `Your custom serum will have a lightweight texture that quickly absorbs into the skin for a silky smooth feeling.`,
      },
      {
        question: `Is the packaging recyclable?`,
        answer: `Our packaging primarily consists of lightweight glass bottles and minimal plastic. The bottle is recyclable and we encourage you to reuse your serum dropper for future refills!`,
      },
      {
        question: 'What are my serum fragrance options?',
        answer: (
          <>
            You can choose a fragrance during your consultation — or you can choose to make your
            formula fragrance-free, which we recommend for those with skin sensitivity.
            <br />
            <br />
            Please note that fragrance-free formulas may still contain a slight scent due to the
            active ingredients in your formulas, as we don&apos;t use fragrance-masking additives.
          </>
        ),
      },
      {
        question: 'What is the pH level of my custom serum?',
        answer: `Prose serums are formulated at a pH level that's similar to the skin's natural pH level, making them optimal for proper skin barrier function, hydration, and cell turnover.`,
      },
      {
        question: 'What if my product looks different from what’s on the website?',
        answer:
          'Because Prose products are truly custom, each formula is unique. This means that color and scent can vary slightly from formula to formula, even if you choose fragrance-free. For example, your moisturizer might appear white or slightly beige, depending on the active ingredients — but no matter the color, your products are always made to order according to our stringent safety and quality standards.',
      },
    ],
  },
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom serum packaging',
    },
    {
      image: carouselImg2,
      alt: 'Single Prose custom serum with liquid',
    },
    {
      image: carouselImg3,
      alt: 'Someone using Prose custom serum on the face',
    },
    {
      image: carouselImg4,
      alt: 'Prose custom serum eyedropper',
    },
  ],
  numbersModule: {
    title: 'Always-custom, one of a kind results',
    numbers: [
      { number: '94%', label: '94% say their skin barrier feels stronger*' },
      { number: '91%', label: 'say their serum increases skin firmness*' },
      { number: '90%', label: 'say their skin is glowy*' },
      { number: '88%', label: 'say their complexion is evened out*' },
      { number: '82%', label: 'say the appearance of wrinkles is reduced*' },
      { number: '82%', label: 'say the appearance of blemishes is reduced*' },
    ],
    disclaimer:
      '*Based on a 4-week use test of 72 people, ages 25 to 70 with sensitive skin, who used Prose Serum twice a day.',
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Serum is rigorously researched, backed by science, and precisely selected to target your unique skin goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    list: [
      'Vegan',
      'Cruelty-free',
      'Silicone-free',
      'Alcohol-free',
      'Paraben-free',
      'Mineral oil-free',
      'Phthalate-free',
      'GMO-free',
      'Available fragrance-free',
    ],
    title: 'A serum with naturally powerful + proven-effective ingredients',
  },
};

export default content;
