import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import { removeAppendedVersion } from 'utils/removeAppendedVersion';

const getGiftWithPurchaseState = (state: RootState) => state.giftWithPurchase;

export const getGiftWithPurchaseListLoading = createSelector(
  getGiftWithPurchaseState,
  (state) => state.isLoading,
);

export const getGiftWithPurchaseError = createSelector(
  getGiftWithPurchaseState,
  (state) => state.error,
);

export const getGiftWithPurchaseList = createSelector(
  getGiftWithPurchaseState,
  (state) => state.list ?? [],
);

export const getIsGiftWithPurchaseListLoaded = createSelector(
  getGiftWithPurchaseState,
  (state) => state.list !== null,
);

export const getFirstActiveGiftWithPurchaseBannerData = createSelector(
  getGiftWithPurchaseList,
  (giftWithPurchaseList) => giftWithPurchaseList.find((gwp) => gwp.should_show_banner),
);

export const getIsGiftWithPurchaseBannerActive = createSelector(
  getFirstActiveGiftWithPurchaseBannerData,
  (firstActiveGiftWithPurchaseBannerData) => Boolean(firstActiveGiftWithPurchaseBannerData),
);

export const getIsGiftWithPurchaseActive = createSelector(
  [getGiftWithPurchaseList, (_state, offeredVariantType: string) => offeredVariantType],
  (giftWithPurchaseList, offeredVariantType) =>
    giftWithPurchaseList.some((gwp) => gwp.offered_variant_type === offeredVariantType),
);

export const getGiftWithPurchaseByOfferedVariantType = createSelector(
  [getGiftWithPurchaseList, (_state, offeredVariantType: string) => offeredVariantType],
  (giftWithPurchaseList, offeredVariantType) =>
    giftWithPurchaseList.find(
      (gwp) =>
        removeAppendedVersion(offeredVariantType) ===
        removeAppendedVersion(gwp?.offered_variant_type),
    ),
);
