const geolocationCodeToLocale = {
  US: 'en-US',
  CA: 'en-CA',
};

export const formatPriceWithCurrency = ({
  currency,
  currencyDisplay = 'symbol',
  locale = geolocationCodeToLocale.US,
  minimumFractionDigits = 2,
  price,
  style = 'currency',
}) => {
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay,
    minimumFractionDigits,
    style,
  });
  return formatter.format(price);
};
