import PropTypes from 'prop-types';

export const variantProps = PropTypes.oneOf([
  'roundedBlack',
  'sorbet',
  'white',
  'whiteFull',
  'noir',
  'vertFull',
  'whiteBg',
  'vert',
  'vertOutline',
  'lime',
  'rouge',
  'rougeOutline',
  'underlined',
  'underlinedBlack',
  'underlinedWhite',
  'add',
  'tab',
  'tabIsActive',
  'yellow',
  'none',
  'roundedBlack',
  'roundedPeach',
]);
