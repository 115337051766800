import { useEffect } from 'react';

import { useAppSelector } from 'dux/app/hooks';

import * as TagSegmentService from 'Services/TagSegmentService';

import { usePostPurchasePopupAbTest } from 'abTesting/postPurchasePopup/hook';

import trialOfferGwpDecemberContent from 'assets/content/trialOfferCombGwpPromotionModal';
import trialOfferGwpHeatlessCurlSetBlackFridaySetContent from 'assets/content/trialOfferHeatlessCurlerSetBlackFridayGwpPromotionModal';
import trialOfferGwpHeatlessCurlSetContent from 'assets/content/trialOfferHeatlessCurlerSetGwpPromotionModal';
import trialOfferContent from 'assets/content/trialOfferPromotionModal';
import trialOfferGwpSummerSaleContent from 'assets/content/trialOfferSummerSaleGwpPromotionModal';
import trialOfferGwpToiletry from 'assets/content/trialOfferToiletryGWPPromotionModal';
import trialOfferGwpTravelPouchesContent from 'assets/content/trialOfferTravelPouchesGwpPromotionModal';
import trialOfferToiletryAndCombGwpPromotionContent from 'assets/content/trialOfferTravelToiletryBagAndCombGwpPromotionModal';

import {
  getTrialOfferFlagVariant,
  isFlagsDoneFetching as getIsFlagsDoneFetching,
  shouldShowTrialOffer,
  trialOfferCombGwp,
  trialOfferEvergreen,
  trialOfferGwpSummerSale,
  trialOfferHeatlessCurlSetBlackFridaySetGwp,
  trialOfferHeatlessCurlSetGwp,
  trialOfferToiletryAndCombGwp,
  trialOfferToiletryGwp,
  trialOfferTravelPouchesGwp,
} from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';
import * as flagVariants from 'dux/featureFlags/constants/flagVariants';

const trialOfferVariantContent = {
  [trialOfferEvergreen]: trialOfferContent,
  [trialOfferCombGwp]: trialOfferGwpDecemberContent,
  [trialOfferToiletryGwp]: trialOfferGwpToiletry,
  [trialOfferTravelPouchesGwp]: trialOfferGwpTravelPouchesContent,
  [trialOfferToiletryAndCombGwp]: trialOfferToiletryAndCombGwpPromotionContent,
  [trialOfferGwpSummerSale]: trialOfferGwpSummerSaleContent,
  [trialOfferHeatlessCurlSetBlackFridaySetGwp]: trialOfferGwpHeatlessCurlSetBlackFridaySetContent,
  [trialOfferHeatlessCurlSetGwp]: trialOfferGwpHeatlessCurlSetContent,
};

export const useTrialOfferPromotionModalContent = () => {
  const hasHaircareSubscriptonInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const hasSkincareSubscriptonInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);
  const variant = useAppSelector(getTrialOfferFlagVariant);
  const { variantName: postPurchasePopupVariant } = usePostPurchasePopupAbTest({
    isTrackingOnMount: true,
  });

  const content =
    postPurchasePopupVariant === flagVariants.CONTROL
      ? trialOfferContent
      : trialOfferVariantContent[variant];

  return {
    content:
      !hasHaircareSubscriptonInAnyState && !hasSkincareSubscriptonInAnyState
        ? content?.contentDefault
        : hasHaircareSubscriptonInAnyState && !hasSkincareSubscriptonInAnyState
        ? content?.contentSkincare
        : hasSkincareSubscriptonInAnyState && !hasHaircareSubscriptonInAnyState
        ? content?.contentHaircare
        : null,
    variant,
  };
};

export const useRedeemCouponForTrialOffer = () => {
  const showTrialOffer = useAppSelector(shouldShowTrialOffer);
  const isFlagsDoneFetching = useAppSelector(getIsFlagsDoneFetching);

  useEffect(() => {
    if (showTrialOffer && isFlagsDoneFetching) {
      TagSegmentService.post('onsite_trial_offer_50');
    }
  }, [showTrialOffer, isFlagsDoneFetching]);
};
