import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import AuthStatus from './constants/authStatuses';

// auth
const getAuth = (state: RootState) => state.auth;

export const getIsIdle = createSelector(getAuth, (state) => state === AuthStatus.IDLE);
export const getIsAuthenticated = createSelector(
  getAuth,
  (state) => state === AuthStatus.AUTHENTICATED,
);
export const getIsNotAuthenticated = createSelector(
  getAuth,
  (state) => state === AuthStatus.NOT_AUTHENTICATED,
);
export const getIsAuthenticating = createSelector(getAuth, (state) =>
  /*
   * INFO: include on as const types can be a pain in the ***, this is where
   * https://www.totaltypescript.com/ts-reset becomes handy
   * */
  [AuthStatus.IDLE, AuthStatus.LOADING].includes(state),
);
export const getAuthError = createSelector(getAuth, (state) => state === AuthStatus.ERROR);
