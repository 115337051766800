import { MY_LIFESTYLE } from '../constants';
import { LIFESTYLE } from '../constants/progressCategories';

const myLifestyleHormones = {
  name: 'hormones',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/haircare/${MY_LIFESTYLE}/hormones`,
  multi: true,
  title: 'Are you currently...',
  subtitle: 'Select all that apply',
  body: '*Please check with your doctor before starting supplements',
  tip: {
    title: 'Why we ask:',
    content: `
Major hormonal events can affect hair’s growth and shedding cycle. For example, pregnancy can extend hair’s growth phase, giving you fuller-looking hair. After delivery, postpartum hormonal plunges can trigger a shedding phase, so it appears that you’re losing more hair than usual.

&nbsp;

_*If you are pregnant, breastfeeding, or have a medical condition, please check with your medical care team before purchasing or starting supplements_
`,
  },
  options: [
    {
      value: 'pregnant',
      label: 'Pregnant*',
    },
    {
      value: 'post_pregnant',
      label: 'Post-pregnancy (delivered in the last 12 months)',
    },
    {
      value: 'breastfeeding',
      label: 'Breastfeeding*',
    },
    {
      value: 'menopause',
      label: 'Experiencing menopause',
    },
    { value: 'none', label: 'None of the above', unique: true },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_hormones,
  getAnswers: ({ selected }) => ({ profile_hormones: selected }),
};

export default myLifestyleHormones;
