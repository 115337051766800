import { MY_LIFESTYLE, SKINCARE_QUESTION_INDOOR_EXPOSURE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

import { ReactComponent as LivingRoomIcon } from 'assets/images/skincare/livingRoomIcon.svg';

const indoorExposure = {
  name: SKINCARE_QUESTION_INDOOR_EXPOSURE,
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/indoor-exposure`,
  title: 'Over the next 2 months, will you spend a lot of time...',
  subtitle:
    'Consider any of the following you do for a few hours or more per day. Select all that apply.',
  icon: <LivingRoomIcon />,
  tip: {
    title: 'Why we ask:',
    content: `
When it comes to the kind of UVA exposure that leads to premature aging, sitting near a window is the same as sitting outside.

&nbsp;

Spending time in extreme AC can cause dryness, high heat can cause uneven skin tone, and indoor pollution can trigger oxidative stress.
    `,
  },
  options: [
    { label: 'In a space with air conditioning', value: 'air_conditioning' },
    { label: 'In a space with heating', value: 'heating' },
    { label: 'Sitting by a window', value: 'window' },
    { label: 'Wearing a mask', value: 'mask' },
    { label: 'None of these', value: 'none', unique: true },
  ],
  multi: true,
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_indoor,
  getAnswers: ({ selected }) => ({ profile_indoor: selected }),
};

export default indoorExposure;
