import { productsCategories } from 'constants/products';

export const HAIR_SCALP = 'hair-scalp';
export const TREATMENTS = 'treatments';
export const LIFESTYLE = 'lifestyle';
export const PREFERENCES_GOALS = 'my-preferences';
export const SKIN = 'skin';

const hairProgressTrackerCategories = [
  {
    code: HAIR_SCALP,
    label: 'Hair & Scalp',
  },
  {
    code: TREATMENTS,
    label: 'Treatments',
  },
  {
    code: LIFESTYLE,
    label: 'Lifestyle',
  },
  {
    code: PREFERENCES_GOALS,
    label: 'Preferences & Goals',
    labelMobile: 'Preferences',
  },
];

const skinProgressTrackerCategories = [
  {
    code: LIFESTYLE,
    label: 'Lifestyle',
  },
  {
    code: SKIN,
    label: 'My skin',
  },
  {
    code: PREFERENCES_GOALS,
    label: 'Preferences & Goals',
    labelMobile: 'Preferences',
  },
];

export const progressTrackerCategoriesList = {
  [productsCategories.HAIRCARE]: hairProgressTrackerCategories,
  [productsCategories.SKINCARE]: skinProgressTrackerCategories,
};
