import { MY_LIFESTYLE } from '../../constants';
import { HAIRCARE, SKINCARE } from '../../constants/consultationProductsCategories';
import { LIFESTYLE } from '../../constants/progressCategories';

import { ReactComponent as SmallScribbleIcon } from 'assets/images/skincare/smallScribbleIcon.svg';

const stress = {
  name: 'stress',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  // route is defined at question set level since this question is shared
  // route: '',
  title: 'How often are you stressed out?',
  subtitle: 'We’re interested in the frequency, not the intensity.',
  icon: <SmallScribbleIcon />,
  tip: {
    title: 'Why we ask:',
    content: {
      [HAIRCARE]:
        'Emotional stress can weaken hair, so be honest about your feelings. We’ll create products that give your hair the strength and resilience it needs on good days and bad.',
      [SKINCARE]:
        'Our body’s natural response to a stressful situation — like a traffic jam or a breakup — is to release cortisol, a hormone that impacts our skin. Be honest, and try not to stress too much about your stress — it’s something that everybody deals with.',
    },
  },
  options: [
    { value: 'never', label: 'Rarely' },
    { value: 'little', label: 'Maybe once a week' },
    { value: 'moderately', label: 'Multiple times a week' },
    { value: 'lot', label: 'Every day' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_stress_level,
  getAnswers: ({ selected }) => ({ profile_stress_level: selected }),
};

export default stress;
