const IDLE = 'idle';
const LOADING = 'loading';
const AUTHENTICATED = 'authenticated';
const NOT_AUTHENTICATED = 'notAuthenticated';
const ERROR = 'error';

const AuthStatus = { IDLE, LOADING, AUTHENTICATED, NOT_AUTHENTICATED, ERROR } as const;
/**
 * INFO: intentional merging of value & type name
 */
type AuthStatus = (typeof AuthStatus)[keyof typeof AuthStatus];

export default AuthStatus;
