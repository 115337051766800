import * as consultationComponents from '../constants/consultationComponents';

import supplementsBackgroundDesktop from 'assets/images/consultation/transitionScreens/d-supplements_intro.jpg';
import supplementsBackgroundMobile from 'assets/images/consultation/transitionScreens/m-supplements_intro.jpg';
import supplementsBackgroundTablet from 'assets/images/consultation/transitionScreens/t-supplements_intro.jpg';

const supplementsConsultationWelcome = {
  name: 'welcome',
  route: `/consultation/haircare`,
  Component: consultationComponents.Welcome,
  title: 'Customize your haircare inside & out with Root Source®',
  subtitle:
    'Answer a few new questions in our recently-expanded consultation, and we’ll use all your info to create the supplements your hair truly needs.',
  cta: 'complete your consultation',
  image: {
    mobile: supplementsBackgroundMobile,
    tablet: supplementsBackgroundTablet,
    desktop: supplementsBackgroundDesktop,
  },
  shouldBeIncluded: ({ isAuthenticated }) => isAuthenticated,
  public: true,
  skipSave: true,
};

export default supplementsConsultationWelcome;
