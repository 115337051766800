import holdLevel from './questions/holdLevel';
import myHairTexture from './questions/myHairTexture';
import myTreatmentsHairStyles from './questions/myTreatmentsHairStyles';
import myTreatmentsStyles from './questions/myTreatmentsStyles';
import stylingGelWelcome from './questions/stylingGelWelcome';

// options relative to the consultation layout
const options = {
  showSteps: true,
  showProgressTracker: false,
};

const questionSet = [
  stylingGelWelcome,
  myHairTexture,
  myTreatmentsStyles,
  myTreatmentsHairStyles,
  holdLevel,
];
questionSet.options = options;

export default questionSet;
