import { createSlice } from '@reduxjs/toolkit';

type GiftState = {
  selectedGift: null | { pubkey: string };
};

const initialState: GiftState = {
  selectedGift: null,
};

const giftSlice = createSlice({
  name: 'gift',
  initialState,
  reducers: {
    setSelectedGift: (draft, action) => {
      draft.selectedGift = action.payload;
    },
    reset: () => initialState,
  },
});

export const { reducer, actions } = giftSlice;
