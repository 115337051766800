import { Link } from 'react-router-dom';

import { theme } from '@prose-ui';

import acaciaCollagenImg from 'assets/images/productPages/ingredients/acacia_collagen.png';
import appleCiderVinegarImg from 'assets/images/productPages/ingredients/apple_cider_vinegar.png';
import arganOilImg from 'assets/images/productPages/ingredients/argan_oil.png';
import arginineImg from 'assets/images/productPages/ingredients/arginine.png';
import biotinImg from 'assets/images/productPages/ingredients/biotin.png';
import cbdPowderImg from 'assets/images/productPages/ingredients/cbd_powder.png';
import fermentedRiceWaterImg from 'assets/images/productPages/ingredients/fermented_rice_water.png';
import greenTeaWaterImg from 'assets/images/productPages/ingredients/green_tea_water.png';
import honeyImg from 'assets/images/productPages/ingredients/honey.png';
import liqouriceExtractImg from 'assets/images/productPages/ingredients/liqourice_extract.png';
import oatOilImg from 'assets/images/productPages/ingredients/oat_oil.png';
import peppermintExtractImg from 'assets/images/productPages/ingredients/peppermint_extract.png';
import prebioticsImg from 'assets/images/productPages/ingredients/prebiotics.png';
import proVitaminB5Img from 'assets/images/productPages/ingredients/pro_vitamin_b5.png';
import shikakaiExtractImg from 'assets/images/productPages/ingredients/shikakai_extract.png';
import silkProteinsImg from 'assets/images/productPages/ingredients/silk_proteins.png';
import sunflowerOilImg from 'assets/images/productPages/ingredients/sunflower_oil.png';
import vitaminFImg from 'assets/images/productPages/ingredients/vitamin_f.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-shampoo.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-shampoo-close-up.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-shampoo-in-soap.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-rinsing-custom-shampoo.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-rinsing-custom-shampoo-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-rinsing-custom-shampoo-mobile.jpg';
import carouselImg5 from 'assets/images/productPages/prose-ugc-shampoo.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "HEIDI'S CLARIFYING + STRENGTHENING SHAMPOO",
      ingredients: [
        {
          image: shikakaiExtractImg,
          name: 'Shikakai Extract',
          benefit: 'Scalp Cleansing',
        },
        {
          image: greenTeaWaterImg,
          name: 'Green Tea Water',
          benefit: 'Antioxidant',
        },
        {
          image: peppermintExtractImg,
          name: 'Peppermint Extract',
          benefit: 'Sebum Regulator',
        },
        {
          image: proVitaminB5Img,
          name: 'Pro-Vitamin B5',
          benefit: 'Moisturizer',
        },
        {
          image: honeyImg,
          name: 'Honey',
          benefit: 'Volumizing',
        },
        {
          image: biotinImg,
          name: 'Biotin',
          benefit: 'Hair Strength',
        },
      ],
    },
    {
      title: "ELEANOR'S SOOTHING + REPARATIVE SHAMPOO",
      ingredients: [
        {
          image: acaciaCollagenImg,
          name: 'Acacia Collagen',
          benefit: 'Fiber Repair',
        },
        {
          image: fermentedRiceWaterImg,
          name: 'Fermented Rice Water',
          benefit: 'Smoothing',
        },
        {
          image: arginineImg,
          name: 'Arginine',
          benefit: 'Heat Protect',
        },
        {
          image: oatOilImg,
          name: 'Oat Oil',
          benefit: 'Color Protection',
        },
        {
          image: cbdPowderImg,
          name: 'CBD Powder',
          benefit: 'Scalp Soother',
        },
        {
          image: vitaminFImg,
          name: 'Vitamin F',
          benefit: 'Revitalizing',
        },
      ],
    },
    {
      title: "SAM'S NOURISHING + BALANCING SHAMPOO",
      ingredients: [
        {
          image: liqouriceExtractImg,
          name: 'Licorice Extract',
          benefit: 'Scalp Soother',
        },
        {
          image: sunflowerOilImg,
          name: 'Sunflower Oil',
          benefit: 'Antioxidant',
        },
        {
          image: appleCiderVinegarImg,
          name: 'Apple Cider Vinegar',
          benefit: 'Shine',
        },
        {
          image: arganOilImg,
          name: 'Argan Oil',
          benefit: 'Nourishing',
        },
        {
          image: prebioticsImg,
          name: 'Prebiotics',
          benefit: 'Scalp Balance',
        },
        {
          image: silkProteinsImg,
          name: 'Silk Proteins',
          benefit: 'Cuticle Nutrition',
        },
      ],
    },
  ],
  proTips: [
    {
      background: theme.colors.primary['300'],
      number: '128',
      description:
        'For the best lather, apply shampoo horizontally across fingertips, add in a few drops of water and then rub fingers together before applying to wet hair.',
    },
  ],
  faq: [
    {
      question: 'What shampoo should I use?',
      answer: (
        <>
          Everyone’s hair is unique, and your shampoo should factor in your hair type, porosity,
          density, and the geo-aggressors (like hard water, humidity and pollution) in your zip
          code. Prose asks in-depth questions about your diet, lifestyle, and climate, all of which
          impact your hair. Take your <Link to="/consultation/haircare">consultation</Link> today to
          find out what your hair needs.
        </>
      ),
    },
    {
      question: 'What type of hair is this shampoo for?',
      answer:
        'Prose custom haircare works on straight (type 1), wavy (type 2), curly (type 3) and coily (type 4) hair. ' +
        'Our completely personalized formulas account for different hair densities and porosities, so your hair can get the exact care that it needs.',
    },
    {
      question: 'How do I customize my shampoo?',
      answer: (
        <>
          1. <Link to="/consultation/category">Take your online consultation</Link> about your hair,
          lifestyle and environment to understand what kind of care your hair needs.
          <br />
          2. From your answers, we’ll create a personalized shampoo based on 85+ factors and
          formulated to support your unique needs at this moment in time.
          <br />
          3. You can always share feedback on your shampoo formula after every order using our
          Review and Refine® survey so we can provide evolving, custom-fitting care.
        </>
      ),
    },
    {
      question: 'How often should I wash my hair?',
      answer:
        'How often you need to shampoo varies for each individual, since sebum (the natural oils your scalp produces) is impacted by external and internal factors. ' +
        'These factors range from pollution to humidity, and stress levels to hormones. Your Prose formulas can help mitigate environmental factors ' +
        'and provide the right amount of each ingredient to help keep your hair and scalp balanced — which can mean more time between washes!',
    },
    {
      question: 'Will Prose shampoo help me go longer in between washes?',
      answer:
        'This will vary for each individual, since sebum production is impacted by external and internal factors. These can range from pollution and humidity, to stress levels and hormones. ' +
        "Your Prose formulas can help mitigate environmental factors, but as for fixing stress levels—we're still working on it!",
    },
    {
      question: 'How do I use Prose shampoo?',
      answer:
        'Your shampoo can be worked into a lightweight lather for gentle cleansing on wash day. ' +
        'Start with a pump of shampoo across your fingertips then rub your fingers together with a few drops of water. ' +
        'Since our products are sulfate-free, your shampoo might not appear as sudsy, but that doesn’t mean it’s working any less hard.',
    },
    {
      question: 'Is Prose shampoo color-safe?',
      answer:
        'Yes—your shampoo will be safe for colored hair if you indicate that you have color-treated or dyed hair during the consultation. ' +
        'If your hair is blonde, your routine will likely include purple shampoo.',
    },
    {
      question: 'I have dermatitis/psoriasis/a sensitive scalp—can I still use Prose shampoo?',
      answer:
        'Yes—our shampoo is safe and gentle enough for those with a very sensitive scalp. ' +
        'Our shampoo can also be made fragrance-free, which we recommend for those who have sensitive skin. ' +
        'Remember, always consult a doctor if you’re experiencing a severe medical condition.',
    },
    {
      question: 'What is the best shampoo and conditioner to use together?',
      answer: (
        <>
          For the best results, we recommend using Prose custom shampoo and{' '}
          <Link to="/products/custom-conditioner">conditioner</Link> together to cleanse your scalp
          and hydrate your hair’s ends. Your hair may need different care on its lengths, and our
          shampoo and conditioner are uniquely formulated for your specific hair type and needs to
          provide personalized care from root to tip.
        </>
      ),
    },
    {
      question: 'What ingredients should I avoid in shampoo?',
      answer:
        'When it comes to our ingredients, if it’s not high quality or highly efficacious, we won’t use it. ' +
        'Every ingredient is meticulously researched by our world-class R&D team and sustainably sourced from trusted partners around the globe. ' +
        'Prose formulas are always free of harmful ingredients like sulfates, parabens, phthalates, mineral oil, alcohol, and GMOs. ' +
        'Just good, clean ingredients — and none of the bad stuff.',
    },
    {
      question: 'Can Prose Custom Shampoo treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom shampoo packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom shampoo close up',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom shampoo in the shower',
    },
    {
      image: carouselImg4,
      alt: 'Prose custom shampoo in soap',
    },
    {
      image: carouselImg5,
      alt: 'Models posing with custom shampoo bottles',
    },
  ],
  formulaModule: {
    header: (
      <>
        A personalized shampoo,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every shampoo we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model using custom shampoo in shower',
    },
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Shampoo is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A shampoo with naturally powerful + proven-effective ingredients',
  },
};
