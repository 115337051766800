import { useState } from 'react';

import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';

import { FIRST_CONSULTATION_ROUTE } from 'Apps/Consultation/constants';
import GiftWithPurchaseBanner from 'Apps/PromotionTerms/Scenes/GiftWithPurchase/GiftWithPurchaseBanner';

import { BottomModal } from 'Components/BottomModal';

import { useSssShippingFeeAbTest } from 'abTesting/sssShippingFee/hook';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';
import { type Currency } from 'constants/currencies';

import {
  CheckoutBanner,
  ClickableCta,
  DefaultBanner,
  LinkCta,
  LinkCtaNoir,
  LinkCtaWhite,
  LoadingBanner,
  LongLinkCta,
  MembershipBanner,
  ReferFriendSideLink,
} from 'Blocks/Banner/BannerComponents';

import * as bannerContent from 'assets/content/banner';
import {
  skincareMinisWithoutShippingFee,
  skincareMinisWithShippingFee,
} from 'assets/content/skincareMinisModal';
import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/trialOfferGWPMixedWithSkincareMinisModal';
import trialOfferMixedWithSkincareMinisModalContent from 'assets/content/trialOfferMixedWithSkincareMinisModal';

import { handleKeyPress } from 'utils/a11y';

import { trialOfferEvergreen } from 'dux/featureFlags/selectors';
import { getUserCurrency } from 'dux/user/selectors';
import { VARIANT1 } from 'dux/featureFlags/constants/flagVariants';

type ShouldRenderOptions = {
  showTrialOffer?: boolean;
  showSkincareStarterSetPromo?: boolean;
  hasHaircareSubscriptionInAnyState?: boolean;
  hasSkincareSubscriptionInAnyState?: boolean;
  locationPathname?: string;
  locationSearchParams?: URLSearchParams;
  hasHairAndSkinSubscriptions?: boolean;
  isGiftWithPurchaseEligible?: boolean;
  giftWithPurchaseBannerData?: Object;
  hasCompletedHaircareConsultation?: boolean;
  hasCompletedSkincareConsultation?: boolean;
  hasStartedHaircareConsultation?: boolean;
  hasStartedSkincareConsultation?: boolean;
  showPromotionAccessories2023?: boolean;
  isFlagsDoneFetching?: string;
  postPurchaseCrossSellVariantName?: string;
  isAuthenticated?: boolean;
};

type RenderOptions = {
  giftWithPurchaseBannerData: {
    lp_images: {
      mobile: string;
      tablet: string;
      desktop: string;
    };
    lp_cta_text: string;
    lp_cta_url: string;
    lp_gift_description: string;
    lp_legal_text: string;
    lp_title: string;
    banner_color: string;
    banner_redirection_url: string;
    banner_text: string;
  };
  couponsStatuses: {
    coupons: [
      {
        code: string;
        is_attached_to_customer: boolean;
        status: 'created' | 'redeem';
      },
    ];
  };
  hasHaircareSubscription: boolean;
  hasSkincareSubscription: boolean;
  hasCompletedHaircareConsultation: boolean;
  hasCompletedSkincareConsultation: boolean;
  nextMode: boolean;
};

export const defaultBanner = {
  shouldRender: () => true, // This should always return true
  render: (hideBannerLink: boolean, { nextMode }: RenderOptions) => (
    <DefaultBanner data-testid="banner" hasLink={!hideBannerLink}>
      <LongLinkCta
        data-click="refer"
        data-from="banner"
        data-testid="default-banner"
        nextMode={nextMode}
        to="/reviews"
      >
        {bannerContent.defaultContent}
      </LongLinkCta>
      {!hideBannerLink && <ReferFriendSideLink nextMode={nextMode} />}
    </DefaultBanner>
  ),
};

const RenderTrialOfferBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { content, variant } = useTrialOfferPromotionModalContent();

  return (
    <>
      <BottomModal
        content={content?.bottomModal}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix={`bottom-modal-trial-offer-${variant}`}
      />
      <ClickableCta
        aria-label="Open trial offer modal"
        data-click="offer-popup"
        data-from="banner"
        data-testid={`banner-trial-offer-${variant}`}
        onClick={() => setIsOpen(true)}
        onKeyPress={handleKeyPress(() => setIsOpen(true))}
        role="button"
        tabIndex={0}
      >
        {content?.banner}
      </ClickableCta>
    </>
  );
};

const RenderTrialOfferMixedSkincareMinisBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currency: Currency = useAppSelector(getUserCurrency);
  const { variant } = useTrialOfferPromotionModalContent();
  const content =
    variant !== trialOfferEvergreen
      ? trialOfferGWPMixedWithSkincareMinisModalContent
      : trialOfferMixedWithSkincareMinisModalContent;

  return (
    <>
      <BottomModal
        backgroundColor={theme.colors.tertiary[200]}
        content={content.bottomModal[currency]}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <ClickableCta
        aria-label="Open skincare minis + starter set offer modal"
        data-click="offer-popup"
        data-from="banner"
        onClick={() => setIsOpen(true)}
        onKeyPress={handleKeyPress(() => setIsOpen(true))}
        role="button"
        tabIndex={0}
      >
        {content.banner}
      </ClickableCta>
    </>
  );
};

const RenderSkincareMinisBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userCurrency: Currency = useAppSelector(getUserCurrency);
  const { variantName: sssShippingFeeAbTestVariantName } = useSssShippingFeeAbTest();

  const skincareMinisContent =
    sssShippingFeeAbTestVariantName === VARIANT1
      ? skincareMinisWithShippingFee
      : skincareMinisWithoutShippingFee;

  return (
    <>
      <BottomModal
        backgroundColor={theme.colors.tertiary[200]}
        content={skincareMinisContent.bottomModal[userCurrency]}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix="bottom-modal-skincare-minis"
      />
      <ClickableCta
        aria-label="Open skincare minis offer modal"
        data-click="offer-popup"
        data-from="banner"
        data-testid="banner-skincare-minis"
        onClick={() => setIsOpen(true)}
        onKeyPress={handleKeyPress(() => setIsOpen(true))}
        role="button"
        tabIndex={0}
      >
        {skincareMinisContent.banner}
      </ClickableCta>
    </>
  );
};

export const trialOfferBanner = {
  shouldRender: ({ showTrialOffer }: ShouldRenderOptions) => showTrialOffer,
  render: () => <RenderTrialOfferBanner />,
};

export const skincareMinisBanner = {
  shouldRender: ({ showSkincareStarterSetPromo }: ShouldRenderOptions) =>
    showSkincareStarterSetPromo,
  render: () => <RenderSkincareMinisBanner />,
};

export const trialOfferMixedSkincareMinisBanner = {
  shouldRender: ({
    showTrialOffer,
    showSkincareStarterSetPromo,
    hasHaircareSubscriptionInAnyState,
    hasSkincareSubscriptionInAnyState,
  }: ShouldRenderOptions) =>
    showTrialOffer &&
    showSkincareStarterSetPromo &&
    !hasHaircareSubscriptionInAnyState &&
    !hasSkincareSubscriptionInAnyState,
  render: () => <RenderTrialOfferMixedSkincareMinisBanner />,
};

export const checkoutMessage = {
  shouldRender: ({ locationPathname }: ShouldRenderOptions) =>
    locationPathname
      ? /\/checkout\/(haircare|skincare)\/(account-details|shipping-address|payment)/.test(
          locationPathname,
        )
      : false,
  render: () => (
    <CheckoutBanner data-testid="satisfaction-guarantee-banner">
      Love your first order (or it&apos;s on us)
    </CheckoutBanner>
  ),
};

export const noFreeShippingContent = {
  shouldRender: ({ locationSearchParams }: ShouldRenderOptions) => {
    return locationSearchParams?.has('origin') && locationSearchParams?.get('origin') === 'yjfwm';
  },
  render: (hideBannerLink: boolean, { nextMode }: RenderOptions) => (
    <DefaultBanner data-testid="banner" hasLink={!hideBannerLink}>
      <span data-testid="no-free-shipping-content-banner">
        {bannerContent.noFreeShippingContent}
      </span>
      {!hideBannerLink && <ReferFriendSideLink nextMode={nextMode} />}
    </DefaultBanner>
  ),
};

export const referralCta = {
  shouldRender: ({ hasHairAndSkinSubscriptions }: ShouldRenderOptions) =>
    hasHairAndSkinSubscriptions,
  render: ({ nextMode }: RenderOptions) => (
    <DefaultBanner data-testid="banner">
      <LinkCta
        data-click="refer"
        data-from="banner"
        data-testid="referral-cta-banner"
        nextMode={nextMode}
        to="/account/refer-a-friend"
      >
        {bannerContent.referralCta}
      </LinkCta>
    </DefaultBanner>
  ),
};

export const giftWithPurchase = {
  shouldRender: ({ isGiftWithPurchaseEligible, giftWithPurchaseBannerData }: ShouldRenderOptions) =>
    isGiftWithPurchaseEligible && Boolean(giftWithPurchaseBannerData),
  render: (_hideBannerLink: boolean, options: RenderOptions) => (
    <GiftWithPurchaseBanner
      backgroundColor={options.giftWithPurchaseBannerData.banner_color ?? theme.colors.primary[300]}
      nextMode={options.nextMode}
      redirectionUrl={options.giftWithPurchaseBannerData.banner_redirection_url}
      text={options.giftWithPurchaseBannerData.banner_text}
    />
  ),
};

export const membershipCta = {
  shouldRender: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
    hasStartedHaircareConsultation,
    hasStartedSkincareConsultation,
  }: ShouldRenderOptions) =>
    hasCompletedHaircareConsultation ||
    hasCompletedSkincareConsultation ||
    (hasStartedHaircareConsultation && hasStartedSkincareConsultation),
  render: (
    hideBannerLink: boolean,
    { hasCompletedHaircareConsultation, hasCompletedSkincareConsultation, nextMode }: RenderOptions,
  ) => (
    <MembershipBanner data-testid="banner" hasLink={!hideBannerLink}>
      <LinkCtaNoir
        data-click="membership-lp-prospects"
        data-from="banner"
        data-testid="membership-cta"
        nextMode={nextMode}
        to={
          hasCompletedHaircareConsultation
            ? HAIRCARE_CART_URL
            : hasCompletedSkincareConsultation
            ? SKINCARE_CART_URL
            : FIRST_CONSULTATION_ROUTE
        }
      >
        {bannerContent.membershipCta}
      </LinkCtaNoir>
      {!hideBannerLink && <ReferFriendSideLink nextMode={nextMode} />}
    </MembershipBanner>
  ),
};

export const loading = {
  shouldRender: ({ isFlagsDoneFetching }: ShouldRenderOptions) => !isFlagsDoneFetching,
  render: () => <LoadingBanner data-testid="loading-banner" />,
};

export const supplementsPages = {
  shouldRender: ({ locationPathname, isAuthenticated }: ShouldRenderOptions) => {
    const match = locationPathname
      ? /\/(pages\/supplements|products\/custom-hair-supplements)/.test(locationPathname)
      : false;
    return !isAuthenticated && match;
  },
  render: ({ nextMode }: RenderOptions) => (
    <DefaultBanner data-testid="banner">
      <LinkCta
        data-click="membership-lp-prospects"
        data-from="banner"
        data-testid="membership-cta"
        nextMode={nextMode}
        to="/the-salon"
      >
        {bannerContent.membershipCta}
      </LinkCta>
    </DefaultBanner>
  ),
};

export const membershipMultiCategoryWithoutSubscription = {
  shouldRender: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
    hasStartedHaircareConsultation,
    hasStartedSkincareConsultation,
  }: ShouldRenderOptions) =>
    (hasCompletedHaircareConsultation && hasCompletedSkincareConsultation) ||
    (hasCompletedHaircareConsultation && hasStartedSkincareConsultation) ||
    (hasCompletedSkincareConsultation && hasStartedHaircareConsultation),
  render: (
    _: unknown,
    { hasCompletedHaircareConsultation, hasCompletedSkincareConsultation, nextMode }: RenderOptions,
  ) => (
    <MembershipBanner data-testid="membership-multi-category-banner-without-subscription">
      <LinkCtaNoir
        data-testid="membership-cta"
        nextMode={nextMode}
        to={
          hasCompletedHaircareConsultation && hasCompletedSkincareConsultation
            ? '/account/reorder'
            : hasCompletedHaircareConsultation
            ? '/consultation/skincare'
            : '/consultation/haircare'
        }
      >
        {bannerContent.membershipMultiCategoryCta}
      </LinkCtaNoir>
    </MembershipBanner>
  ),
};

export const membershipMultiCategoryWithOneSubscription = {
  shouldRender: ({
    hasEitherHairOrSkinSubscription,
  }: {
    hasEitherHairOrSkinSubscription: boolean;
  }) => hasEitherHairOrSkinSubscription,
  render: (
    _: unknown,
    {
      hasHaircareSubscription,
      hasSkincareSubscription,
      hasCompletedHaircareConsultation,
      hasCompletedSkincareConsultation,
      nextMode,
    }: RenderOptions,
  ) => (
    <MembershipBanner data-testid="membership-multi-category-banner-with-subscription">
      <LinkCtaNoir
        data-testid="membership-cta"
        nextMode={nextMode}
        to={
          hasHaircareSubscription &&
          hasCompletedHaircareConsultation &&
          hasCompletedSkincareConsultation
            ? SKINCARE_CART_URL
            : hasSkincareSubscription &&
              hasCompletedHaircareConsultation &&
              hasCompletedSkincareConsultation
            ? HAIRCARE_CART_URL
            : hasHaircareSubscription
            ? '/consultation/skincare'
            : '/consultation/haircare'
        }
      >
        {hasHaircareSubscription
          ? bannerContent.membershipMultiCategoryWithHaircareSubscriptionCta
          : bannerContent.membershipMultiCategoryWithSkincareSubscriptionCta}
      </LinkCtaNoir>
    </MembershipBanner>
  ),
};

export const promotionAccessoriesOffer = {
  shouldRender: ({ locationPathname, showPromotionAccessories2023 }: ShouldRenderOptions) => {
    const match = locationPathname ? /\/(checkout|consultation)/.test(locationPathname) : false;
    return !match && showPromotionAccessories2023;
  },
  render: ({ nextMode }: RenderOptions) => (
    <DefaultBanner>
      <LinkCtaWhite
        data-click="accessories-promo-dec-2023"
        data-from="banner"
        data-testid="promotion-accessories-link"
        nextMode={nextMode}
        to="/products/accessories"
      >
        {bannerContent.accessoriesPromotion2023BannerText}
      </LinkCtaWhite>
    </DefaultBanner>
  ),
};
