import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsColorTreatmentsLocation = {
  name: 'color-treatments-location',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/color-treatments-location`,
  title: 'How much of your hair is color-treated?',
  subtitle:
    'If you can’t choose between two answers, pick the higher-coverage option. E.g. if you’re stuck between "All" and "Only parts", choose "All."',
  tip: {
    title: 'Why we ask:',
    content:
      'If you’ve recently stopped dyeing your hair but still have color-treated lengths, consider answering with one of these answers; so we can give your hair what it needs.',
  },
  options: [
    {
      value: 'full_head',
      label: 'All or nearly all',
      description: 'Choose all even if you have small visible roots',
    },
    {
      value: 'partial',
      label: 'Only parts',
      description: 'I.e. ombre, balayage, highlights',
    },
    { value: 'tips', label: 'Just the tips', description: 'Nearly grown-out ends' },
  ],
  shouldBeIncluded: ({ answers }) => answers.diag_color_treatments === true,
  // Deprecated answer `partial` can now be answered `partial` or `tips` - data has been renamed to `partial_or_tips`
  // For user taking the consultation again who might have previously selected this answer we want to show `partial` selected by default
  getInitialValue: answers =>
    answers.diag_color_treatments_location === 'partial_or_tips'
      ? 'partial'
      : answers.diag_color_treatments_location,
  getAnswers: ({ selected }) => ({
    diag_color_treatments_location: selected,
  }),
};

export default myTreatmentsColorTreatmentsLocation;
