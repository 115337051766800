import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

const myHairHairloss = {
  name: 'hairloss',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/hairloss`,
  title: 'Do you feel like you’re shedding more than usual lately?',
  subtitle: 'What‘s normal? Check the tip!',
  tip: {
    title: 'What’s normal?',
    content: `**What normal looks like**

It’s normal to lose up to 100 strands a day, but you’ll typically notice the loss only when you wash or style your hair. So if, for example, you had your hair in a braid for 3 days, then washed it or brushed it out, it might look like you lost 300 hairs at once.

&nbsp;

**Shedding vs breakage**

Shedding means hair is falling out at the root; breakage means it is breaking off somewhere down the strand. If you’re 100% sure you’re shedding more lately, there could be genetic, hormonal, or environmental factors at work. We’ll do all we can to balance you out, but some forces are greater than haircare.`,
  },
  options: [
    { value: 'normal', label: 'No, seems normal' },
    { value: 'bit_than_normal', label: 'Sometimes it feels that way, sometimes not' },
    { value: 'excessive', label: 'Yes, I know for sure I am' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_hairloss_level,
  getAnswers: ({ selected }) => ({ diag_hairloss_level: selected }),
};

export default myHairHairloss;
