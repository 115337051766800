import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

const myHairSensitivity = {
  name: 'sensitivity',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/sensitivity`,
  title: 'Do you have a sensitive scalp?',
  subtitle:
    'If you experience tightness, dryness, burning, tingling, pain, itching, or redness, all that counts as sensitivity.',
  tip: {
    title: 'Did you know...',
    content: `Some sensitivity is caused by things like chemical treatments, chlorine, or hard water, and can be significantly improved with the right haircare. Some causes are more hormonal or medical, and require lifestyle changes or medication, but our custom formulas can still give you partial relief in the meantime. If you’re at all sensitive, choose our fragrance-free option at the end of this quiz.

&nbsp;

PS: If you’ve been diagnosed with eczema or allergic contact dermatitis, please answer with the highest level of sensitivity.`,
  },
  options: [
    { value: 0, label: 'Not at all' },
    { value: 1, label: 'Off and on' },
    { value: 2, label: 'Yes, for sure' },
    { value: 3, label: 'It’s ridiculously sensitive' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_sensitivity_level,
  getAnswers: ({ selected }) => ({ diag_sensitivity_level: selected }),
};

export default myHairSensitivity;
