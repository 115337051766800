// For the record, it used to be a hair question but now is a lifestyle for UX concerns
import { MY_LIFESTYLE } from '../constants';
import { LIFESTYLE } from '../constants/progressCategories';

const myLifestyleOtherHairLossCauses = {
  name: 'other_hair_loss_causes',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/haircare/${MY_LIFESTYLE}/other-hair-loss-causes`,
  multi: true,
  title: 'Have you experienced any of these factors in the last 6 months?',
  subtitle: 'Select all that apply',
  tip: {
    title: 'Why we ask:',
    content: `A sudden upsurge in hair shedding is often a physical reaction to changing conditions, and can usually be reversed. This is different from permanent hair loss, which is not as easily or even not reversible.

&nbsp;

If you're already taking medication, consult your doctor before starting any supplements.`,
  },
  options: [
    {
      value: 'major_stressfull_event',
      label: 'Major stressful event',
      description: 'e.g. job loss, surgery, breakup, bereavement',
    },
    {
      value: 'medication_change',
      label: 'Stopped or started a new medication',
      description: 'e.g. birth control, insulin',
    },
    {
      value: 'diagnosed_scalp_disorder',
      label: 'Diagnosed with a hair/scalp condition',
      description: 'e.g. psoriasis, eczema',
    },
    {
      value: 'medical_treatment',
      label: (
        <>
          Medical treatment that affects
          <br />
          hair/scalp
        </>
      ),
      description: 'e.g. chemotherapy, thyroid treatment',
    },
    { value: 'none', label: 'None of the above', unique: true },
  ],
  shouldBeIncluded: ({ answers }) => answers.diag_hairloss_level !== 'normal',
  getInitialValue: answers => answers.diag_hairloss_other,
  getAnswers: ({ selected }) => ({ diag_hairloss_other: selected }),
};

export default myLifestyleOtherHairLossCauses;
