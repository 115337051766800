import { em, px, unitless } from '../utils/units';

export const breakpoints = {
  sm: em(40), // 640px
  md: em(63.9), // 1024px
  lg: em(79.9), // 1280px
};

export const columns = {
  sm: unitless(4),
  md: unitless(6),
  'lg-account': unitless(9),
  lg: unitless(12),
};

export const gutters = {
  sm: px(16),
  md: px(16),
  lg: px(16),
};

export const margins = {
  sm: px(30),
  md: px(20),
  lg: px(32),
};
