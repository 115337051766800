import cleanserFoamingCreamImg from 'assets/images/skincare/textures/cleanser_foaming_cream.png';
import cleansingCreamImg from 'assets/images/skincare/textures/cleansing_cream.png';
import moisturizerLightImg from 'assets/images/skincare/textures/moisturizer_light.png';
import moisturizerMediumImg from 'assets/images/skincare/textures/moisturizer_medium.png';
import moisturizerRichImg from 'assets/images/skincare/textures/moisturizer_rich.png';

const CLEANSER_FOAMING_GEL = 'foaming_gel';
const CLEANSING_CREAM = 'cleansing_cream';

const LIGHT_MOISTURIZER = 'light';
const MEDIUM_MOISTURIZER = 'medium';
const RICH_MOISTURIZER = 'rich';

export const recommended = 'your top recommendation';
export const notRecommended = 'not recommended at this time';

export const cleanserTextures = {
  [CLEANSER_FOAMING_GEL]: {
    name: 'Gel Cleanser',
    description: 'Water-based, light',
    url: cleanserFoamingCreamImg,
  },
  [CLEANSING_CREAM]: {
    name: 'Cream Cleanser',
    description: 'Rich, nourishing',
    url: cleansingCreamImg,
  },
};

export const moisturizerTextures = {
  [LIGHT_MOISTURIZER]: {
    name: 'Gel Cream Moisturizer',
    description: 'Water-based, sheer',
    url: moisturizerLightImg,
  },
  [MEDIUM_MOISTURIZER]: {
    name: 'Cream Moisturizer',
    description: 'Lightweight, quick-absorbing',
    url: moisturizerMediumImg,
  },
  [RICH_MOISTURIZER]: {
    name: 'Rich Cream Moisturizer',
    description: 'Dense, nourishing',
    url: moisturizerRichImg,
  },
};
