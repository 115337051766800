import { legacyTheme, styled } from '@prose-ui/legacy';

import SustainanilityWithoutImage from 'Apps/Products/Blocks/SustainanilityWithoutImage';

import Spacer from 'Components/Spacer';
import TextImage from 'Components/TextImage';
import TextVideo from 'Components/TextVideo';
import Typography from 'Components/Typography';

import carouselPicture1 from 'assets/images/productPages/accessories-collection-hair_towel-1.jpg';
import carouselPicture2 from 'assets/images/productPages/accessories-collection-hair_towel-2.jpg';
import carouselPicture3 from 'assets/images/productPages/accessories-collection-hair_towel-3.jpg';
import modulePicture1 from 'assets/images/productPages/hair_towel-module-1.jpg';
import modulePicture2 from 'assets/images/productPages/hair_towel-module-2.jpg';
import BcorpLogo from 'assets/images/skincare/b-corp-logo.png';
import ClimateLogo from 'assets/images/skincare/climate-logo.png';

const Wrapper = styled.div`
  text-align: left;
  padding: 40px;
`;

const TextAlignLeft = styled.div`
  text-align: left;
`;

const Divider = styled.div`
  margin: 32px 0px;
  background-color: ${legacyTheme.palette.common.grey.medium};
  height: 1px;
  width: 100%;
`;

const hairTowelPageContent = {
  modules: [
    {
      key: '1',
      Component: TextImage,
      children: (
        <TextAlignLeft>
          <Typography variant="h2">Ultra-soft microfiber fabric</Typography>
          <Spacer size="14" />
          <Typography variant="p2">
            Made from plush microfiber that’s gentle on all textures, including curls and coils.
            Helps preserve the natural curl pattern and prevent knots, breakage, damage, and
            unwanted frizz.
          </Typography>
          <Divider />
          <Typography variant="h2">Adjustable closure</Typography>
          <Spacer size="14" />
          <Typography variant="p2">
            Crafted in a versatile rectangular shape with an easy elastic strap closure for an
            always-adjustable fit.
          </Typography>
        </TextAlignLeft>
      ),
      props: {
        imageDesktop: modulePicture1,
        imageMobile: modulePicture1,
        wideScreenOrder: '21',
      },
    },
    {
      key: '2',
      Component: SustainanilityWithoutImage,
      props: {
        title: 'Giving plastic bottles a second life',
        description:
          'Every towel is made from the equivalent of 16 recycled 33-ounce water bottles, which helps reduce emissions and redirect plastic waste from landfills.',
        logos: {
          bCorp: BcorpLogo,
          climate: ClimateLogo,
        },
      },
    },
    {
      Component: TextVideo,
      key: '3',
      children: (
        <Wrapper>
          <Typography variant="h2">Easy as 1-2-3</Typography>
          <Spacer size="28" />
          <Typography variant="p2">
            <Typography inline medium>
              Drape:{' '}
            </Typography>
            Flip hair forward and drape towel over it, placing elastic at the nape of the neck.
          </Typography>
          <Divider />
          <Typography variant="p2">
            <Typography inline medium>
              Twist:{' '}
            </Typography>
            Wrap towel around lengths, gently twisting to remove excess moisture.
          </Typography>
          <Divider />
          <Typography variant="p2">
            <Typography inline medium>
              Wrap:{' '}
            </Typography>
            Flip hair back and tuck towel into the non-slip elastic strap for a snug, hands-free
            fit.
          </Typography>
        </Wrapper>
      ),
      props: {
        video: {
          mobile: 'hair-towel-pdp-v2-mobile',
          desktop: 'hair-towel-pdp-desktop',
        },
        fallbackImage: {
          src: {
            mobile: modulePicture2,
            desktop: modulePicture2,
          },
          alt: 'Woman using an hair towel',
        },
      },
    },
  ],
  ticker: ["It's a wrap"],
  heroModule: {
    subDescription: 'Designed for all textures and lengths',
    priceSectionInfo: '23” x 37” in',
    title: 'Hair Towel Wrap',
    imageCarousel: [
      {
        image: carouselPicture1,
        alt: 'Hair towel',
      },
      {
        image: carouselPicture2,
        alt: 'Hair towel with a plant on its side',
      },
      {
        image: carouselPicture3,
        alt: 'Person from the back with a hair towel on her hair',
      },
    ],
  },
  faq: [
    {
      question: 'What is the Hair Towel Wrap made of?',
      answer:
        'Our towels are made from sustainable 100% RPET microfiber, certified by Global Recycled Standard. Each towel is made with the equivalent of 16 recycled 33-ounce water bottles, and is completely free of phthalates and heavy metals. The manufacturing process for each towel reduces approximately 670 gCO2 in emissions as compared with a towel made from non-recycled microfiber.',
    },
    {
      question: 'What are the benefits of using a microfiber towel?',
      answer: (
        <>
          Our highly absorbent towel can help reduce drying time and prevent further damage caused
          by blow drying.
          <Spacer size="14" />
          Microfiber is also known to help reduce friction, which tames frizz and leaves strands
          smooth and soft. The soft, gentle material is suitable for all hair textures and will not
          break natural curl patterns.
          <Spacer size="14" />
          If you have straight hair or want to speed up drying time: our towel can help your hair
          gently dry in less time, for silky soft, frizz-free hair.
          <Spacer size="14" />
          If you have wavy hair or want to reduce frizz: our towel&apos;s fabric is known to be
          extra gentle on waves, leaving them smooth and frizz-free.
          <Spacer size="14" />
          If you have curly hair and want to preserve your curl definition: our towel provides a
          gentle dry which helps enhance your natural curl pattern, leaving you with bouncy and
          defined curls.
          <Spacer size="14" />
          If you have coily hair and want to prevent breakage: our towel&apos;s fabric is gentle
          enough to dry your hair without causing damage or breakage, for healthy, defined coils.
        </>
      ),
    },
    {
      question: 'How do I use the Hair Towel Wrap to dry my hair?',
      answer: (
        <>
          First, flip your hair forward and drape the towel over your hair, placing the elastic at
          the nape of your neck.
          <Spacer size="14" />
          Then, wrap the towel around your lengths, gently twisting to remove moisture and absorb
          excess water.
          <Spacer size="14" />
          Finally, flip your hair back and tuck the end of the towel into the non-slip elastic strap
          for a snug, secure fit.
        </>
      ),
    },
    {
      question: 'What are some tips and tricks for the Hair Towel Wrap?',
      answer: (
        <>
          <b>Plopping:</b> After washing, flip over your damp hair and let it &quot;plop&quot; into
          your towel, then secure with the elastic. This drying method can help increase definition
          and add even more bounce to natural curls.
          <Spacer size="14" />
          <b>Scrunching:</b> Use to scrunch up before applying styling products to enhance your
          natural texture.
          <Spacer size="14" />
          <b>Blotting:</b> Use to blot excess water from your hair for better absorption before
          applying leave-in products.
        </>
      ),
    },
    {
      question: 'How do I clean my Hair Towel Wrap?',
      answer:
        'We recommend machine-washing your hair towel in cold water and hanging it dry. Do not bleach your hair towel.',
    },
    {
      question: 'How do I purchase a Hair Towel Wrap?',
      answer:
        'At this time, Hair Towel Wraps can only be purchased if you have a Prose account. Once you create an account and complete the hair consultation, you can purchase a towel from your Haircare cart.',
    },
  ],
};

export default hairTowelPageContent;
