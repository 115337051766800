import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { keyframes, legacyTheme } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import CircularProgress from 'Components/CircularProgress';

const slideRightKeyframes = keyframes`
  0% { width: 100%; left: 0; }
  45% { width: 0; left: 100%; }
  50% { width: 0; left: 0; }
  100% { width: 100%; }
`;

const useStyles = makeStyles(
  (_theme, _props, classes) => ({
    root: {
      ...legacyTheme.typography.mono2,
      fontWeight: 700,
      display: 'inline-block',
      position: 'relative',
      minWidth: 215,
      maxWidth: '100%',
      height: legacyTheme.props.buttonHeight,
      lineHeight: legacyTheme.props.buttonHeight,
      margin: '11px 0',
      padding: '0px 16px',
      cursor: 'pointer',
      textAlign: 'center',
      transition: `background, color, font-size`,
      transitionDuration: '300ms',
      color: theme.colors.primary[400],
      whiteSpace: 'nowrap',
      width: 'fit-content',
      appearance: 'none',
      '&:hover': {
        color: theme.colors.primary[400],
        background: theme.colors.accent[200],
        border: `1px solid ${theme.colors.accent[200]}`,
      },
      '&:visited': {
        color: 'inherit',
      },
    },
    fixed: {
      zIndex: 100,
      width: '100%',
      margin: 0,
      bottom: 0,
      left: 0,
      position: 'fixed',
    },
    loadingRoot: {
      color: theme.colors.neutral[100],
      position: 'absolute',
      top: '50%',
      marginTop: '-8px',
      left: '50%',
      marginLeft: '-8px',
    },
    sorbet: {
      color: theme.colors.primary[400],
      background: theme.colors.accent[200],
      border: `1px solid ${theme.colors.accent[200]}`,
      '&:hover': {
        color: theme.colors.primary[400],
        background: theme.colors.accent[200],
        border: `1px solid ${theme.colors.accent[200]}`,
      },
    },
    white: {
      background: 'transparent',
      color: theme.colors.neutral[100],
      border: `1px solid ${theme.colors.neutral[100]}`,
      '&:visited': {
        color: theme.colors.neutral[100],
      },
      '&:hover': {
        background: theme.colors.neutral[100],
        color: theme.colors.primary[400],
        border: `1px solid ${theme.colors.neutral[100]}`,
      },
    },
    whiteFull: {
      background: theme.colors.neutral[100],
      color: theme.colors.primary[400],
      border: `1px solid ${theme.colors.neutral[100]}`,
      '&:visited': {
        color: theme.colors.primary[400],
      },
      '&:hover': {
        background: theme.colors.neutral[100],
        color: theme.colors.primary[400],
        border: `1px solid ${theme.colors.neutral[100]}`,
      },
    },

    noir: {
      background: 'transparent',
      color: theme.colors.primary[400],
      border: `1px solid ${theme.colors.primary[400]}`,
      '&:visited': {
        color: theme.colors.primary[400],
      },
      '&:hover': {
        background: theme.colors.primary[400],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
      },
      [`&.${classes.disabled}`]: {
        background: 'transparent',
        color: theme.colors.neutral[700],
        border: `1px solid ${theme.colors.neutral[700]}`,
        '&:hover': {
          background: 'transparent',
          color: theme.colors.neutral[700],
        },
      },
    },
    vertFull: {
      background: theme.colors.primary[300],
      color: theme.colors.neutral[100],
      border: `1px solid ${theme.colors.primary[300]}`,
      '&:visited': {
        color: theme.colors.neutral[100],
      },
      '&:hover': {
        background: theme.colors.primary[300],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[300]}`,
      },
    },
    roundedBlack: {
      background: theme.colors.primary[400],
      color: theme.colors.neutral[100],
      borderRadius: '2rem',

      border: `1px solid ${theme.colors.primary[400]}`,
      '&:visited': {
        color: theme.colors.neutral[100],
      },
      '&:hover': {
        background: theme.colors.primary[300],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[300]}`,
      },
    },
    whiteBg: {
      background: theme.colors.neutral[100],
      color: theme.colors.primary[400],
      border: `1px solid ${theme.colors.neutral[100]}`,
      '&:visited': {
        color: theme.colors.primary[400],
      },
      '&:hover': {
        background: 'transparent',
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.neutral[100]}`,
      },
    },
    whiteBgOutline: {
      background: theme.colors.neutral[100],
      color: theme.colors.primary[400],
      border: `1px solid ${theme.colors.neutral[100]}`,
      '&:hover': {
        background: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
        outline: 'none',
      },
      '&:focus': {
        background: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
        outline: 'none',
      },
    },
    whiteBgOutlineRadius: {
      background: theme.colors.neutral[100],
      color: theme.colors.primary[400],
      border: `1px solid ${theme.colors.neutral[100]}`,
      borderRadius: '30px',
      '&:hover': {
        background: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.neutral[100]}`,
        outline: 'none',
      },
      '&:focus': {
        background: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
        outline: 'none',
      },
    },
    vert: {
      background: theme.colors.primary[300],
      color: theme.colors.neutral[100],
      border: `1px solid ${theme.colors.primary[300]}`,
      '&:visited': {
        color: theme.colors.neutral[100],
      },
      '&:hover': {
        background: theme.colors.primary[400],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
      },
    },
    lime: {
      background: theme.colors.highlight[200],
      color: theme.colors.primary[400],
      border: `1px solid ${theme.colors.highlight[200]}`,
      '&:visited': {
        color: theme.colors.primary[400],
      },
      '&:hover': {
        background: theme.colors.neutral[100],
        color: theme.colors.primary[400],
        border: `1px solid ${theme.colors.highlight[200]}`,
      },
      [`&.${classes.disabled}`]: {
        background: theme.colors.highlight[200],
        color: theme.colors.primary[400],
        border: `1px solid ${theme.colors.highlight[200]}`,
        opacity: 0.2,
        '&:visited': {
          color: theme.colors.primary[400],
        },
        '&:hover': {
          background: theme.colors.highlight[200],
          color: theme.colors.primary[400],
          border: `1px solid ${theme.colors.highlight[200]}`,
          opacity: 0.2,
        },
        '&::after': {
          background: theme.colors.highlight[200],
          opacity: 0.2,
        },
      },
    },
    rouge: {
      background: theme.colors.error[200],
      color: theme.colors.neutral[100],
      border: `1px solid ${theme.colors.error[200]}`,
      '&:visited': {
        color: theme.colors.neutral[100],
      },
      '&:hover': {
        background: theme.colors.primary[400],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
      },
    },
    rougeOutline: {
      background: 'transparent',
      color: theme.colors.error[200],
      border: `1px solid ${theme.colors.error[200]}`,
      '&:visited': {
        color: theme.colors.error[200],
      },
      '&:hover': {
        background: theme.colors.primary[400],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[400]}`,
      },
    },
    vertOutline: {
      background: 'transparent',
      color: theme.colors.primary[300],
      border: `1px solid ${theme.colors.primary[300]}`,
      '&:visited': {
        color: theme.colors.primary[300],
      },
      '&:hover': {
        background: theme.colors.primary[300],
        color: theme.colors.neutral[100],
        border: `1px solid ${theme.colors.primary[300]}`,
      },
    },
    yellow: {
      background: theme.colors.supps[200],
      color: theme.colors.primary[400],
      border: 'none',
      '&:visited': {
        color: theme.colors.primary[400],
      },
      '&:hover': {
        background: theme.colors.supps[200],
        border: 'none',
      },
    },
    add: {
      background: theme.colors.primary[300],
      color: theme.colors.neutral[100],
      border: `0 solid ${theme.colors.neutral[100]}`,
      minWidth: 'inherit',
      height: 55,
      lineHeight: '55px',
      margin: 'inherit',
      width: 117,
      marginBottom: 18,
      '&:visited': {
        color: theme.colors.primary[400],
      },
      '&:hover': {
        background: theme.colors.primary[300],
        color: theme.colors.neutral[100],
        border: `0 solid ${theme.colors.neutral[100]}`,
      },
    },
    disabled: {
      cursor: 'not-allowed',
      color: theme.colors.neutral[100],
      background: theme.colors.neutral[700],
      border: `1px solid ${theme.colors.neutral[700]}`,
      '&:visited': {
        color: theme.colors.neutral[100],
      },
      '&:hover': {
        color: theme.colors.neutral[100],
        background: theme.colors.neutral[700],
        border: `1px solid ${theme.colors.neutral[700]}`,
      },
    },
    underlined: {
      background: 'none',
      lineHeight: '18px',
      height: 'inherit',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'inherit',
      minWidth: 'auto',
      padding: 0,
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '2px',
        width: '100%',
        left: '0%',
        bottom: '-5px',
        transition: 'all .3s ease-out',
        background: theme.colors.accent[200],
      },
      '&:hover': {
        background: 'inherit',
        border: 'inherit',
        '&::after': {
          animation: `${slideRightKeyframes} .6s`,
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.colors.neutral[700],
        '&::after': {
          background: theme.colors.neutral[700],
        },
      },
    },
    underlinedBlack: {
      background: 'none',
      lineHeight: '18px',
      height: 'inherit',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'inherit',
      minWidth: 'auto',
      padding: 0,
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '2px',
        width: '100%',
        left: '0%',
        transition: 'all .3s ease-out',
        background: theme.colors.primary[400],
      },
      '&:hover': {
        background: 'transparent',
        border: 'inherit',
        '&::after': {
          animation: `${slideRightKeyframes} .6s`,
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.colors.neutral[700],
        '&::after': {
          background: theme.colors.neutral[700],
        },
      },
    },
    underlinedBlackMono: {
      fontFamily: theme.typography.fontFamily.label,
      fontSize: theme.typography.fontSize.sm,
      fontWeight: theme.typography.fontWeight.normal,
      lineHeight: 0.5,
      background: 'none',
      height: 'inherit',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'inherit',
      minWidth: 'auto',
      padding: 0,
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '1px',
        width: '100%',
        left: '0%',
        transition: 'all .3s ease-out',
        background: theme.colors.primary[400],
      },
      '&:hover': {
        background: 'transparent',
        border: 'inherit',
        '&::after': {
          animation: `${slideRightKeyframes} .6s`,
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.colors.neutral[700],
        '&::after': {
          background: theme.colors.neutral[700],
        },
      },
    },
    underlinedWhite: {
      background: 'none',
      lineHeight: '18px',
      height: 'inherit',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'inherit',
      minWidth: 'auto',
      padding: 0,
      color: theme.colors.neutral[100],
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '2px',
        width: '100%',
        left: '0%',
        transition: 'all .3s ease-out',
        background: theme.colors.neutral[100],
      },
      '&:hover': {
        background: 'transparent',
        border: 'inherit',
        color: theme.colors.neutral[100],
        '&::after': {
          animation: `${slideRightKeyframes} .6s`,
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.colors.neutral[700],
        '&::after': {
          background: theme.colors.neutral[700],
        },
      },
    },
    tabIsActive: {
      background: 'none',
      lineHeight: '18px',
      height: 'inherit',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'inherit',
      minWidth: 'auto',
      padding: 0,
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '2px',
        width: '100%',
        left: '0%',
        transition: 'all .3s ease-out',
        background: theme.colors.primary[400],
      },
      '&:hover': {
        background: 'transparent',
        border: 'inherit',
        '&::after': {
          animation: `${slideRightKeyframes} .6s`,
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.colors.neutral[700],
        '&::after': {
          background: theme.colors.neutral[700],
        },
      },
    },
    tab: {
      background: 'none',
      lineHeight: '18px',
      height: 'inherit',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'inherit',
      minWidth: 'auto',
      padding: 0,
      '&::after': {
        display: 'block',
        position: 'absolute',
        height: '2px',
        width: '100%',
        left: '0%',
        transition: 'all .3s ease-out',
        background: theme.colors.primary[400],
      },
      '&:hover': {
        background: 'transparent',
        border: 'inherit',
        '&::after': {
          animation: `${slideRightKeyframes} .6s`,
          content: '""',
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.colors.neutral[700],
        '&::after': {
          display: 'none',
        },
      },
    },
    fullWidth: {
      width: '100%',
    },
    marginTop: {
      marginTop: legacyTheme.spacing.s40,
    },
    noMargin: {
      margin: 0,
    },
    boxShadow: {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  }),
  { name: 'Button' }
);

export const buttonVariants = /** @type {const} */ [
  'roundedBlack',
  'sorbet',
  'white',
  'whiteFull',
  'noir',
  'vertFull',
  'whiteBg',
  'whiteBgOutline',
  'whiteBgOutlineRadius',
  'vert',
  'lime',
  'rouge',
  'rougeOutline',
  'vertOutline',
  'yellow',
  'add',
  'underlined',
  'underlinedBlack',
  'underlinedWhite',
  'underlinedBlackMono',
  'tabIsActive',
  'tab',
  'none',
];

const filledVariants = buttonVariants.slice(0, 13);
const textVariants = buttonVariants.slice(13);

const Button = ({
  Component,
  className,
  children,
  disabled,
  fixed,
  fullWidth,
  isLoading,
  marginTop,
  noMargin,
  onClick,
  variant,
  hasBoxShadow,
  ...props
}) => {
  const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);

  return (
    <Component
      className={cx(
        classes.root,
        { [classes[variant]]: filledVariants.includes(variant) },
        { [classes.fixed]: fixed },
        { [classes.disabled]: disabled },
        { [classes[variant]]: textVariants.includes(variant) },
        { [classes.fullWidth]: fullWidth },
        { [classes.marginTop]: marginTop },
        { [classes.noMargin]: noMargin },
        { [classes.boxShadow]: hasBoxShadow },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {isLoading ? <CircularProgress classes={{ root: classes.loadingRoot }} /> : children}
    </Component>
  );
};

Button.defaultProps = {
  Component: 'button',
  className: null,
  classes: null,
  disabled: false,
  fixed: false,
  fullWidth: false,
  isLoading: false,
  marginTop: false,
  noMargin: false,
  onClick: null,
  hasBoxShadow: false,
  variant: 'sorbet',
};

Button.propTypes = {
  /**
   * Change the inner html tag used to represent the component.
   */
  Component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.oneOf(['button', 'label', 'a']),
  ]),
  /**
   * Enables to overrides the element inner styles.
   * Use with caution.
   */
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  /**
   * Adds supplementary styles on the element.
   * Use with caution.
   */
  className: PropTypes.string,

  /**
   * The element will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The element be postionned at the bottom of the viewport and will
   * occupy 100vw.
   */
  fixed: PropTypes.bool,
  /**
   * The element will have width 100%.
   */
  fullWidth: PropTypes.bool,
  /**
   * The element will display a loading spinner.
   */
  isLoading: PropTypes.bool,
  /**
   * Adds a margin a the top of the element.
   */
  marginTop: PropTypes.bool,
  /**
   * Removes all margin on the element.
   */
  noMargin: PropTypes.bool,
  /**
   * Triggers a callback when the element is clicked.
   */
  variant: PropTypes.oneOf(buttonVariants),
  /**
   * Adds a box shadow to the bottom of the button.
   */
  hasBoxShadow: PropTypes.bool,
  onClick: PropTypes.func,
  /**
   * Changes the element appearance.
   */
};

/**
 * @deprecated Please use Button instead.
 */
export default Button;
