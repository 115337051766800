import Typography from 'Components/Typography';

import * as hairTextures from 'constants/hairTextures';
import { HAIRCARE_QUESTION_LENGTH, MY_HAIR } from '../constants';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { HAIR_SCALP } from '../constants/progressCategories';

import longHairTexture1 from 'assets/images/consultation/length/type1-long.jpg';
import shortHairTexture1 from 'assets/images/consultation/length/type1-short.jpg';
import shoulderHairTexture1 from 'assets/images/consultation/length/type1-shoulder.jpg';
import veryLongHairTexture1 from 'assets/images/consultation/length/type1-very_long.jpg';
import veryShortHairTexture1 from 'assets/images/consultation/length/type1-very_short.jpg';
import longHairTexture2 from 'assets/images/consultation/length/type2-long.jpg';
import shortHairTexture2 from 'assets/images/consultation/length/type2-short.jpg';
import shoulderHairTexture2 from 'assets/images/consultation/length/type2-shoulder.jpg';
import veryLongHairTexture2 from 'assets/images/consultation/length/type2-very_long.jpg';
import veryShortHairTexture2 from 'assets/images/consultation/length/type2-very_short.jpg';
import longHairTexture3 from 'assets/images/consultation/length/type3-long.jpg';
import shortHairTexture3 from 'assets/images/consultation/length/type3-short.jpg';
import shoulderHairTexture3 from 'assets/images/consultation/length/type3-shoulder.jpg';
import veryLongHairTexture3 from 'assets/images/consultation/length/type3-very_long.jpg';
import veryShortHairTexture3 from 'assets/images/consultation/length/type3-very_short.jpg';
import longHairTexture4 from 'assets/images/consultation/length/type4-long.jpg';
import shortHairTexture4 from 'assets/images/consultation/length/type4-short.jpg';
import shoulderHairTexture4 from 'assets/images/consultation/length/type4-shoulder.jpg';
import veryLongHairTexture4 from 'assets/images/consultation/length/type4-very_long.jpg';
import veryShortHairTexture4 from 'assets/images/consultation/length/type4-very_short.jpg';

const hairLengthsTipImages = {
  texture1: {
    very_short: veryShortHairTexture1,
    short: shortHairTexture1,
    shoulder: shoulderHairTexture1,
    long: longHairTexture1,
    very_long: veryLongHairTexture1,
  },
  texture2: {
    very_short: veryShortHairTexture2,
    short: shortHairTexture2,
    shoulder: shoulderHairTexture2,
    long: longHairTexture2,
    very_long: veryLongHairTexture2,
  },
  texture3: {
    very_short: veryShortHairTexture3,
    short: shortHairTexture3,
    shoulder: shoulderHairTexture3,
    long: longHairTexture3,
    very_long: veryLongHairTexture3,
  },
  texture4: {
    very_short: veryShortHairTexture4,
    short: shortHairTexture4,
    shoulder: shoulderHairTexture4,
    long: longHairTexture4,
    very_long: veryLongHairTexture4,
  },
};

const myHairLength = {
  name: HAIRCARE_QUESTION_LENGTH,
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/length`,
  title: 'How long is your hair?',
  subtitle: 'If you have curly hair, pull the curl all the way down to find your true length.',
  tip: {
    title: 'More of a visual learner? Tap for pics',
    content: 'If you are stuck between 2 lengths, choose the longer one.',
    richContent: {
      Component: consultationTipsComponents.SliderImageAndContent,
      slides: ({ hairTexture }) => {
        let texture = null;
        switch (hairTexture) {
          case hairTextures.ONE:
            texture = 1;
            break;
          case hairTextures.WAVY:
          case hairTextures.TWO_A:
          case hairTextures.TWO_B:
          case hairTextures.TWO_C:
            texture = 2;
            break;
          case hairTextures.DEPRECATED_CURLY: // User would probably have gone through texture question and change their answer
          case hairTextures.THREE_A:
          case hairTextures.THREE_B:
          case hairTextures.THREE_C:
            texture = 3;
            break;
          case hairTextures.VERY_CURLY:
          case hairTextures.FRIZZY:
          case hairTextures.ZIGZAG:
            texture = 4;
            break;
          // Most of our user base define themselves as a texture 3
          default:
            texture = 3;
        }

        const lengths = [
          { id: 'very_short', name: 'Short Hair', heapTracker: 'Length Short' },
          { id: 'short', name: 'Chin-length hair', heapTracker: 'Length Chin' },
          { id: 'shoulder', name: 'Shoulder length hair', heapTracker: 'Length Shoulder' },
          { id: 'long', name: 'Long hair', heapTracker: 'Length Long' },
          { id: 'very_long', name: 'Very long hair', heapTracker: 'Length Very Long' },
        ];
        return lengths.map(({ id, name, heapTracker }) => ({
          id,
          image: {
            src: hairLengthsTipImages[`texture${texture}`][id],
            alt: `Picture of a texture ${texture} person with ${name.toLocaleLowerCase()}`,
          },
          // TODO: Replace Typography with a componentless solution
          content: (
            <Typography align="center" variant="p3">
              {name}
            </Typography>
          ),
          heapTracker,
        }));
      },
    },
  },
  options: [
    { value: 'very_short', label: 'Short', description: 'Buzz-cut to early-Beatles' },
    { value: 'short', label: 'Chin-length', description: 'Doesn’t touch shoulders' },
    {
      value: 'shoulder',
      label: 'Shoulder length',
      description: 'Sits on shoulders or falls slightly past',
    },
    { value: 'long', label: 'Long', description: 'Below shoulder blades to mid-back' },
    { value: 'very_long', label: 'Very Long', description: 'Mid-back and beyond' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_hair_length,
  getAnswers: ({ selected }) => ({ diag_hair_length: selected }),
};

export default myHairLength;
