import * as skincareFragranceSlugs from 'constants/skincareFragrances';

import { FRAGRANCE_FREE as HAIRCARE_FRAGRANCE_FREE } from 'assets/content/haircareFragrances';
import pacificaImg from 'assets/images/fragrances/fragrance_pacifica.png';
import blancaImg from 'assets/images/fragrances/prose-blanca-swatch.jpg';

export const PACIFICA = {
  name: 'Pacifica',
  slug: skincareFragranceSlugs.PACIFICA,
  description: 'WOODY + AROMATIC',
  details: 'Lavender, Lemongrass, Cypress',
  url: pacificaImg,
  imageAlt: 'Prose pacifica swatch',
};

export const BLANCA = {
  name: 'Blanca',
  slug: skincareFragranceSlugs.BLANCA,
  description: 'CLEAN + FLORAL',
  details: 'Crisp linen, Ambrette, Rose',
  url: blancaImg,
  imageAlt: 'Prose blanca swatch',
};

export const FRAGRANCE_FREE = {
  ...HAIRCARE_FRAGRANCE_FREE,
  slug: skincareFragranceSlugs.FRAGRANCE_FREE,
  details: 'You may notice a light scent due to your custom ingredient blend',
};
