import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { connect } from 'react-redux';

import find from 'lodash/fp/find';

import * as authSelectors from 'dux/auth/selectors';
import * as featureFlagsSelector from 'dux/featureFlags/selectors';
import {
  getFirstActiveGiftWithPurchaseBannerData,
  getIsGiftWithPurchaseBannerActive,
} from 'dux/giftWithPurchase/selectors';
import { getShowSkincareStarterSetPromo } from 'dux/promotions/selectors';
import * as userSelectors from 'dux/user/selectors';

import * as Banners from './Banners';

const bannersByPriority = [
  Banners.loading, // Loading needs to be first
  Banners.checkoutMessage,
  Banners.trialOfferMixedSkincareMinisBanner,
  Banners.skincareMinisBanner,
  Banners.trialOfferBanner,
  Banners.promotionAccessoriesOffer,
  Banners.giftWithPurchase,
  Banners.referralCta,
  Banners.membershipMultiCategoryWithOneSubscription,
  Banners.membershipMultiCategoryWithoutSubscription,
  Banners.membershipCta,
  Banners.noFreeShippingContent,
  Banners.supplementsPages,
  Banners.defaultBanner, // Default needs to be last
];

const Banner = ({
  canSubscribe,
  haveSubscription,
  hideBannerLink,
  isAuthenticated,
  isAuthenticating,
  isFlagsDoneFetching,
  isGiftWithPurchaseEligible,
  giftWithPurchaseBannerData,
  hasEitherHairOrSkinSubscription,
  hasHaircareSubscription,
  hasSkincareSubscription,
  hasHairAndSkinSubscriptions,
  hasCompletedHaircareConsultation,
  hasCompletedSkincareConsultation,
  hasStartedHaircareConsultation,
  hasStartedSkincareConsultation,
  showPromotionAccessories2023,
  showTrialOffer,
  hasSkincareOrder,
  showSkincareStarterSetPromo,
  hasSkincareSubscriptionInAnyState,
  hasHaircareSubscriptionInAnyState,
  pathname: locationPathname,
  searchParams: locationSearchParams,
  nextMode,
}) => {
  const foundBanner = find(({ shouldRender }) =>
    shouldRender({
      isAuthenticated,
      canSubscribe,
      haveSubscription,
      isFlagsDoneFetching,
      isGiftWithPurchaseEligible,
      locationPathname,
      locationSearchParams,
      giftWithPurchaseBannerData,
      hasEitherHairOrSkinSubscription,
      hasHaircareSubscription,
      hasSkincareSubscription,
      hasHairAndSkinSubscriptions,
      hasCompletedHaircareConsultation,
      hasCompletedSkincareConsultation,
      hasStartedHaircareConsultation,
      hasStartedSkincareConsultation,
      showPromotionAccessories2023,
      showTrialOffer,
      hasSkincareOrder,
      showSkincareStarterSetPromo,
      hasSkincareSubscriptionInAnyState,
      hasHaircareSubscriptionInAnyState,
    })
  )(bannersByPriority);

  if (foundBanner) {
    return foundBanner.render(
      hideBannerLink || isAuthenticating || !isAuthenticated || haveSubscription || !canSubscribe,
      {
        giftWithPurchaseBannerData,
        hasHaircareSubscription,
        hasSkincareSubscription,
        hasCompletedHaircareConsultation,
        hasCompletedSkincareConsultation,
        nextMode,
      }
    );
  }
  return null;
};

Banner.propTypes = forbidExtraProps({
  canSubscribe: PropTypes.bool,
  showSkincareStarterSetPromo: PropTypes.bool,
  hasSkincareSubscriptionInAnyState: PropTypes.bool,
  hasHaircareSubscriptionInAnyState: PropTypes.bool,
  haveSubscription: PropTypes.bool,
  hideBannerLink: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isAuthenticating: PropTypes.bool,
  isFlagsDoneFetching: PropTypes.bool,
  isGiftWithPurchaseEligible: PropTypes.bool,
  giftWithPurchaseBannerData: PropTypes.shape({
    lp_images: PropTypes.shape({
      mobile: PropTypes.string,
      tablet: PropTypes.string,
      desktop: PropTypes.string,
    }),
    lp_cta_text: PropTypes.string,
    lp_cta_url: PropTypes.string,
    lp_gift_description: PropTypes.string,
    lp_legal_text: PropTypes.string,
    lp_title: PropTypes.string,
    banner_color: PropTypes.string,
    banner_redirection_url: PropTypes.string,
    banner_text: PropTypes.string,
  }),
  hasSkincareOrder: PropTypes.bool,
  hasEitherHairOrSkinSubscription: PropTypes.bool,
  hasHaircareSubscription: PropTypes.bool,
  hasSkincareSubscription: PropTypes.bool,
  hasHairAndSkinSubscriptions: PropTypes.bool,
  hasCompletedHaircareConsultation: PropTypes.bool,
  hasCompletedSkincareConsultation: PropTypes.bool,
  hasStartedHaircareConsultation: PropTypes.bool,
  hasStartedSkincareConsultation: PropTypes.bool,
  showPromotionAccessories2023: PropTypes.bool,
  showTrialOffer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  pathname: PropTypes.string.isRequired,
  searchParams: PropTypes.string.isRequired,
  nextMode: PropTypes.bool,
});

Banner.defaultProps = {
  canSubscribe: false,
  haveSubscription: false,
  hideBannerLink: false,
  isAuthenticated: false,
  isAuthenticating: true,
  isFlagsDoneFetching: false,
  isGiftWithPurchaseEligible: false,
  giftWithPurchaseBannerData: null,
  hasEitherHairOrSkinSubscription: false,
  hasSkincareOrder: false,
  hasHaircareSubscription: false,
  hasSkincareSubscription: false,
  hasHairAndSkinSubscriptions: false,
  hasCompletedHaircareConsultation: false,
  hasCompletedSkincareConsultation: false,
  hasStartedHaircareConsultation: false,
  hasStartedSkincareConsultation: false,
  showPromotionAccessories2023: false,
  showTrialOffer: false,
  showSkincareStarterSetPromo: false,
  hasSkincareSubscriptionInAnyState: false,
  hasHaircareSubscriptionInAnyState: false,
  nextMode: false,
};

// Name to be changed
export const StatelessBanner = Banner;

export default connect(
  state => ({
    canSubscribe: userSelectors.getCanSubscribe(state),
    haveSubscription: userSelectors.getHasActiveSubscription(state),
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    isAuthenticating: authSelectors.getIsAuthenticating(state),
    isFlagsDoneFetching: featureFlagsSelector.isFlagsDoneFetching(state),
    isGiftWithPurchaseEligible: getIsGiftWithPurchaseBannerActive(state),
    giftWithPurchaseBannerData: getFirstActiveGiftWithPurchaseBannerData(state),
    hasEitherHairOrSkinSubscription: userSelectors.getHasEitherHairOrSkinSubscription(state),
    hasSkincareOrder: userSelectors.getHasOrdersByCategory(state).skincare,
    hasHaircareSubscription: userSelectors.getHasActiveHaircareSubscription(state),
    hasSkincareSubscription: userSelectors.getHasActiveSkincareSubscription(state),
    hasHairAndSkinSubscriptions: userSelectors.getHasHairAndSkinSubscriptions(state),
    hasCompletedHaircareConsultation: userSelectors.getHasCompletedHaircareConsultation(state),
    hasCompletedSkincareConsultation: userSelectors.getHasCompletedSkincareConsultation(state),
    hasStartedHaircareConsultation: userSelectors.getHasDraftHaircareConsultation(state),
    hasStartedSkincareConsultation: userSelectors.getHasDraftSkincareConsultation(state),
    showPromotionAccessories2023:
      featureFlagsSelector.shouldShowPromotionAccessoriesforTargetedUser(state),
    showTrialOffer: featureFlagsSelector.shouldShowTrialOffer(state),
    showSkincareStarterSetPromo: getShowSkincareStarterSetPromo(state),
    hasSkincareSubscriptionInAnyState: userSelectors.getHasSkincareSubscriptionInAnyState(state),
    hasHaircareSubscriptionInAnyState: userSelectors.getHasHaircareSubscriptionInAnyState(state),
  }),
  {}
)(Banner);
