import noListImgDesktop from 'assets/images/productPages/no-list-science-ingredients-desktop.jpg';
import noListImgMobile from 'assets/images/productPages/no-list-science-ingredients-mobile.jpg';

export default {
  guarantee: 'Love your first order (or it’s on us)',
  soldOutNotice: 'This product is no longer available at this time.',
  waitingListNotice: 'Join the waitlist to be notified if it becomes available again.',
  noListModule: {
    cta: {
      label: 'Learn about our ingredients',
      link: '/ingredients',
    },
    image: {
      alt: 'Serene-looking blonde woman with eyes closed laying in grass with long blonde hair spread out around head.',
      imageDesktop: noListImgDesktop,
      imageMobile: noListImgMobile,
    },
    list: [
      'Cruelty-free',
      'PETA Approved',
      'Alcohol-free',
      'Sulfate-free',
      'Paraben-free',
      'Mineral oil-free',
      'Phthalate-free',
      'GMO-free',
      'Available fragrance-free',
      'Silicone-free options',
      'Vegan options',
    ],
  },
  faqModule: {
    header: 'Frequently asked questions',
  },
  productsModule: {
    headline: 'Discover more',
    skincareHeadline: 'Complete your skincare routine',
  },
};
