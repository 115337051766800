import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

const myHairShampooFrequency = {
  name: 'shampoo-frequency',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/shampoo-frequency`,
  title: 'How often do you wash your hair?',
  subtitle: 'This includes shampooing and co-washing.',
  tip: {
    title: 'Fun fact no. 222',
    content:
      'The average shower uses 2.1 gallons of water per minute. Skipping a wash day and opting for dry shampoo a few times a week can save time and water resources.',
  },
  options: [
    { value: 'every_day', label: 'Every day' },
    { value: 'every_2_3_days', label: 'Every 2-3 days' },
    { value: 'once_a_week', label: 'Once a week' },
    { value: 'less_once_a_week', label: 'Less than once a week' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_shampoo_frequency,
  getAnswers: ({ selected }) => ({ diag_shampoo_frequency: selected }),
};

export default myHairShampooFrequency;
