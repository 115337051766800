import * as consultationComponents from '../constants/consultationComponents';

import agnosticIntroDesktop from 'assets/images/consultation/welcomeScreens/prose-assortment-of-haircare-and-skincare-products-desktop.jpg';
import agnosticIntroMobile from 'assets/images/consultation/welcomeScreens/prose-assortment-of-haircare-and-skincare-products-mobile.jpg';
import agnosticIntroTablet from 'assets/images/consultation/welcomeScreens/prose-assortment-of-haircare-and-skincare-products-tablet.jpg';

const consultationWelcome = {
  name: 'welcome',
  route: `/consultation`,
  Component: consultationComponents.Welcome,
  title: "We're excited to get started on your consultation.",
  subtitle:
    'We’ll ask a series of questions to better understand your lifestyle, environment and unique hair and skin needs so we can formulate your custom routine.',
  cta: 'Begin',
  image: {
    mobile: agnosticIntroMobile,
    tablet: agnosticIntroTablet,
    desktop: agnosticIntroDesktop,
  },
  shouldBeIncluded: () => true,
  public: true,
  skipSave: true,
  showProgressTracker: false,
};

export default consultationWelcome;
