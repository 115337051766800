// TOOO: Replace Typograpgy with a componentless solution
import Typography from 'Components/Typography';

import { MY_HAIR } from '../constants';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { HAIR_SCALP } from '../constants/progressCategories';

import splitEndsBranchImage from 'assets/images/consultation/splitEnds/branch.jpg';
import splitEndsCenterImage from 'assets/images/consultation/splitEnds/center.jpg';
import splitEndsKnotImage from 'assets/images/consultation/splitEnds/knot.jpg';
import splitEndsStandardImage from 'assets/images/consultation/splitEnds/standard.jpg';
import splitEndsTaperedImage from 'assets/images/consultation/splitEnds/tapered.jpg';
import splitEndsTreeImage from 'assets/images/consultation/splitEnds/tree.jpg';

const myHairSplitEnds = {
  name: 'split-ends',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/split-ends`,
  title: 'Do you get split ends?',
  subtitle:
    'Check your ends right now—do you see any fiber divisions, white specks, or tiny knots?',
  tip: {
    title: 'Did you know there are 6 types of splits?',
    content:
      'No haircare can make your ends grow back together, but we can prevent more happening in future. In the meantime, we recommend a trim.',
    richContent: {
      Component: consultationTipsComponents.SliderImageAndContent,
      slides: () => [
        {
          id: 'standard',
          heapTracker: 'Split Ends Standard',
          image: { src: splitEndsStandardImage, alt: 'Illustration of a standard split ends' },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Standard split
              </Typography>
              <Typography align="center" variant="p3">
                Most common split end where the ends of hair separate at the tip of a hair strand,
                forming a ‘Y’.
              </Typography>
            </>
          ),
        },
        {
          id: 'branch',
          heapTracker: 'Split Ends Branch',
          image: { src: splitEndsBranchImage, alt: 'Illustration of a branch split ends' },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Branch split
              </Typography>
              <Typography align="center" variant="p3">
                When hair endures damage in a particular spot on the strand forcing the strand to
                split on one side.
              </Typography>
            </>
          ),
        },
        {
          id: 'tree',
          heapTracker: 'Split Ends Tree',
          image: { src: splitEndsTreeImage, alt: 'Illustration of a tree split ends' },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Tree split
              </Typography>
              <Typography align="center" variant="p3">
                When a ‘branch split’ hasn’t been addressed right away, multiple layers of the
                hair’s cuticle will continue to split forming a ‘tree.’
              </Typography>
            </>
          ),
        },
        {
          id: 'tapered',
          heapTracker: 'Split Ends Tapered',
          image: { src: splitEndsTaperedImage, alt: 'Illustration of a tapered split' },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Tapered split
              </Typography>
              <Typography align="center" variant="p3">
                When layers of the hair’s cuticle has been lost the hair strand begins to resemble a
                tapered look, exposing the cortex leaving hair susceptible to even further damage.
              </Typography>
            </>
          ),
        },
        {
          id: 'center',
          heapTracker: 'Split Ends Center',
          image: { src: splitEndsCenterImage, alt: 'Illustration of a Center split ends' },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Center split
              </Typography>
              <Typography align="center" variant="p3">
                When a certain spot of the hair strand becomes weak and hasn’t fully separated yet.
              </Typography>
            </>
          ),
        },
        {
          id: 'knot',
          heapTracker: 'Split Ends Single Knot',
          image: { src: splitEndsKnotImage, alt: 'Illustration of a Single knot' },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Single knot
              </Typography>
              <Typography align="center" variant="p3">
                Most common in curly and coily hair types, where a strand of hair has wound itself
                into a knot causing the hair cuticle to weaken and unravel in time.
              </Typography>
            </>
          ),
        },
      ],
    },
  },
  options: [
    { value: 'yes', label: 'Yes, plenty' },
    { value: 'not_sure', label: 'Only a few' },
    { value: 'no', label: 'None in sight' },
  ],
  // Dynamic logic
  shouldBeIncluded: ({ answers }) => answers.diag_hair_length !== 'very_short',
  getInitialValue: answers => answers.diag_split_ends,
  getAnswers: ({ selected }) => ({ diag_split_ends: selected }),
};

export default myHairSplitEnds;
