const SENSITIVE_SKIN_REASON = 'fragrance_free_recommender_sensitive_skin_v1';
const PREGNANT_REASON = 'fragrance_free_recommender_pregnant_v1';
const SKIN_CONDITION_REASON = 'fragrance_free_recommender_skin_condition_v1';
const MEDICAL_TREATMENT_REASON = 'fragrance_free_recommender_medical_treatment_v1';

export const skincareRecommendedReasons = {
  [SENSITIVE_SKIN_REASON]:
    'Because you indicated that you have sensitive skin, we recommend fragrance-free for you.',
  [PREGNANT_REASON]:
    "Because you indicated that you're pregnant or trying to get pregnant, we recommend fragrance-free for you.",
  [SKIN_CONDITION_REASON]:
    'Because you indicated that you are living with a skin condition, we recommend fragrance-free for you.',
  [MEDICAL_TREATMENT_REASON]:
    'Because you indicated that you are under medical treatment, we recommend fragrance-free for you.',
};
