import { productsSlugs } from 'constants/products';
import * as consultationComponents from '../constants/consultationComponents';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

import cleansingCream from 'assets/images/consultation/cleansing/tip-cleansing-cream.jpg';
import cleansingGel from 'assets/images/consultation/cleansing/tip-cleansing-gel.jpg';

const cleanserTextures = {
  Component: consultationComponents.SkincareTextures,
  productSlug: productsSlugs.CLEANSER,
  name: 'cleanserTextures',
  category: PREFERENCES_GOALS,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/skincare/${PREFERENCES_GOALS}/cleanser-preferences`,
  title: 'Choose your cleanser’s texture',
  subtitle: 'Color and scent can vary slightly from formula to formula.',
  tip: {
    richContent: {
      Component: consultationTipsComponents.SliderOfSlider,
      contentComesFirst: true,
      sliders: [
        {
          sliderId: 'cleansingGel',
          sliderTitleLegend: 'Gel Cleanser',
          sliderBodyLegend:
            'Our Gel Cleanser provides a nice amount of foam, efficiently removing dirt, sebum, and other impurities from your skin without leaving it feeling stripped.',
          sliderName: 'Gel Cleanser',
          slidesContent: [
            {
              id: 'cleansingGel',
              heapTracker: 'cleansingGel',
              image: {
                src: cleansingGel,
                alt: 'cleansing-gel',
              },
            },
          ],
        },
        {
          sliderId: 'cleanserCream',
          sliderTitleLegend: 'Cream Cleanser',
          sliderBodyLegend:
            'Formulated for the most sensitive skin, our Cream Cleanser has a thicker, milky consistency for a mild yet effective cleanse.',
          sliderName: 'Cream Cleanser',
          slidesContent: [
            {
              id: 'cleanser-cream',
              heapTracker: 'cleanser cream',
              image: {
                src: cleansingCream,
                alt: 'creamy-cleanser',
              },
            },
          ],
        },
      ],
    },
    title: 'Need help deciding?',
  },
  shouldBeIncluded: () => true,
  getInitialValue: answers => ({
    pref_texture_cleanser: answers?.pref_texture_cleanser,
  }),
  getAnswers: ({ selected }) => ({
    pref_texture_cleanser: selected,
  }),
};

export default cleanserTextures;
