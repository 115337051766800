import { Link } from 'react-router-dom';

import babassuOilImg from 'assets/images/productPages/ingredients/babassu_oil.png';
import barbaryFigOilImg from 'assets/images/productPages/ingredients/barbary_fig_oil.png';
import beechOilImg from 'assets/images/productPages/ingredients/beech_oil.png';
import chicoryOilImg from 'assets/images/productPages/ingredients/chicory_oil.png';
import cucumberOilImg from 'assets/images/productPages/ingredients/cucumber_oil.png';
import appleCiderVinegarImg from 'assets/images/productPages/ingredients/grape_seed_oil.png';
import kalahariMelonOilImg from 'assets/images/productPages/ingredients/kalahari_melon_oil.png';
import maracujaOilImg from 'assets/images/productPages/ingredients/maracuja_oil.png';
import pequiOilImg from 'assets/images/productPages/ingredients/pequi_oil.png';
import riceBranOilImg from 'assets/images/productPages/ingredients/rice_bran_oil.png';
import rosehipOilImg from 'assets/images/productPages/ingredients/rosehip_oil.png';
import sachaInchiOilmg from 'assets/images/productPages/ingredients/sacha_inchi_oil.png';
import sheaOilImg from 'assets/images/productPages/ingredients/shea_oil.png';
import siberianPineNutOilImg from 'assets/images/productPages/ingredients/siberian_pine_nut_oil.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-hair-oil.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-hair-oil-dropper-in-hand.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-hair-oil-products.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-posing-with-custom-hair-oil-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-posing-with-custom-hair-oil-mobile.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-using-custom-hair.jpg';
import carouselImg5 from 'assets/images/productPages/prose-ugc-hairoil.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "PAIGE'S NOURISHING + REPAIRING HAIR OIL",
      ingredients: [
        {
          image: appleCiderVinegarImg,
          name: 'Grapeseed Oil',
          benefit: 'Renewal',
        },
        {
          image: cucumberOilImg,
          name: 'Cucumber Oil',
          benefit: 'Hair Health',
        },
        {
          image: siberianPineNutOilImg,
          name: 'Siberian Pine Nut Oil',
          benefit: 'Shine',
        },
        {
          image: sheaOilImg,
          name: 'Shea Oil',
          benefit: 'Repair',
        },
        {
          image: riceBranOilImg,
          name: 'Rice Bran Oil',
          benefit: 'Split End Repair',
        },
        {
          image: rosehipOilImg,
          name: 'Rosehip Oil',
          benefit: 'Moisture',
        },
      ],
    },
    {
      title: "RYAN'S MOISTURIZING + ANTI-FRIZZ HAIR OIL",
      ingredients: [
        {
          image: sachaInchiOilmg,
          name: 'Sacha Inchi Oil',
          benefit: 'Repair',
        },
        {
          image: babassuOilImg,
          name: 'Babassu Oil',
          benefit: 'Hair Health',
        },
        {
          image: siberianPineNutOilImg,
          name: 'Siberian Pine Nut Oil',
          benefit: 'Shine',
        },
        {
          image: chicoryOilImg,
          name: 'Chicory Oil',
          benefit: 'Nourishment',
        },
        {
          image: beechOilImg,
          name: 'Beech Oil',
          benefit: 'Moisture',
        },
        {
          image: pequiOilImg,
          name: 'Pequi Oil',
          benefit: 'Anti-Frizz',
        },
      ],
    },
    {
      title: "TY'S HYDRATING + SHINE-ENHAnCING HAIR OIL",
      ingredients: [
        {
          image: kalahariMelonOilImg,
          name: 'Kalahari Melon Oil',
          benefit: 'Renewal',
        },
        {
          image: barbaryFigOilImg,
          name: 'Barbary Fig Oil',
          benefit: 'Nourishment',
        },
        {
          image: maracujaOilImg,
          name: 'Maracuja Oil',
          benefit: 'Repair',
        },
        {
          image: cucumberOilImg,
          name: 'Cucumber Oil',
          benefit: 'Hair Health',
        },
        {
          image: riceBranOilImg,
          name: 'Rice Bran Oil ',
          benefit: 'Split End Repair',
        },
        {
          image: rosehipOilImg,
          name: 'Rosehip Oil',
          benefit: 'Moisture',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'noir',
      number: '625',
      description:
        'Use a few drops of your oil on wet hair for fuss-free air drying or on freshly-styled hair to eliminate frizz and smooth out flyaways.',
    },
    {
      background: 'noir',
      number: '421',
      description:
        'Start with your smallest recommended amount, and build up as you go to avoid a too-slick look.',
    },
    {
      background: 'noir',
      number: '914',
      description:
        'Before your next color appointment, apply custom hair oil on strands to preserve their natural oils. ' +
        'Your colorist should apply dye directly over your custom hair oil.',
    },
  ],
  faq: [
    {
      question: 'What does hair oil do?',
      answer: (
        <>
          Prose Custom Hair Oil helps to recreate the hair’s natural lipid layer, which protects
          strands from damage, dryness, and unwanted frizz. By forming a protective film around the
          fiber, it strengthens the waterproof barrier of the cuticle and rebalances hydration to
          bring back shiny and soft lengths. We believe the best hair oil is one that is
          personalized to your unique needs. Take the{' '}
          <Link to="/consultation/haircare">consultation</Link> to find your formula.
        </>
      ),
    },
    {
      question: 'How do you use hair oil?',
      answer:
        'Let us count the ways. From lengths to ends, Prose Custom Hair Oil helps to tame flyaways, smooth unwanted frizz, and seal in moisture to nurture and hydrate dry and damaged hair. Made with precious natural oils, it’s a luxurious and personalized upgrade to off-the-shelf alternatives that don’t account for your specific needs and preferences.',
    },
    {
      question: 'What are the benefits of hair oil?',
      answer:
        'Each custom hair oil contains almond oil, and may contain additional tree nut oils. ' +
        'To see if custom oil can work for you, feel free to send us a note with your specific allergy concerns at hello@prose.com.',
    },
    {
      question: 'How often should you oil your hair?',
      answer:
        'Great question. It really depends on how you’re using the product and on your specific hair needs and styling preferences. For example, you can use your Custom Hair Oil as a deeply reparative “hair oiling” treatment once or twice a week for a boost of nourishment to dry, stressed strands. On the other hand, you might use a few drops to tame unwanted frizz and smooth flyaways in your daily styling routine.Keep in mind that we’ll always provide a detailed guide on how to use your Custom Hair Oil, tailored to your unique needs and hair goals.',
    },
    {
      question: 'Is Custom Hair Oil suitable for all hair types?',
      answer:
        'Absolutely. Every hair type can benefit from Prose Custom Hair Oil. Each blend is personalized to 85+ factors and formulated to target your specific hair needs and styling preferences. No matter your hair type or texture — be it fine and straight or thick and curly — your unique formula will provide just the right amount of hydration and nutrition without ever weighing down your strands.',
    },
    {
      question: 'Does custom hair oil contain fragrance?',
      answer:
        'The choice is yours. You can select either a fragrance-free hair oil or a scented oil, depending on your preferences. Our fragrance-free Custom Hair Oil contains no perfume, alcohol, or added fragrance, but it may have a slightly sweet, nutty, or neutral scent depending on your unique blend of ingredients. If you opt for fragrance in the consultation, your Custom Hair Oil will come with “Solstice” — a warm and gourmand fragrance, with notes of Vanilla, Caramel, and Musk. The scent is exclusive to Hair Oil. ',
    },
    {
      question: 'Can Prose Custom Hair Oil treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom hair oil packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom hair oil dropper in hand',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom hair oil',
    },
    {
      image: carouselImg4,
      alt: 'Assortment of Prose custom hair oil bottles',
    },
    {
      image: carouselImg5,
      alt: 'Models posing with custom hair oil bottles',
    },
  ],
  noListModule: {
    description:
      'Every ingredient in your Custom Hair Oil is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A hair oil with naturally powerful + proven-effective ingredients',
  },
  formulaModule: {
    header: (
      <>
        A personalized hair oil,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every hair oil we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model posing with bottle of custom hair oil',
    },
  },
};
