import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

const myHairScalpType = {
  name: 'diag_scalp_type',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/scalp`,
  title: 'In between washes, where is your scalp on the oily/dry spectrum?',
  subtitle:
    'Just like your skin, your scalp is constantly changing, but think about how it typically feels.',
  tip: {
    title: 'Did you know…',
    content: `Healthy hair starts with a healthy scalp, so the state of your scalp can tell us a lot about the ingredients and products that will cleanse, condition, and treat your hair to its best expression. 
<br />
<br />
An oily scalp, for example, can be a sign of sebum overproduction triggered by stress or product buildup. A dry scalp, on the other hand, may indicate an imbalance caused by environmental stressors.
<br />
<br />
Prose products are designed to respect the health of your scalp microbiome — the unique “microbial fingerprint” that, when balanced, provides the optimal foundation for full, healthy hair.`,
  },
  options: [
    {
      value: 'oily',
      label: 'On the oily side',
    },
    {
      value: 'balanced',
      label: 'Balanced',
    },
    {
      value: 'dry',
      label: 'On the dry side',
    },
    {
      value: 'not_sure',
      label: 'Not sure / I don’t know',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_scalp_type,
  getAnswers: ({ selected }) => ({ diag_scalp_type: selected }),
};

export default myHairScalpType;
