import * as fragrances from 'constants/haircareFragrances';
import { MY_PREFERENCES } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

const myPreferencesFragrance = {
  name: 'fragrance',
  category: MY_PREFERENCES,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/haircare/${MY_PREFERENCES}/fragrance`,
  title: 'What haircare fragrance would you like?',
  subtitle:
    'Please note that some of your custom products may feature a unique scent, tailored to the formulation.',
  tip: {
    title: 'Need help deciding?',
    content: `We asked members of our team to describe our fragrances. Here’s what they had to say:`,
    richContent: {
      Component: consultationTipsComponents.SliderFragranceTestimonyCards,
      contentComesFirst: true,
      fragranceTestimonies: [
        {
          by: 'Kaela, Creative',
          fragranceName: fragrances.AURA,
          intensity: 60,
          notes: 'Wildflowers, Desert Breeze, Musk',
          testimony:
            'Laying on a warm sand dune covered in blooming wildflowers while the sunset paints the sky overhead.',
          title: 'Aura',
        },
        {
          by: 'Annie, CX',
          fragranceName: fragrances.SIGNATURE,
          intensity: 100,
          notes: 'Violet leaf, Peony, White Amber',
          testimony: 'Creating a bouquet with your favorite florals at a nursey or floral shop.',
          title: 'Signature',
        },
        {
          by: 'Katherine, CX',
          fragranceName: fragrances.PERLE,
          intensity: 15,
          notes: 'Musk, Gardenia, Sandalwood',
          testimony: 'Fresh lilies. but also smells very warm and cozy.',
          title: 'Perle',
        },
        {
          by: 'Leslie, CX',
          fragranceName: fragrances.PRELUDE,
          intensity: 40,
          notes: 'Rose, Geranium, Blue Iris',
          testimony: 'A freshly arranged bouquet with a hint of sweetness like a jam.',
          title: 'Prelude',
        },
        {
          by: 'Claire, Product Innovation',
          fragranceName: fragrances.RITUEL,
          intensity: 60,
          notes: 'Green Tea, Mandarin, Bergamot',
          testimony: 'Drinking a cup of green tea on a sunny spring morning.',
          title: 'Rituel',
        },
        {
          by: 'Emily, Marketing',
          fragranceName: fragrances.MELENI,
          intensity: 50,
          notes: 'Mango, Melon, Lush Greens',
          testimony:
            'Feasting on an abundance of ripe, juicy mangoes and melons while enveloped by the tropical rainforest.',
          title: 'Meleni',
        },
        {
          by: 'Leslie, CX',
          fragranceName: fragrances.OASIS,
          intensity: 40,
          notes: 'Jasmine, Amber, White Peach',
          testimony: 'A refreshing summer cocktail on the beach.',
          title: 'Oasis',
        },
        {
          by: 'Jay G, Operations',
          fragranceName: fragrances.ELIXIR,
          intensity: 70,
          notes: 'Freesia, Jasmine & Vanilla',
          testimony: 'Florida. Heavy notes of sunscreen.',
          title: 'Elixir',
        },
        {
          by: 'Anna, CX',
          fragranceName: fragrances.BOTANICA,
          intensity: 15,
          notes: 'Eucalyptus, Rosemary, Lavender',
          testimony: 'A warm spa towel',
          title: 'Botanica',
        },
        {
          by: 'Katherine, CX',
          fragranceName: fragrances.CORSICA,
          intensity: 50,
          notes: 'Anjou Pear, Peony, Cedar Wood',
          testimony: 'A light green ocean and super soft and fluffy sand.',
          title: 'Corsica',
        },
        {
          by: 'Aline, R&D',
          fragranceName: fragrances.ARCADIA,
          intensity: 60,
          notes: 'Grapefruit, Basil, Cedar',
          testimony: 'Sipping lemonade at an early-morning farmer’s market.',
          title: 'Arcadia',
        },
        {
          by: 'Brian, Marketing',
          fragranceName: fragrances.FETE,
          intensity: 85,
          notes: 'Apple, Spiced Pear, Cinnamon',
          testimony: 'Baking a galette with fresh, juicy fruits on a cosy winter day.',
          title: 'Fête',
        },
        {
          by: 'Sarah, Creative',
          fragranceName: fragrances.PARADISO,
          intensity: 60,
          notes: 'Coconut, Vanilla & Tonka Bean',
          testimony: 'The earthy-sweet haze of sun-drenched sand and freshly cracked coconut.',
          title: 'Paradiso',
        },
        {
          by: 'Anja, Innovation',
          fragranceName: fragrances.PALMA,
          intensity: 50,
          notes: 'Orange, Tea, Sandalwood',
          testimony:
            'Taking a moment to enjoy the quiet with a cup of tea after an invigorating yoga class.',
          title: 'Palma',
        },
        {
          by: 'Alize, Innovation',
          fragranceName: fragrances.GLACIA,
          intensity: 50,
          notes: 'Cypress, Fresh Citrus, Musk',
          testimony:
            'Snow-capped mountains set against an impossibly clear sky: airy and outdoorsy, with the perfect hint of citrus.',
          title: 'Glacia',
        },
      ],
    },
  },
  shouldBeIncluded: () => true,
  getInitialValue: answers =>
    answers.pref_fragrance_free ? fragrances.NONE : answers.pref_fragrance,
  getAnswers: ({ selected }) => ({
    pref_fragrance_free: selected === fragrances.NONE,
    pref_fragrance: selected !== fragrances.NONE ? selected.toUpperCase() : null,
  }),
  Component: consultationComponents.FragranceContainer,
};

export default myPreferencesFragrance;
