import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';

// like lodash getOr, but uses default value for any null result (not only undefined)
const getOrNil = (defaultValue, path) => object => {
  const ret = get(path)(object);
  return isNil(ret) ? defaultValue : ret;
};

export default getOrNil;
