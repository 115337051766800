import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const skinGoals = {
  CardProps: {
    twoColumns: true,
  },
  name: 'skinGoals',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/goals`,
  title: 'What are your current skin goals?',
  subtitle: (
    <>
      Select all that apply, and focus on what you <br /> care about most right now.
    </>
  ),
  tip: {
    title: 'why we ask + how to prioritize:',
    content:
      'Focus on what you’re looking for right now. This will help us understand what you want to get out of your routine, and how we can help you get closer to your skin goals over time.',
  },
  options: [
    {
      value: 'goal_graceful_aging',
      label: 'Graceful aging',
    },
    {
      value: 'goal_hydration',
      label: 'Extra hydration',
    },
    {
      value: 'goal_firmness',
      label: 'Firmer texture',
    },
    {
      value: 'goal_smoothness',
      label: 'Smoother surface',
    },
    {
      value: 'goal_wrinkles',
      label: 'Softened wrinkles',
    },
    {
      value: 'goal_redness',
      label: 'Reduced redness',
    },
    {
      value: 'goal_shine_skin',
      label: 'Reduced shine/oil',
    },
    {
      value: 'goal_dark_spots',
      label: 'Fewer dark spots',
    },
    {
      value: 'goal_blemish',
      label: 'Fewer blemishes ',
    },
    {
      value: 'goal_glow',
      label: 'Boosted glow',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.goal_skincare,
  getAnswers: ({ selected }) => ({
    goal_skincare: selected,
  }),
  multi: true,
};

export default skinGoals;
