import * as Sentry from '@sentry/nextjs';
import ky, { HTTPError as KyHTTPError } from 'ky';

import { getEnvOrThrow } from 'utils/getEnv';

import { getGuestTokens, getTokens } from './Auth';
import { tryRefresh } from './AuthService';
import HTTPError, { HTTPInterceptedError, type ServerErrorResponse } from './HTTPError';

export const isJsonResponse = (response: Response) => {
  // used to ensure that the response is JSON
  const contentType = response.headers.get('content-type');
  return contentType ? contentType.includes('application/json') : false;
};

/* INFO: good resource around handling fetch errors:
 * https://web.dev/articles/fetch-api-error-handling */

/**
 * Provides a way to triage errors thrown by authClient.
 * thanks to this, functions testing errors against a given code just works
 */
const handleAuthClientError = async (error: unknown) => {
  // handle server errors
  if (error instanceof KyHTTPError) {
    const { response, request, options } = error;
    const isJson = isJsonResponse(response);
    let body: ServerErrorResponse;

    if (isJson) {
      // the error is describe within the response body
      body = (await response.json()) as ServerErrorResponse;
    } else {
      // the response is probably html returned by the api.
      body = {
        code: 'not_a_json',
        detail: 'Expected a JSON response.',
        extra: {
          contentType: response.headers.get('content-type'),
        },
      };

      Sentry.configureScope((scope) => {
        scope.setExtra('api:error', {
          requestOptions: {
            verb: error.options.method,
            retryLimit: error.options.retry.limit,
          },
          url: response.url,
          status: response.status,
          statusText: response.statusText,
          body,
        });
      });
    }
    return new HTTPInterceptedError({ response, request, options }, body);
  }

  // At this point, by elimination it's either a network error or a fetch fn error
  Sentry.configureScope((scope) => {
    if (error instanceof Error) {
      scope.setExtra('api:fetchError', error.message);
    }
  });

  throw new HTTPError(
    {
      code: 'failed_to_fetch',
      detail: 'Failed to fetch',
    },
    { cause: error },
  );
};

export const authClient = ky.extend({
  prefixUrl: getEnvOrThrow('REACT_APP_ROOT_URL'),
  credentials: 'include',
  retry: {
    limit: 3,
  },
  timeout: 60000,
  // The following line overrides the default fetch method in ky in order to set the referrerPolicy to unsafe-url
  // Other strategies that didn't work for an unknown reason:
  // - Pass referrerPolicy in the default options of ky.extend
  // - Create a new request object while setting the referrerPolicy in the hooks
  fetch: (request, init) => fetch(request, { ...init, referrerPolicy: 'unsafe-url' }),
  hooks: {
    beforeRequest: [
      (request) => {
        if (!('Authorization' in request.headers)) {
          const tokens = getTokens();
          const guestTokens = getGuestTokens();
          if (tokens?.accessToken || guestTokens?.accessToken) {
            request.headers.set(
              'Authorization',
              `Bearer ${tokens?.accessToken ?? guestTokens?.accessToken}`,
            );
          } else {
            request.headers.delete('Authorization');
          }
        }
      },
      async (request) => {
        const { tokens, refreshed } = await tryRefresh();

        if (refreshed) {
          request.headers.set('Authorization', `Bearer ${tokens.accessToken}`);
        }
      },
    ],
    beforeRetry: [
      async ({ request }) => {
        const { tokens, refreshed } = await tryRefresh();

        if (refreshed) {
          request.headers.set('Authorization', `Bearer ${tokens.accessToken}`);
        }
      },
    ],
    beforeError: [handleAuthClientError],
  },
});
