import chooseImage from 'assets/images/gifting/prose-assortment-of-haircare-and-skincare-products-close-up.jpg';
import customizeImage from 'assets/images/gifting/prose-model-with-curly-hair.jpg';
import squiggle from 'assets/images/squiggle.svg';

import allureLogo from '../images/logo_allure.png';
import nytLogo from '../images/logo_nyt.png';
import popSugarLogo from '../images/logo_popsugar.png';

export const giftContent = () => ({
  hero: {
    title: 'The gift that is literally made for them',
    content: <>Take gifting to the next level with custom, made-to-order haircare + skincare.</>,
  },
  philosophy: {
    title: 'The best gifts are personal',
    content: (
      <>For the gift that has their name on it — give custom haircare + skincare routines.</>
    ),
    cta: 'Get started',
  },
  howItWork: {
    title: 'How it works',
    imageMobile: chooseImage,
    images: [
      {
        image: chooseImage,
        alt: 'Prose assortment of custom haircare and skincare products',
        width: '45%',
      },
      { image: { src: squiggle }, alt: '', width: 100, style: { margin: '0px -10px', zIndex: 1 } },
      { image: customizeImage, alt: 'Prose model with curly hair', width: '45%' },
    ],
    imageMobileAlt: 'Assorted prose products on a table',
    content: [
      {
        title: '1. You choose a gift card amount',
        content:
          'Select from the values below, and we’ll send your giftee a special link that will add their gift card to their Prose account.',
      },
      {
        title: '2. They customize their order',
        content:
          'After completing their consultation, your giftee can use their credits to purchase Prose custom haircare, skincare, and accessories.',
      },
    ],
  },
  form: {
    // when we add or remove an occasion, we should make sure that we are updating the oms part in utils/gifts.js
    occasion: [
      {
        value: 'holidays',
        label: 'Holidays',
        message: 'Wishing you happy holidays. And happy hair days too!',
      },
      {
        value: 'birthday',
        label: 'Birthday',
        message:
          'Happy birthday! Wishing you all the best and only happy hair days in the year ahead.',
      },
      {
        value: 'just_because',
        label: 'Just Because',
        message:
          'You’re one-of-a-kind and you deserve to use hair care that’s been made just for you. I hope you enjoy your very own Prose.',
      },
      {
        value: 'graduation',
        label: 'Graduation',
        message:
          "You're one-of-a-kind and you deserve to use hair care that's been made just for you. Congrats on your graduation!",
      },
    ],
  },
  press: [
    {
      logo: allureLogo,
      logoAlt: 'Allure',
      width: '61px',
      review: '“I tried a customized hair-care regimen and was blown away by the results.”',
    },
    {
      logo: nytLogo,
      logoAlt: 'The New York Times',
      width: '178px',
      review:
        '“Prose is one of the biggest and most successful entrants in … customizable hair care.”',
    },
    {
      logo: popSugarLogo,
      logoAlt: 'POPSUGAR',
      width: '161px',
      review: (
        <>
          “Basically a salon
          <br /> blowout in a <br /> bottle.”
        </>
      ),
    },
  ],
  questions: [
    {
      question: 'I want to give the gift of Prose, what are my options?',
      answer: (
        <>
          We offer digital gift cards in the amount of $50, $75, $100 and $150 (USD). You can enter
          a personalized message and we’ll email your gift to the recipient at 10 AM EST on the date
          of your choosing. If you’ve selected the day of, your digital gift will be emailed
          immediately upon purchase.
          <br />
          <br />
          Prose gift cards can only be redeemed in the country they were purchased in.
        </>
      ),
    },
    {
      question: 'Do digital gift cards expire?',
      answer: 'They don’t! Your digital gift can be redeemed at any time.',
    },
    {
      question: 'I need to make a change to my digital gift card, what can I do?',
      answer: (
        <>
          To edit or cancel a digital gift that hasn’t been emailed yet, please reach out to us{' '}
          <a href="https://prose.com/faq/contact-us" target="_blank">
            here
          </a>
          . We can’t guarantee any changes, but we’ll do our best!
        </>
      ),
    },
    {
      question: 'What products can I purchase with my digital gift card?',
      answer:
        'You can purchase any Prose product with your digital gift card. Your digital gift card cannot be used to purchase additional gift cards.',
    },
    {
      question: 'What happens to leftover credit on my digital gift card?',
      answer: 'Any leftover credit will apply toward future purchases.',
    },
    {
      question: 'Can I start a subscription using my digital gift card?',
      answer:
        "Yes! You'll have the option to buy your products once, or you can choose to subscribe and save 15% on your order—this means your digital gift card will go even further.",
    },
    {
      question:
        "What happens if I'm not totally satisfied with products purchased using my digital gift card?",
      answer: (
        <>
          Any product purchased with a digital gift card is backed by The Prose Promise™ — Love
          your first order (or it’s on us) within 30 days of the delivery date. You can receive
          adjusted formulas free of charge, or you can return your order for credit to your Prose
          account—both processes are free, and the digital gift purchaser won’t be notified. Just
          contact our team{' '}
          <a href="https://prose.com/faq/contact-us" target="_blank">
            here
          </a>
          , and we’ll provide detailed instructions.
        </>
      ),
    },
  ],
  giftCardSet: {
    title: 'Choose your gift amount',
    subtitle:
      'Prose gift cards can be used to purchase custom haircare + skincare, or accessories like hair towels + candles.',
  },
  noRedeemableGift: 'Sorry, no redeemable gift is associated to this account',
});
