import { MY_TREATMENTS } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { TREATMENTS } from '../constants/progressCategories';

import treatmentsDesktop from 'assets/images/consultation/transitionScreens/prose-lab-overhead-desktop.jpg';
import treatmentsMobile from 'assets/images/consultation/transitionScreens/prose-lab-overhead-mobile.jpg';
import treatmentsTablet from 'assets/images/consultation/transitionScreens/prose-lab-overhead-tablet.jpg';

const consultationBackgroundImages = {
  // All hair texture to see the same treatments background
  treatments: {
    desktop: treatmentsDesktop,
    tablet: treatmentsTablet,
    mobile: treatmentsMobile,
  },
};
const myTreatmentsTransitionScreen = {
  name: 'intro-treatments',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/introduction`,
  title: 'Section 2',
  subtitle: 'How you style',
  shouldBeIncluded: () => true,
  Component: consultationComponents.Transition,
  skipSave: true,
  body: 'Hair that’s been dyed or treated needs special attention, and we’ll make sure you get it.',
  image: () => consultationBackgroundImages.treatments,
};

export default myTreatmentsTransitionScreen;
