import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsOtherTreatments = {
  name: 'other-treatments',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/other-treatments`,
  multi: true,
  title: 'Has your hair undergone any textural treatments?',
  subtitle: 'Select all that apply, even if you’re growing one out.',
  tip: {
    title: 'Not sure what you got?',
    content: `See if you remember this happening.

&nbsp;

A **relaxer** comes in the form of a cream that’s applied to dry hair, rinsed, then washed out with a pH-balancing shampoo.

&nbsp;

A **perm** is set with rollers that come big for loose waves or small for tight curls, activated by heat.

&nbsp;

**Keratin** or Brazilian keratin works by combing a straightening formula through wet hair, then sealing it in with a blow-dryer and flat-iron. It gives you smoother, straighter hair that lasts 3-5 months.`,
  },
  options: [
    { value: 'relaxer', label: 'Relaxer', description: 'Curly hair became straight(er)' },
    { value: 'perm', label: 'Perm or Waving', description: 'Straight hair became curly' },
    {
      value: 'keratin',
      label: 'Keratin',
      description: 'Hair was smoothed & straightened for 3-5 months',
    },
    { value: 'none', label: 'None of the above', unique: true },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_other_treatments,
  getAnswers: ({ selected }) => ({
    diag_other_treatments: selected,
  }),
};

export default myTreatmentsOtherTreatments;
