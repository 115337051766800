import { countriesCode } from './countries';

const currencies = {
  USD: 'USD',
  CAD: 'CAD',
} as const;

export type Currency = (typeof currencies)[keyof typeof currencies];

export const countriesCodeCurrency = {
  [countriesCode.US]: currencies.USD,
  [countriesCode.CA]: currencies.CAD,
} as const;

export default currencies;
