import {
  DEPRECATED_CURLY,
  FRIZZY,
  ONE,
  THREE_A,
  THREE_B,
  THREE_C,
  TWO_A,
  TWO_B,
  TWO_C,
  VERY_CURLY,
  ZIGZAG,
} from 'constants/hairTextures';

export const getTypeforQuestion = hairTextureName => {
  switch (hairTextureName) {
    case ONE:
      return 1;
    case TWO_A:
    case TWO_B:
    case TWO_C:
      return 2;
    case THREE_A:
    case THREE_B:
    case THREE_C:
      return 3;
    case DEPRECATED_CURLY:
    case VERY_CURLY:
    case FRIZZY:
    case ZIGZAG:
      return 4;
    default:
      return null;
  }
};

// RELAXER OR TYPE 1
export const relaxerOrOneOptions = [
  {
    value: 'crafted_curls',
    label: 'Crafted Curls',
    description: 'Curls created with a styling tool like a curling iron or rollers',
  },
  {
    value: 'pulled_back_styles',
    label: 'Pulled-back styles',
    description: 'Buns, braids, ponytails, updos',
  },
  {
    value: 'straightened_styles',
    label: 'Straightened styles',
    description: 'Usually created with a straightening iron 1',
  },
  {
    value: 'beachy_waves',
    label: 'Beachy or tousled waves',
    description: 'For added volume and texture ',
  },
  {
    value: 'lifted_roots',
    label: 'Lifted roots',
    description: 'Teased or raised for added volume',
  },
  {
    value: 'locs',
    label: 'Locs or Dreadlocks',
    description: 'Ropes or strands of entangled, braided, twisted, or palm-rolled hair',
  },
  {
    value: 'finger_combed',
    label: 'Finger-combed',
    description: 'For added shape like swoops or spikes',
  },
  { value: 'extensions', label: 'Extensions', description: 'Natural or synthetic extensions' },
  {
    value: 'wrapped_styles',
    label: 'Wrapped styles',
    description: 'Including head wraps, turbans, scarves',
  },
  { value: 'wigs', label: 'Wigs', description: 'Natural or synthetic wigs' },
  { value: 'none', label: 'I don’t wear any of these styles', unique: true },
];

// TYPE 2
export const typeTwoOptions = [
  {
    value: 'natural_waves',
    label: 'Natural Waves',
    description: 'Beachy or defined natural waves and loose curls',
  },
  {
    value: 'crafted_curls',
    label: 'Crafted Curls',
    description: 'Curls created with a styling tool like a curling iron or rollers',
  },
  {
    value: 'pulled_back_styles',
    label: 'Pulled-back styles',
    description: 'Buns, braids, ponytails, updos',
  },
  {
    value: 'lifted_roots',
    label: 'Lifted roots',
    description: 'Teased or raised for added volume',
  },
  {
    value: 'locs',
    label: 'Locs or Dreadlocks',
    description: 'Ropes or strands of entangled, braided, twisted, or palm-rolled hair',
  },
  {
    value: 'finger_combed',
    label: 'Finger-combed',
    description: 'For added shape like swoops or spikes',
  },
  {
    value: 'stretched_out_straightened',
    label: 'Stretched out or straightened',
    description: 'Usually created with blowouts or a straightening iron',
  },

  { value: 'extensions', label: 'Extensions', description: 'Natural or synthetic extensions' },

  {
    value: 'wrapped_styles',
    label: 'Wrapped styles',
    description: 'Including head wraps, turbans, scarves',
  },
  { value: 'wigs', label: 'Wigs', description: 'Natural or synthetic wigs' },
  { value: 'none', label: 'I don’t wear any of these styles', unique: true },
];

// TYPE 3 OR PERMS
export const permsOrThreeOptions = [
  {
    value: 'wash_n_go',
    label: 'Wash-n-go',
    description: 'Natural curls, defined and set with products',
  },
  {
    value: 'pulled_back_styles',
    label: 'Pulled-back styles',
    description: 'Buns, braids, ponytails, updos',
  },
  {
    value: 'protective_styles',
    label: 'Protective styles',
    description: 'Braids, twists, crochet styles',
  },
  {
    value: 'crafted_patterns',
    label: 'Crafted patterns',
    description: 'Roller and rod sets or other tools to create defined shapes',
  },
  {
    value: 'afro_combed_curls',
    label: 'Afro or combed out curls',
    description: 'Hair combed up and out with no visible curl definition',
  },
  {
    value: 'locs',
    label: 'Locs or Dreadlocks',
    description: 'Ropes or strands of entangled, braided, twisted, or palm-rolled hair',
  },
  {
    value: 'stretched_out_straightened',
    label: 'Stretched out or straightened',
    description: 'Created with or without heat stylers',
  },
  { value: 'extensions', label: 'Extensions', description: 'Natural or synthetic extensions' },
  {
    value: 'wrapped_styles',
    label: 'Wrapped styles',
    description: 'Including head wraps, turbans, scarves',
  },
  { value: 'wigs', label: 'Wigs', description: 'Natural or synthetic wigs' },
  { value: 'none', label: 'I don’t wear any of these styles', unique: true },
];

// TYPE 4 OR PERMS
export const typeFourOptions = [
  {
    value: 'wash_n_go',
    label: 'Wash-n-go',
    description: 'Natural coils, defined and set with products',
  },
  {
    value: 'pulled_back_styles',
    label: 'Pulled-back styles',
    description: 'Buns, braids, ponytails, updos',
  },
  {
    value: 'protective_styles',
    label: 'Protective styles',
    description: 'Braids, twists, crochet styles',
  },
  {
    value: 'crafted_patterns',
    label: 'Crafted patterns',
    description: 'Roller and rod sets or other tools to create defined shapes',
  },
  {
    value: 'afro',
    label: 'Afro',
    description: 'Hair combed or fluffed up and out without specific coil definition ',
  },
  {
    value: 'locs',
    label: 'Locs or Dreadlocks',
    description: 'Ropes or strands of entangled, braided, twisted, or palm-rolled hair',
  },
  {
    value: 'stretched_out_straightened',
    label: 'Stretched out or straightened',
    description: 'Created with or without heat stylers',
  },
  { value: 'extensions', label: 'Extensions', description: 'Natural or synthetic extensions' },
  { value: 'wigs', label: 'Wigs', description: 'Natural or synthetic wigs' },
  {
    value: 'wrapped_styles',
    label: 'Wrapped styles',
    description: 'Including head wraps, turbans, scarves',
  },
  { value: 'none', label: 'I don’t wear any of these styles', unique: true },
];

const stylingGelValuesExtensionsConcat = typeFourOptions
  .concat(permsOrThreeOptions)
  .concat(typeTwoOptions)
  .concat(relaxerOrOneOptions);

export const stylingGelValuesExtensions = stylingGelValuesExtensionsConcat.map(
  ({ value }) => value
);

export const tips = {
  relaxerOrOneOptions:
    'Knowing what style is in your rotation helps us assess which product and styling routine best fit your needs and expectations.',
  typeTwoOptions:
    'Knowing what style is in your rotation helps us assess which product and styling routine best fit your needs and expectations.',
  permsOrThreeOptions:
    'Knowing what style is in your rotation helps us assess which product and styling routine best fit your needs',
  typeFourOptions:
    'Knowing what style is in your rotation helps us assess which product and styling routine will best fit your needs and goals.',
};

export const getOptionsfromTextureAndTreatment = answers => {
  const type = getTypeforQuestion(answers?.diag_hair_texture);
  const isRelaxer =
    answers?.diag_other_treatments.includes('relaxer') &&
    !answers?.diag_other_treatments.includes('perm');
  const isPerm = answers?.diag_other_treatments.some(answer => answer === 'perm');
  if (isPerm) {
    return { options: permsOrThreeOptions, tip: tips.permsOrThreeOptions };
  }
  if (isRelaxer) {
    return { options: relaxerOrOneOptions, tip: tips.relaxerOrOneOptions };
  }

  switch (type) {
    case 1:
      return { options: relaxerOrOneOptions, tip: tips.typeTwoOptions };
    case 2:
      return { options: typeTwoOptions, tip: tips.typeTwoOptions };
    case 3:
      return { options: permsOrThreeOptions, tip: tips.permsOrThreeOptions };
    case 4:
      return { options: typeFourOptions, tip: tips.typeFourOptions };
    default:
      return null;
  }
};
