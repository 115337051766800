import * as consultationComponents from '../constants/consultationComponents';

const stylingGelWelcome = {
  name: 'stylingGelWelcome',
  route: `/consultation/haircare`,
  shouldBeIncluded: () => true,
  public: true,
  Component: consultationComponents.StartStylingGelInterstitial,
  skipSave: true,
};

export default stylingGelWelcome;
