import * as hairTextures from 'constants/hairTextures';
import { HAIRCARE_QUESTION_TEXTURE, MY_HAIR } from '../constants';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { HAIR_SCALP } from '../constants/progressCategories';

import tipTexture1 from 'assets/images/consultation/textures/tips/1.jpg';
import tipTexture2 from 'assets/images/consultation/textures/tips/2.jpg';
import tipTexture3a from 'assets/images/consultation/textures/tips/3a.jpg';
import tipTexture3b from 'assets/images/consultation/textures/tips/3b.jpg';
import tipTexture3c from 'assets/images/consultation/textures/tips/3c.jpg';
import tipTexture4a from 'assets/images/consultation/textures/tips/4a.jpg';
import tipTexture4b from 'assets/images/consultation/textures/tips/4b.jpg';
import tipTexture4c from 'assets/images/consultation/textures/tips/4c.jpg';
import textureSmoothImageUrl from 'assets/images/consultation/textures/type1.png';
import texture2AImageUrl from 'assets/images/consultation/textures/type2a.png';
import texture2BImageUrl from 'assets/images/consultation/textures/type2b.png';
import texture2CImageUrl from 'assets/images/consultation/textures/type2c.png';
import texture3AImageUrl from 'assets/images/consultation/textures/type3a.png';
import texture3BImageUrl from 'assets/images/consultation/textures/type3b.png';
import texture3CImageUrl from 'assets/images/consultation/textures/type3c.png';
import textureVeryCurlyImageUrl from 'assets/images/consultation/textures/type4a.png';
import textureFrizzyImageUrl from 'assets/images/consultation/textures/type4b.png';
import textureZigzagImageUrl from 'assets/images/consultation/textures/type4c.png';

const myHairTexture = {
  name: HAIRCARE_QUESTION_TEXTURE,
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/texture`,
  title: 'What’s your natural, untreated, air-dried hair texture?',
  subtitle: 'If your hair is texturally treated, think back to when it wasn’t.',
  tip: {
    title: 'More of a visual learner? Tap for pics',
    content: `Sometimes it’s tough to decide, and many people have more than 1 texture on their heads. When in doubt, pick the texture that looks like the majority of your hair, the majority of the time.`,
    couldBounce: true,
    richContent: {
      Component: consultationTipsComponents.SliderOfSlider,
      contentComesFirst: true,
      // to remove when clean up styling-gel FF = first launch without the 2 textures in tip slider
      slidersForStylingGel: [
        {
          sliderId: 'texture1',
          sliderNameTitle: 'Type 1',
          sliderName: 'Straight',
          slidesContent: [
            {
              id: 'texture1',
              heapTracker: 'Texture 1',
              image: {
                src: tipTexture1,
                alt: 'Illustration of Texture 1',
                legend: 'Type 1',
              },
            },
          ],
        },
        {
          sliderId: 'texture2',
          sliderNameTitle: 'Type 2',
          sliderName: 'Wavy',
          slidesContent: [
            {
              id: 'texture2a',
              heapTracker: 'Texture 2a',
              image: {
                src: 'https://via.placeholder.com/1024',
                alt: 'Illustration of Texture 2a',
                legend: 'Type 2a',
              },
            },
            {
              id: 'texture2b',
              heapTracker: 'Texture 2b',
              image: {
                src: 'https://via.placeholder.com/1024',
                alt: 'Illustration of Texture 2b',
                legend: 'Type 2b',
              },
            },
            {
              id: 'texture2c',
              heapTracker: 'Texture 2c',
              image: {
                src: 'https://via.placeholder.com/1024',
                alt: 'Illustration of Texture 2c',
                legend: 'Type 2c',
              },
            },
          ],
        },
        {
          sliderId: 'texture3',
          sliderNameTitle: 'Type 3',
          sliderName: 'Curly',
          slidesContent: [
            {
              id: 'texture3a',
              heapTracker: 'Texture 3A',
              image: {
                src: tipTexture3a,
                alt: 'Illustration of Texture 3 A',
                legend: 'Type 3a',
              },
            },
            {
              id: 'texture3b',
              heapTracker: 'Texture 3B',
              image: {
                src: tipTexture3b,
                alt: 'Illustration of Texture 3 B',
                legend: 'Type 3b',
              },
            },
            {
              id: 'texture3c',
              heapTracker: 'Texture 3C',
              image: {
                src: tipTexture3c,
                alt: 'Illustration of Texture 3 C',
                legend: 'Type 3c',
              },
            },
          ],
        },
        {
          sliderId: 'texture4',
          sliderNameTitle: 'Type 4',
          sliderName: 'Coily',
          slidesContent: [
            {
              id: 'texture4a',
              heapTracker: 'Texture 4A',
              image: {
                src: tipTexture4a,
                alt: 'Illustration of Texture 4 A',
                legend: 'Type 4a',
              },
            },
            {
              id: 'texture4b',
              heapTracker: 'Texture 4B',
              image: {
                src: tipTexture4b,
                alt: 'Illustration of Texture 4 B',
                legend: 'Type 4b',
              },
            },
            {
              id: 'texture4c',
              heapTracker: 'Texture 4C',
              image: {
                src: tipTexture4c,
                alt: 'Illustration of Texture 4 C',
                legend: 'Type 4c',
              },
            },
          ],
        },
      ],
      sliders: [
        {
          sliderId: 'texture1',
          sliderNameTitle: 'Type 1',
          sliderName: 'Straight',
          slidesContent: [
            {
              id: 'texture1',
              heapTracker: 'Texture 1',
              image: {
                src: tipTexture1,
                alt: 'Illustration of Texture 1',
                legend: 'Type 1',
              },
            },
          ],
        },
        {
          sliderId: 'texture2',
          sliderNameTitle: 'Type 2',
          sliderName: 'Wavy',
          slidesContent: [
            {
              id: 'texture2',
              heapTracker: 'Texture 2',
              image: {
                src: tipTexture2,
                alt: 'Illustration of Texture 2',
                legend: 'Type 2',
              },
            },
          ],
        },
        {
          sliderId: 'texture3',
          sliderNameTitle: 'Type 3',
          sliderName: 'Curly',
          slidesContent: [
            {
              id: 'texture3a',
              heapTracker: 'Texture 3A',
              image: {
                src: tipTexture3a,
                alt: 'Illustration of Texture 3 A',
                legend: 'Type 3a',
              },
            },
            {
              id: 'texture3b',
              heapTracker: 'Texture 3B',
              image: {
                src: tipTexture3b,
                alt: 'Illustration of Texture 3 B',
                legend: 'Type 3b',
              },
            },
            {
              id: 'texture3c',
              heapTracker: 'Texture 3C',
              image: {
                src: tipTexture3c,
                alt: 'Illustration of Texture 3 C',
                legend: 'Type 3c',
              },
            },
          ],
        },
        {
          sliderId: 'texture4',
          sliderNameTitle: 'Type 4',
          sliderName: 'Coily',
          slidesContent: [
            {
              id: 'texture4a',
              heapTracker: 'Texture 4A',
              image: {
                src: tipTexture4a,
                alt: 'Illustration of Texture 4 A',
                legend: 'Type 4a',
              },
            },
            {
              id: 'texture4b',
              heapTracker: 'Texture 4B',
              image: {
                src: tipTexture4b,
                alt: 'Illustration of Texture 4 B',
                legend: 'Type 4b',
              },
            },
            {
              id: 'texture4c',
              heapTracker: 'Texture 4C',
              image: {
                src: tipTexture4c,
                alt: 'Illustration of Texture 4 C',
                legend: 'Type 4c',
              },
            },
          ],
        },
      ],
    },
  },
  options: [
    {
      value: hairTextures.ONE,
      label: 'Type 1 - Straight',
      imageUrl: textureSmoothImageUrl,
    },
    {
      value: hairTextures.TWO_A,
      label: 'Type 2A - Soft Waves',
      description: 'Almost straight with a slight bend',
      imageUrl: texture2AImageUrl,
    },
    {
      value: hairTextures.TWO_B,
      label: 'Type 2B - Wavy',
      description: 'Like a loose "S"',
      imageUrl: texture2BImageUrl,
    },
    {
      value: hairTextures.TWO_C,
      label: 'Type 2C - Deep Waves or Loose Curls',
      description: 'Defined, S-shaped pattern',
      imageUrl: texture2CImageUrl,
    },
    {
      value: hairTextures.THREE_A,
      label: 'Type 3A - Curly',
      description: 'Like a loose "C"',
      imageUrl: texture3AImageUrl,
    },
    {
      value: hairTextures.THREE_B,
      label: 'Type 3B - Very Curly',
      description: 'Defined & springy C’s',
      imageUrl: texture3BImageUrl,
    },
    {
      value: hairTextures.THREE_C,
      label: 'Type 3C - Tight Curls',
      description: 'Like a corkscrew',
      imageUrl: texture3CImageUrl,
    },
    {
      value: hairTextures.VERY_CURLY,
      label: 'Type 4A - Coily',
      description: 'Tight & springy, like a slinky',
      imageUrl: textureVeryCurlyImageUrl,
    },
    {
      value: hairTextures.FRIZZY,
      label: 'Type 4B - Zig-Zag Coils',
      description: 'A less-defined "Z"',
      imageUrl: textureFrizzyImageUrl,
    },
    {
      value: hairTextures.ZIGZAG,
      label: 'Type 4C - Tight Zig-Zag',
      description: 'Like a sharply defined "Z"',
      imageUrl: textureZigzagImageUrl,
    },
  ],

  optionsFilter: () => {
    const wavyOptions = {
      [hairTextures.TWO_A]: true,
      [hairTextures.TWO_B]: true,
      [hairTextures.TWO_C]: true,
    };
    const curlyOptions = {
      [hairTextures.THREE_A]: true,
      [hairTextures.THREE_B]: true,
      [hairTextures.THREE_C]: true,
    };

    return {
      [hairTextures.ONE]: true,
      ...wavyOptions,
      ...curlyOptions,
      [hairTextures.VERY_CURLY]: true,
      [hairTextures.FRIZZY]: true,
      [hairTextures.ZIGZAG]: true,
    };
  },

  shouldBeIncluded: () => true,
  getInitialValue: (answers, isStylingGelActive) => {
    return isStylingGelActive && answers.diag_hair_texture === hairTextures.WAVY
      ? null
      : answers.diag_hair_texture;
  },
  getAnswers: ({ selected }) => ({ diag_hair_texture: selected }),
};

export default myHairTexture;
