import { styled } from '@prose-ui/legacy';

// From: https://www.joshwcomeau.com/react/modern-spacer-gif/#the-code

type SizeProps = {
  axis?: 'horizontal' | 'vertical';
  size: number;
};

const getHeight = ({ axis, size }: SizeProps) => (axis === 'horizontal' ? 1 : size);
const getWidth = ({ axis, size }: SizeProps) => (axis === 'vertical' ? 1 : size);

const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;

  background-color: var(--color-background, transparent);
`;

export default Spacer;
