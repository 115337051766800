import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsOtherTreatmentsCadence = {
  name: 'other-treatments-cadence',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/other-treatments-cadence`,
  title: 'How often do you receive textural treatments?',
  subtitle: 'If you’ve stopped getting regular treatments, answer with your former frequency.',
  tip: {
    title: 'Why we ask:',
    content:
      'Since these treatments are more prone to causing damage, we need to know how often you’re exposed to them so we can give you the right level or reparative support.',
  },
  options: [
    { value: 'every_month', label: 'Every month' },
    { value: 'two_three_months', label: 'Every 2 or 3 months' },
    { value: 'few_a_year', label: 'A couple times a year' },
    { value: 'just_started', label: 'I just started' },
  ],
  // Dynamic logic
  shouldBeIncluded: ({ answers }) =>
    ['relaxer', 'perm'].some(item => answers.diag_other_treatments?.includes(item)),
  getInitialValue: answers => answers.diag_other_treatments_frequency,
  getAnswers: ({ selected }) => ({
    diag_other_treatments_frequency: selected,
  }),
};

export default myTreatmentsOtherTreatmentsCadence;
