import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

import { withSebumLevel } from '../utils';

const myHairOil = {
  name: 'oil',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/oil`,
  title: 'After a wash, how long until hair gets oily again?',
  subtitle: 'If you’re stuck between answers, choose the greater number of days.',
  tip: {
    title: 'Why we ask:',
    content:
      'Your sebum production can vary within the year, depending on elements like temperature, humidity, styling routine, diet, stress, etc. Just give the answer that’s true for you right now, and we’ll be able to adjust your formulas throughout the year according to these expected changes. The goal is less washing & more great hair days.',
  },
  options: [
    { value: 'within_one_day', label: 'The same day' },
    { value: 'second_day', label: 'The second day' },
    { value: 'three_more_days', label: '3+ days' },
    { value: 'never', label: 'It never gets oily' },
    { value: 'dont_know', label: "I don't know" },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_regrease_delay,
  getAnswers: ({ selected, answers }) => withSebumLevel({ diag_regrease_delay: selected }, answers),
};

export default myHairOil;
