import { MY_LIFESTYLE } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { LIFESTYLE } from '../constants/progressCategories';

import lifestyleDesktop from 'assets/images/consultation/transitionScreens/prose-models-with-different-hair-types-desktop.jpg';
import lifestyleMobile from 'assets/images/consultation/transitionScreens/prose-models-with-different-hair-types-mobile.jpg';
import lifestyleTablet from 'assets/images/consultation/transitionScreens/prose-models-with-different-hair-types-tablet.jpg';

const consultationBackgroundImages = {
  desktop: lifestyleDesktop,
  tablet: lifestyleTablet,
  mobile: lifestyleMobile,
};

const myLifestyleTransitionScreen = {
  name: 'intro-lifestyle',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/haircare/${MY_LIFESTYLE}/introduction`,
  title: 'Section 3',
  subtitle: 'How you live',
  shouldBeIncluded: () => true,
  Component: consultationComponents.Transition,
  skipSave: true,
  body: 'Your hair lives with you day-in and day-out, and is affected by your actual environment and routine. Be honest here, because your hair never lies.',
  image: () => consultationBackgroundImages,
};

export default myLifestyleTransitionScreen;
