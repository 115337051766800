import * as hairTextures from 'constants/hairTextures';
import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

const myHairPorosity = {
  name: 'porosity',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/porosity`,
  title: 'How porous is your hair?',
  subtitle:
    'High-porosity hair dries quickly and is frizz-prone; products are absorbed quickly. Low porosity hair dries slowly and products stick around.',
  tip: {
    title: 'At home? Tap for an experiment',
    content: `Damage from the environment or from treatments like bleach or straightening increases porosity, so even though your hair is thirsty, it has a hard time retaining moisturizing products. We’ll fine-tune your formulas to give your hair nutrients it can actually absorb.

&nbsp;

To test your porosity in a weird but effective way, pull out a hair and put it in a glass of water.

&nbsp;

If it…

•stays on the surface = low porosity

•floats in the middle = medium porosity

•sinks at the bottom = high porosity`,
  },
  options: [
    { value: 'high', label: 'High porosity - dries quickly' },
    { value: 'medium', label: 'Medium or not sure' },
    { value: 'low', label: 'Low porosity - dries slowly' },
  ],
  // Dynamic logic
  shouldBeIncluded: ({ answers }) =>
    [
      hairTextures.THREE_A,
      hairTextures.THREE_B,
      hairTextures.THREE_C,
      hairTextures.VERY_CURLY,
      hairTextures.FRIZZY,
      hairTextures.ZIGZAG,
    ].includes(answers.diag_hair_texture),
  getInitialValue: answers => answers.diag_hair_porosity,
  getAnswers: ({ selected }) => ({ diag_hair_porosity: selected }),
};

export default myHairPorosity;
