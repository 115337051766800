import { createSelector } from '@reduxjs/toolkit';

import { shouldShowSkincareMinis, shouldShowSkincareMinisPhase2 } from 'dux/featureFlags/selectors';
import { getHasActiveHaircareSubscription, getHasOrdersByCategory } from 'dux/user/selectors';

export const getShowSkincareStarterSetPromo = createSelector(
  shouldShowSkincareMinis,
  shouldShowSkincareMinisPhase2,
  getHasActiveHaircareSubscription,
  getHasOrdersByCategory,
  (
    showSkincareMinisToHCSubs,
    showSkincareMinisToAllProspects,
    hasActiveHaircareSubscription,
    { skincare: hasSkincareOrders },
  ) => {
    if (hasSkincareOrders) {
      return false;
    }

    if (showSkincareMinisToAllProspects && showSkincareMinisToHCSubs) {
      return true;
    }

    return showSkincareMinisToHCSubs && hasActiveHaircareSubscription;
  },
);
