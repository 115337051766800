import * as fragrances from 'constants/skincareFragrances';
import * as consultationComponents from '../constants/consultationComponents';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

const skincareFragrance = {
  Component: consultationComponents.SkincareFragrance,
  name: 'skincareFragrance',
  category: PREFERENCES_GOALS,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/skincare/${PREFERENCES_GOALS}/fragrances`,
  title: 'Select your fragrance preference',
  tip: {
    title: 'Need help deciding?',
    content: `We asked members of our team to describe our fragrance. Here’s what they had to say:`,
    richContent: {
      Component: consultationTipsComponents.SliderFragranceTestimonyCards,
      contentComesFirst: true,
      fragranceTestimonies: [
        {
          by: ' Sam, Marketing',
          fragranceName: fragrances.BLANCA,
          intensity: 75,
          notes: 'Crisp Linen, Ambrette & rose',
          testimony:
            'The cozy comfort of waking up in freshly washed cotton sheets on a sunny Saturday morning.',
          title: 'Blanca',
        },
        {
          by: ' Rachel, Retention',
          fragranceName: fragrances.PACIFICA,
          intensity: 100,
          notes: 'Lavender, Lemongrass & Cypress',
          testimony: 'Fresh, aromatic lavender mixed with the energizing kick of sparkling citrus.',
          title: 'Pacifica',
        },
      ],
    },
  },
  shouldBeIncluded: () => true,
  getInitialValue: answers => ({
    pref_fragrance_skin: answers?.pref_fragrance_skin,
    pref_fragrance_free_skin: answers?.pref_fragrance_free_skin,
  }),
  getAnswers: ({ selected }) => ({
    pref_fragrance_skin: selected?.pref_fragrance_skin,
    pref_fragrance_free_skin: selected?.pref_fragrance_free_skin,
  }),
};

export default skincareFragrance;
