import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import image from 'assets/images/promotionalModals/trial-offer-pouches-gwp-promotion-modal.jpg';

const content = {
  contentDefault: {
    banner: 'Try Prose with 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'The Jetset Sale',
      title: 'Get 50% off + 2 free Travel Pouches',
      description:
        'Subscribe to any 3+ hair or skin products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions: (
        <>
          *Get 2 free Travel Pouches when you subscribe to 3+ products in the same category
          (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you
          subscribe to haircare or skincare. Subsequent subscription orders will receive free
          shipping and 15% off. Offer is valid when your subscription order is placed by 11/17/2024
          at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or
          non-subscribed items. Not valid for accessories or digital gift cards. For full details on
          our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'The Jetset Sale',
      description: (
        <>
          <b>Get 50% off + 2 free Travel Pouches ($16 value!)</b> when you subscribe to any 3+ hair
          or skin products.
        </>
      ),
      conditions: (
        <>
          *Get 2 free Travel Pouches when you subscribe to 3+ products in the same category
          (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you
          subscribe to haircare or skincare. Subsequent subscription orders will receive free
          shipping and 15% off. Offer is valid when your subscription order is placed by 11/17/2024
          at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or
          non-subscribed items. Not valid for accessories or digital gift cards. For full details on
          our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'The Jetset Sale',
      description: (
        <>
          Get <b>50% off + 2 free travel pouches</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Try custom haircare with 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'The Jetset Sale',
      title: 'Get 50% off + 2 free Travel Pouches',
      description:
        'Subscribe to any 3+ haircare products to claim your free gift. Limited time only! ',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions: (
        <>
          *Get 2 free Travel Pouches when you subscribe to 3+ haircare products. Enjoy a total of
          50% off your first subscription order when you subscribe to haircare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 11/17/2024 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'The Jetset Sale',
      description: (
        <>
          <b>Get 50% off + 2 free Travel Pouches ($16 value!)</b> when you subscribe to any 3+
          haircare products.
        </>
      ),
      conditions: (
        <>
          *Get 2 free Travel Pouches when you subscribe to 3+ haircare products. Enjoy a total of
          50% off your first subscription order when you subscribe to haircare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 11/17/2024 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'The Jetset Sale',
      description: (
        <>
          <b>Get 50% off + 2 free Travel Pouches ($16 value!)</b> when you subscribe to any 3+
          haircare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Try custom skincare with 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'The Jetset Sale',
      title: 'Get 50% off + 2 free Travel Pouches',
      description:
        'Subscribe to any 3+ skincare products to claim your free gift. Limited time only! ',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions: (
        <>
          *Get 2 free Travel Pouches when you subscribe to 3+ skincare products. Enjoy a total of
          50% off your first subscription order when you subscribe to skincare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 11/17/2024 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'The Jetset Sale',
      description: (
        <>
          <b>Get 50% off + 2 free Travel Pouches ($16 value!)</b> when you subscribe to any 3+
          skincare products.
        </>
      ),
      conditions: (
        <>
          *Get 2 free Travel Pouches when you subscribe to 3+ skincare products. Enjoy a total of
          50% off your first subscription order when you subscribe to skincare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 11/17/2024 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'The Jetset Sale',
      description: (
        <>
          <b>Get 50% off + 2 free Travel Pouches ($16 value!)</b> when you subscribe to any 3+
          skincare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
