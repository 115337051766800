import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const comfort = {
  name: 'comfort',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/comfort`,
  title: 'Does your skin experience severe tightness, dryness, itching, or redness?',
  subtitle: 'If this changes throughout the year, answer based on the last 2 months.',
  tip: {
    title: 'why we ask:',
    content:
      'These common concerns may be indications of reactive of allergy-prone skin, or of general sensitivity.',
  },
  options: [
    {
      value: 'yes_often',
      label: 'Yes, often',
    },
    {
      value: 'yes_after_testing',
      label: 'Yes, usually after testing a new product',
    },
    {
      value: 'sometimes',
      label: 'Sometimes yes, sometimes no',
    },
    {
      value: 'never',
      label: "Not that I've noticed",
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_comfort,
  getAnswers: ({ selected }) => ({
    diag_comfort: selected,
  }),
};

export default comfort;
