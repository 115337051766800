import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const zitsHormonal = {
  name: 'zits_hormonal',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/zits-hormonal`,
  title: 'Do you regularly break out during your menstrual cycle?',
  subtitle: 'Choose what best reflects your skin in the last 6 months.',
  tip: {
    title: 'Why we ask:',
    content:
      'Learning more about the frequency of your breakouts will help us identify the right active ingredients to include in your formulas.',
  },
  options: [
    {
      value: 'regularly_break_out',
      label: 'Yes',
    },
    {
      value: 'no_break_out',
      label: 'No',
    },
    {
      value: 'not_concerned',
      label: 'This does not apply to me',
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('breakouts'),
  getInitialValue: answers => answers.diag_zits_hormonal,
  getAnswers: ({ selected }) => ({
    diag_zits_hormonal: selected,
  }),
};

export default zitsHormonal;
