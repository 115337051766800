import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

// in case user has no dandruff, we need to add a default dandruff type
const getDandruffAnswer = selected => {
  const answer = {
    diag_dandruff_level: selected,
  };

  return answer;
};

const myHairDandruffLevel = {
  name: 'dandruff-level',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/dandruff-level`,
  title: 'Are you prone to flakiness?',
  tip: {
    title: 'Did you know...',
    content: `Flakes come from the skin of your scalp, and can be triggered by weather, hormones, or irritating products. Build-up comes from products sitting on your scalp and accumulating over time—usually stylers like aerosol dry shampoo, hairspray, mousse, etc.

&nbsp;

If you don’t see an issue when you don’t use these products, you probably just have build-up.`,
  },
  options: [
    { value: 0, label: 'No' },
    { value: 1, label: 'Rarely' },
    { value: 3, label: 'Sometimes' },
    { value: 4, label: 'Very Often' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_dandruff_level,
  getAnswers: ({ selected }) => getDandruffAnswer(selected),
};

export default myHairDandruffLevel;
