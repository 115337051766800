import PropTypes from 'prop-types';

import { imageProp } from 'PropTypes/imageProps';

const LazyImg = ({ offset, height, src, alt, width, ...rest }) => (
  <div height={height} offset={offset}>
    <img alt={alt} src={src} width={width} {...rest} />
  </div>
);

LazyImg.propTypes = {
  alt: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offset: PropTypes.number,
  src: imageProp.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

LazyImg.defaultProps = {
  height: 200,
  offset: 500,
  width: null,
};

export default LazyImg;
