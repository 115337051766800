import defer from 'lodash/fp/defer';

import * as Sentry from '@sentry/nextjs';

import * as BreadcrumbsService from 'Services/BreadcrumbsService';

// Here we send the data to GTM and Heap when the experiment is executed
const onChoice = (Test, variantName, inBreadcrumbs = false) => {
  // GTM & GA
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'exp', expId: Test.TEST_ID, expVar: variantName });
  }
  // HEAP
  if (window.heap && window.heap.track) {
    /* defer the call to make sure it happens after heap's pageview event */
    defer(() =>
      window.heap.track('Experiment Viewed', {
        experimentId: Test.TEST_ID,
        experimentName: Test.name,
        experimentVariationId: variantName,
      })
    );
  }
  // Hotjar
  if (typeof window.hj === 'function') {
    window.hj('trigger', `${Test.TEST_ID}_${variantName}`);
  }

  // Track internally in Prose Breadcrumbs
  if (inBreadcrumbs) {
    BreadcrumbsService.post({
      type: BreadcrumbsService.TYPE_EXPERIMENT_VIEWED,
      version: 1,
      data: {
        experiment_id: Test.TEST_ID,
        experiment_name: Test.name,
        experiment_value: variantName,
      },
    }).catch(error => {
      Sentry.withScope(scope => {
        scope.setLevel('info');
        Sentry.captureMessage(`[Breadcrumb] ${error}`);
      });
    });
  }
};

export default onChoice;
