import { useEffect, useMemo, useState } from 'react';

const useMedia = (matchMediaParam: string) => {
  const query = useMemo(() => window.matchMedia(matchMediaParam), [matchMediaParam]);

  const [value, setValue] = useState(query.matches);

  useEffect(() => {
    const handler = () => setValue(query.matches);
    query.addEventListener('change', handler);

    return () => query.removeEventListener('change', handler);
  }, [query]);

  return value;
};

export default useMedia;
