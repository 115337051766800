import boarImg from 'assets/images/products/prose-boar-brush.png';
import mixedImg from 'assets/images/products/prose-boar-nylon-brush.png';
import detanglingImg from 'assets/images/products/prose-metal-tipped-brush.png';

export const brush_boar = {
  picture: boarImg,
  label: 'Boar Bristle',
};

export const brush_detangling = {
  picture: detanglingImg,
  label: 'Tipped-Metal Bristle',
};

export const brush_mixed = {
  picture: mixedImg,
  label: 'Boar & Nylon Bristle',
};
