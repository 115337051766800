import { productsCategories, productsLabels, productsSlugs } from 'constants/products';

import bgPictureCandleArcadia from 'assets/images/productPages/accessories-collection-candle_arcadia.jpg';
import bgPictureCandleCorsica from 'assets/images/productPages/accessories-collection-candle_corsica.jpg';
import bgPictureCandlePrelude from 'assets/images/productPages/accessories-collection-candle_prelude.jpg';
import selectorPictureBoarBrush from 'assets/images/productPages/boar-brush-selector.png';
import selectorPictureArcadia from 'assets/images/productPages/candle_arcadia-selector.png';
import selectorPictureCorsica from 'assets/images/productPages/candle_corsica-selector.png';
import selectorPicturePrelude from 'assets/images/productPages/candle_prelude-selector.png';
import selectorPictureBoarDetangling from 'assets/images/productPages/detangling-brush-selector.png';
import selectorPictureBoarMixed from 'assets/images/productPages/mixed-brush-selector.png';
import bgPictureBrushMixed from 'assets/images/productPages/prose-boar-and-nylon-hair-brush.jpg';
import bgPictureBrushMixedHover from 'assets/images/productPages/prose-boar-and-nylon-hair-brush-in-use.jpg';
import bgPictureBrush from 'assets/images/productPages/prose-boar-bristle-hair-brush.jpg';
import bgPictureBrushHover from 'assets/images/productPages/prose-boar-bristle-hair-brush-in-use.jpg';
import bgPictureCandle from 'assets/images/productPages/prose-candle.jpg';
import bgPictureCandleHover from 'assets/images/productPages/prose-candle-collection-trio-stacked.jpg';
import bgPictureDigitalGift from 'assets/images/productPages/prose-hair-and-skin-trio-bundle.jpg';
import bgPictureHairTowel from 'assets/images/productPages/prose-hair-towel-wrap.jpg';
import bgPictureDigitalGiftHover from 'assets/images/productPages/prose-haircare-and-skincare-trio-with-models.jpg';
import bgPictureHairTowelHover from 'assets/images/productPages/prose-model-with-hair-towel-wrap.jpg';
import bgPictureBrushDetangling from 'assets/images/productPages/prose-tipped-metal-hair-brush.jpg';
import bgPictureBrushDetanglingHover from 'assets/images/productPages/prose-tipped-metal-hair-brush-in-use.jpg';
import candleArcadiaPicture from 'assets/images/products/prose-arcadia-candle.png';
import candleCorsicaPicture from 'assets/images/products/prose-corsica-candle.png';
import hairTowelPicture from 'assets/images/products/prose-hair-towel.png';
import candlePreludePicture from 'assets/images/products/prose-prelude-candle.png';

import { brush_boar, brush_detangling, brush_mixed } from './brushes';

export const CANDLE_ARCADIA = 'candle_arcadia';
export const CANDLE_PRELUDE = 'candle_prelude';
export const CANDLE_CORSICA = 'candle_corsica';

export const accessoriesProducts = [
  CANDLE_ARCADIA,
  CANDLE_PRELUDE,
  CANDLE_CORSICA,
  productsSlugs.HAIR_TOWEL_WRAP,
];

export const brush = {
  displayingOrderOnPLP: 2,
  label: productsLabels[productsSlugs.BRUSH],
  price: 72,
  bgPicture: bgPictureBrush,
  bgLabelledPicture: bgPictureBrushHover,
  productPageUrl: '/products/brushes',
  currency: 'USD',
  is_subscribable: false,
  slug: productsSlugs.BRUSH,
  type: productsCategories.HAIRCARE,
  dataClickOnCollection: 'brush-pdp',
  isCustom: false,
  tag: 'accessories',
  capacity: '8.75” x 2.5” x 1.5”',
  showCapacity: false,
  variantsContent: {
    [productsSlugs.BRUSH_MIXED]: {
      order: 2,
      description:
        'Longer nylon and shorter boar bristles work double duty to smooth, detangle, and boost shine.',
      productPageUrl: `/products/brushes?variant=${productsSlugs.BRUSH_MIXED}`,
      slug: productsSlugs.BRUSH_MIXED,
      label: productsLabels[productsSlugs.BRUSH_MIXED],
      selectorPicture: selectorPictureBoarMixed,
      selectorPictureAlt: 'Mixed brush',
      picture: brush_mixed,
      bgPicture: bgPictureBrushMixed,
      bgLabelledPicture: bgPictureBrushMixedHover,
      bgPictureAlt: 'Prose boar and nylon hair brush',
      productSquaredPicture: bgPictureBrushMixed,
      productSquaredPictureHover: bgPictureBrushMixed,
    },
    [productsSlugs.BRUSH_BOAR]: {
      order: 1,
      description:
        'Handcrafted with 100% boar bristle. Made with only the root of the bristle for an ultra-luxurious feel.',
      productPageUrl: `/products/brushes?variant=${productsSlugs.BRUSH_BOAR}`,
      slug: productsSlugs.BRUSH_BOAR,
      label: productsLabels[productsSlugs.BRUSH_BOAR],
      selectorPicture: selectorPictureBoarBrush,
      selectorPictureAlt: 'Boar brush',
      picture: brush_boar,
      bgPicture: bgPictureBrush,
      bgLabelledPicture: bgPictureBrushHover,
      bgPictureAlt: 'Prose boar bristle hair brush',
      productSquaredPicture: bgPictureBrush,
      productSquaredPictureHover: bgPictureBrush,
    },
    [productsSlugs.BRUSH_DETANGLING]: {
      order: 3,
      description:
        'Resilient-yet-flexible wire bristles for gentle detangling — without breakage or damage.',
      productPageUrl: `/products/brushes?variant=${productsSlugs.BRUSH_DETANGLING}`,
      slug: productsSlugs.BRUSH_DETANGLING,
      label: productsLabels[productsSlugs.BRUSH_DETANGLING],
      selectorPicture: selectorPictureBoarDetangling,
      selectorPictureAlt: 'Detangling brush',
      picture: brush_detangling,
      bgPicture: bgPictureBrushDetangling,
      bgLabelledPicture: bgPictureBrushDetanglingHover,
      bgPictureAlt: 'Prose tipped metal hair brush',
      productSquaredPicture: bgPictureBrushDetangling,
      productSquaredPictureHover: bgPictureBrushDetangling,
    },
  },
};

export const digital_gift = {
  displayingOrderOnPLP: 3,
  label: productsLabels[productsSlugs.DIGITAL_GIFT],
  price: 56,
  bgPicture: bgPictureDigitalGift,
  bgLabelledPicture: bgPictureDigitalGiftHover,
  bgPictureAlt: 'Hair and skin trio bundle',
  productPageUrl: '/gift',
  currency: 'USD',
  is_subscribable: false,
  slug: productsSlugs.DIGITAL_GIFT,
  type: productsCategories.OTHERS,
  isCustom: false,
  hasMultiplePrices: true,
  dataClickOnCollection: 'gifting-lp',
};

export const hair_towel_wrap = {
  displayingOrderOnPLP: 0,
  label: productsLabels[productsSlugs.HAIR_TOWEL_WRAP],
  description:
    'Fast-drying hair towel for reducing unwanted frizz and preserving hair’s natural texture. Made from 100% recycled microfiber derived from plastic bottles.',
  price: 34,
  bgPicture: bgPictureHairTowel,
  bgLabelledPicture: bgPictureHairTowelHover,
  bgPictureAlt: 'Prose hair towel wrap',
  productPageUrl: '/products/prose-hair-towel',
  currency: 'USD',
  is_subscribable: false,
  slug: productsSlugs.HAIR_TOWEL_WRAP,
  type: productsCategories.OTHERS,
  isCustom: false,
  dataClickOnCollection: 'hair_towel-lp',
  tag: 'accessories',
  picture: hairTowelPicture,
};

export const candle = {
  displayingOrderOnPLP: 1,
  dataClickOnCollection: 'candles-pdp',
  capacity: '8.6 oz',
  description:
    'Sustainably crafted, paraffin-free candles featuring 3 of our fan-favorite fragrances. Includes protective dust cover.',
  label: productsLabels[productsSlugs.CANDLE],
  picture: candlePreludePicture,
  bgPicture: bgPictureCandle,
  bgLabelledPicture: bgPictureCandleHover,
  bgPictureAlt: 'Prose candle',
  productSquaredPicture: bgPictureCandleArcadia,
  productSquaredPictureHover: bgPictureCandleArcadia,
  productPageUrl: '/products/prose-candle',
  price: 52,
  subscribed_price: 52,
  currency: 'USD',
  is_subscribable: false,
  slug: productsSlugs.CANDLE,
  type: productsCategories.OTHERS,
  cardTag: 'New',
  tag: 'accessories',
  variantsContent: {
    [CANDLE_ARCADIA]: {
      order: 3,
      slug: CANDLE_ARCADIA,
      label: 'Arcadia',
      selectorPicture: selectorPictureArcadia,
      selectorPictureAlt: 'Arcadia candle',
      picture: candleArcadiaPicture,
      bgPicture: bgPictureCandleArcadia,
      bgLabelledPicture: bgPictureCandleArcadia,
      productSquaredPicture: bgPictureCandleArcadia,
      productSquaredPictureHover: bgPictureCandleArcadia,
    },
    [CANDLE_PRELUDE]: {
      order: 2,
      slug: CANDLE_PRELUDE,
      label: 'Prelude',
      selectorPicture: selectorPicturePrelude,
      selectorPictureAlt: 'Prelude candle',
      picture: candlePreludePicture,
      bgPicture: bgPictureCandlePrelude,
      bgLabelledPicture: bgPictureCandlePrelude,
      productSquaredPicture: bgPictureCandlePrelude,
      productSquaredPictureHover: bgPictureCandlePrelude,
    },
    [CANDLE_CORSICA]: {
      order: 1,
      slug: CANDLE_CORSICA,
      label: 'Corsica',
      selectorPicture: selectorPictureCorsica,
      selectorPictureAlt: 'Corsica candle',
      picture: candleCorsicaPicture,
      bgPicture: bgPictureCandleCorsica,
      bgLabelledPicture: bgPictureCandleCorsica,
      productSquaredPicture: bgPictureCandleCorsica,
      productSquaredPictureHover: bgPictureCandleCorsica,
    },
  },
};
