import capitalize from 'lodash/fp/capitalize';

import dayjs from 'dayjs';

import { countriesCode } from 'constants/countries';

import config from '../Config';

/** Plural
 * Params
 *  quantity: Int - value to be tested
 *  displayQuantity: Bool - Should the value be returned in the string
 *  singular: String - Singular form of the word
 *  pluralForm: String - If provided will be returned for quantity > 1
 *    or it will default to singular with an -s
 */
export const plural = (quantity, displayQuantity, singular, pluralForm) => {
  let s = '';

  if (displayQuantity) {
    s = `${quantity} `;
  }

  if (quantity < 2) {
    s += singular;
  } else if (pluralForm) {
    s += pluralForm;
  } else {
    s += `${singular}s`;
  }

  return s;
};

/** capitalizeFirstLetter
 * Params
 */
export const capitalizeFirstLetter = string => {
  return capitalize(string);
};

/** Genitive
 * Decline the name to the Genitive case
 *  Heidi -> Heidi's
 *  Boris -> Boris'
 *
 * Params:
 *  name: Int - value to be tested
 */
export const genitive = name => (name[name.length - 1] === 's' ? `${name}’` : `${name}’s`);

/** list to comma separated string last one and
 * ['a', 'b', 'c'] => 'a, b and c'
 * ['a'] => 'a'
 *
 * Params:
 *  name: list - list of items to be parsed
 */
export const commasAndListToString = list => {
  if (list.length === 1) return list[0];
  const allButLast = list.slice(0, list.length - 1);
  const last = list[list.length - 1];
  return `${allButLast.join(', ')} and ${last}`;
};

// extract only digits from string
export const parseDigits = string => (string.match(/\d+/g) || []).join('');

export const parseLetters = string => (string.match(/[A-Za-z]+/g) || []).join('');

// format full phone number, fail if not valid
export const formatPhone = string => {
  if (typeof string !== 'string') {
    throw new Error('formatPhone: Expected a string input');
  }

  const digits = parseDigits(string);

  if (digits.length !== 10) {
    throw new Error('formatPhone: Phone number must be 10 digits');
  }

  const match = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
  return `(${match[1]}) ${match[2]}-${match[3]}`;
};

// format phone number as you type
export const formatPhoneInput = string => {
  const digits = parseDigits(string);

  if (digits.length < 3) {
    return digits;
  }
  if (digits.length === 3) {
    return `(${digits})`;
  }
  if (digits.length <= 6) {
    return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}`;
  }
  return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`;
};

export const addWeekdays = (dateInput, daysInput) => {
  let date = dayjs(dateInput) || dayjs();
  let days = daysInput || config.shippingDelay;

  while (days > 0) {
    date = date.add(1, 'days');
    // skip saturday and sunday
    if (![6, 0].includes(date.day())) {
      days -= 1;
    }
  }

  return date;
};

const formatCanadaZipcode = ({ value, previousValue }) => {
  if (value?.length === 3 && previousValue?.length === 2) {
    return `${value?.toUpperCase()} `;
  }
  // This condition to avoid the user being blocked at the space step when deleting the zipcode
  if (value?.length === 4 && previousValue?.length === 3 && value?.[value?.length - 1] !== ' ') {
    return `${value.substr(0, 3).toUpperCase()} ${value[3].toUpperCase()}`;
  }
  // The first part of the condition is to prevent the user from entering multiple spaces in between the zipcode
  if ((value?.length === 5 && value?.[value?.length - 1] === ' ') || value.length > 7) {
    return previousValue;
  }
  return value?.toUpperCase();
};

export const formatZipcodeByCountryCode = (payload, country) => {
  switch (country) {
    case countriesCode.CA:
      return formatCanadaZipcode(payload);
    default:
      return payload.value;
  }
};

export const convertArrayToStringWithComas = array =>
  array.reduce((acc, curr, i) => (i === 0 ? `${curr}` : `${acc}, ${curr}`));
