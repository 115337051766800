import Typography from 'Components/Typography';

import { MY_SKIN } from '../constants';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { SKIN } from '../constants/progressCategories';

import cheekbone from 'assets/images/consultation/zitsLocation/zits-location-cheekbone.png';
import forehead from 'assets/images/consultation/zitsLocation/zits-location-forehead.png';
import jawline from 'assets/images/consultation/zitsLocation/zits-location-jawline.png';
import nose from 'assets/images/consultation/zitsLocation/zits-location-nose.png';
import upperneck from 'assets/images/consultation/zitsLocation/zits-location-upperneck.png';

const zitsLocation = {
  CardProps: {
    twoColumns: true,
  },
  name: 'zits_location',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/zits-location`,
  title: 'Where do you usually break out?',
  subtitle: 'Select all that apply, thinking about the last 6 months.',
  tip: {
    title: 'why we ask:',
    content:
      'The location of breakouts can tell us a lot about their underlying causes. Knowing what triggers your breakouts is key to selecting active ingredients that target the right issues, and protecting the long-term equilibrium of your skin.',
    richContent: {
      contentComesFirst: true,
      Component: consultationTipsComponents.SliderImageAndContent,
      slides: () => [
        {
          id: 'forehead',
          heapTracker: 'Zits Location Forehead',
          image: {
            src: forehead,
            alt: 'Illustration of a face with forehead highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                1 - Forehead Acne
              </Typography>
              <Typography align="center" variant="p3">
                Could be fungal acne (pityrosporum) or pore-clogging hair products.
              </Typography>
            </>
          ),
        },
        {
          id: 'cheek',
          heapTracker: 'Zits Location Cheek',
          image: {
            src: cheekbone,
            alt: 'Illustration of a face with the cheek highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                2 - Cheek Acne
              </Typography>
              <Typography align="center" variant="p3">
                Might be lifestyle -- fingers or phone on your cheek, friction from your pillow,
                etc.
              </Typography>
            </>
          ),
        },
        {
          id: 'nose',
          heapTracker: 'Zits Location Nose',
          image: {
            src: nose,
            alt: 'Illustration of a face with the nose highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                3 - Nose Acne
              </Typography>
              <Typography align="center" variant="p3">
                Might be blackheads -- clogged pores that turn black when trapped oil and skin cells
                are exposed to the air.
              </Typography>
            </>
          ),
        },
        {
          id: 'jawline',
          heapTracker: 'Zits Location Jawline',
          image: {
            src: jawline,
            alt: 'Illustration of a face with the jawline highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                4 - Jawline Acne
              </Typography>
              <Typography align="center" variant="p3">
                Likely hormonal acne -- often caused by your skins oil glands overreacting to normal
                hormones
              </Typography>
            </>
          ),
        },
        {
          id: 'upperNeck',
          heapTracker: 'Zits Location Upper Neck',
          image: {
            src: upperneck,
            alt: 'Illustration of a face with the upperNeck highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                5 - Upper Neck Acne
              </Typography>
              <Typography align="center" variant="p3">
                Likely hormonal acne -- often caused by your skins oil glands overreacting to normal
                hormones
              </Typography>
            </>
          ),
        },
      ],
    },
  },
  options: [
    {
      value: 'jaw',
      label: 'Along my jawline',
    },
    {
      value: 'cheeks',
      label: 'On my cheeks',
    },
    {
      value: 'forehead',
      label: 'On my forehead',
    },
    {
      value: 'nose',
      label: 'On and around my nose',
    },
    {
      value: 'chin',
      label: 'On my chin',
    },
    {
      value: 'neck',
      label: 'On my neck',
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('breakouts'),
  getInitialValue: answers => answers.diag_zits_location,
  getAnswers: ({ selected }) => ({
    diag_zits_location: selected,
  }),
  multi: true,
};

export default zitsLocation;
