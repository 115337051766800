import { MY_LIFESTYLE } from '../../constants';
import * as consultationComponents from '../../constants/consultationComponents';
import { HAIRCARE, SKINCARE } from '../../constants/consultationProductsCategories';
import { LIFESTYLE } from '../../constants/progressCategories';

import { ReactComponent as BigScribble } from 'assets/images/skincare/bigScribble.svg';

const stressIntensity = {
  name: 'stress-intensity',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  ModalComponent: consultationComponents.MedicalModal,
  // route is defined at question set level since this question is shared
  // route: '',
  multi: true,
  title: 'Have you experienced any of these factors in the last 6 months?',
  subtitle: 'Select all that apply.',
  icon: <BigScribble />,
  tip: {
    title: 'Why we ask:',
    content: {
      [HAIRCARE]: `A sudden upsurge in hair shedding is often a physical reaction to changing conditions, and can usually be reversed. This is different from permanent hair loss, which is not as easily or even not reversible.

&nbsp;

If you're already taking medication, consult your doctor before starting any supplements.`,
      [SKINCARE]: `This helps us determine how much TLC your skin needs right now. A major stressful event impacts your hormones, which in turn impacts your skin.

&nbsp;

If you have an existing skin condition or are under medical treatment, we’ll customize your formulas with gentle yet effective actives.`,
    },
  },
  requiredOptionsForModalToOpen: [
    'medication_change',
    'diagnosed_scalp_disorder',
    'medical_treatment',
  ],
  options: [
    {
      value: 'major_stressfull_event',
      label: 'Major stressful event',
      description: 'e.g. job loss, surgery, breakup, bereavement',
    },
    {
      value: 'medication_change',
      label: 'Stopped or started a new medication',
      description: 'e.g. birth control, insulin',
    },
    {
      value: 'diagnosed_scalp_disorder',
      label: (
        <>
          Living with a diagnosed hair, scalp, <br /> or skin condition
        </>
      ),
      description: 'e.g. psoriasis, eczema',
    },
    {
      value: 'medical_treatment',
      label: (
        <>
          Medical treatment that affects
          <br />
          hair, scalp or skin
        </>
      ),
      description: 'e.g. chemotherapy, thyroid treatment',
    },
    { value: 'none', label: 'None of the above', unique: true },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_stress_cause,
  getAnswers: ({ selected }) => ({ profile_stress_cause: selected }),
};

export default stressIntensity;
