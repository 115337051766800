import conditionerImg from 'assets/images/checkout_trial_conditioner.png';
import shampooImg from 'assets/images/checkout_trial_shampoo.png';

export const trial_conditioner = {
  label: 'Conditioner',
  picture: conditionerImg,
};

export const trial_shampoo = {
  label: 'Shampoo',
  picture: shampooImg,
};
