import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

import { ReactComponent as SunglassesIcon } from 'assets/images/skincare/phototype.svg';

const phototype = {
  name: 'phototype',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/phototype`,
  title: 'How does your skin react if you spend an afternoon in the sun without wearing SPF?',
  subtitle: 'This helps to assess your skin tone and vulnerability to UV damage.',
  icon: <SunglassesIcon />,
  tip: {
    title: 'Fun fact:',
    content:
      'Most sun damage is cumulative, and builds up over a lifetime. Some skin is more vulnerable to UV damage than others, but everyone needs a daily SPF product — even if you rarely burn.',
  },
  options: [
    {
      value: 'burns_quickly',
      label: 'Burns quickly, doesn’t tan',
    },
    {
      value: 'burns_quickly_hardly_tans',
      label: 'Burns quickly, hardly tans',
    },
    {
      value: 'burns_occasionally',
      label: 'Burns occasionally, gradually tans',
    },
    {
      value: 'hardly_burns',
      label: 'Burns occasionally, tans easily',
    },
    {
      value: 'rarely_burns',
      label: 'Burns very rarely, tans easily',
    },
    {
      value: 'never_burns',
      label: 'Burns very rarely, doesn’t tan',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_phototype,
  getAnswers: ({ selected }) => ({
    diag_phototype: selected,
  }),
};

export default phototype;
