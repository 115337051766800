import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsColorTreatmentsCadence = {
  name: 'color-treatments-cadence',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/color-treatments-cadence`,
  title: 'How often do you color-treat your hair?',
  subtitle: 'We’re more interested in bleach & permanent color here, not the temporary stuff.',
  tip: {
    title: 'Why we ask:',
    content:
      'Bleach & permanent color cause the most damage to hair, especially with frequent treatments. Temporary color is nearly damage-free and doesn’t factor as much here.',
  },
  options: [
    { value: 'every_month', label: 'Every month' },
    { value: 'two_three_months', label: 'Every 2 or 3 months' },
    { value: 'few_a_year', label: 'A couple times a year' },
    { value: 'just_started', label: 'I just started' },
  ],
  // Dynamic logic
  shouldBeIncluded: ({ answers }) =>
    answers.diag_color_treatments === true &&
    // Need to check if next line is still relevant
    answers.diag_color_treatments_location !== 'none' && // In order to support back navigation we need to check this again
    ['permanent', 'bleach'].some(item => answers.diag_color_treatments_type?.includes(item)),
  getInitialValue: answers => answers.diag_color_treatments_frequency,
  getAnswers: ({ selected }) => ({
    diag_color_treatments_frequency: selected,
  }),
};

export default myTreatmentsColorTreatmentsCadence;
