import { createABTestHook } from 'abTesting/hooks';
import { SssShippingFeeExperiment } from 'abTesting/testConfigs';

import {
  getActiveOrVariantForSssShippingFeeAbTest,
  isSssShippingFeeAbTestInExperiment,
} from 'dux/featureFlags/selectors';

export const useSssShippingFeeAbTest = createABTestHook({
  isInExperimentSelector: isSssShippingFeeAbTestInExperiment,
  variantNameSelector: getActiveOrVariantForSssShippingFeeAbTest,
  experimentConfig: SssShippingFeeExperiment,
});
