import { theme } from '@prose-ui';

const BagIcon = props => {
  return (
    <svg
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0778 21.9999H1.11111L0 6.2666H16.1889L15.0778 21.9999Z"
        fill={theme.colors.primary[400]}
      />
      <path
        d="M8.10018 0C5.43351 0 3.26685 2.16667 3.26685 4.82222V5.26667H5.3224V4.82222C5.3224 3.28889 6.56685 2.05556 8.08907 2.05556C9.61129 2.05556 10.8557 3.3 10.8557 4.82222V5.26667H12.9113V4.82222C12.9224 2.16667 10.7557 0 8.10018 0Z"
        fill={theme.colors.primary[400]}
      />
    </svg>
  );
};

export default BagIcon;
