export const defaultContent = (
  <>
    <span>500k+ 5-star Prose haircare reviews </span>
    <span>on Review & Refine®</span>
  </>
);

export const noFreeShippingContent = 'Limited offer: free starter kit, only pay for shipping';
export const referralCta = 'Refer a friend. Give $20, get $20';
export const membershipCta = 'Subscribe for 15% off + free products. Cancel anytime.';

export const vanityUrlDailypop =
  'Hi DailyPop Viewers! $10 off your first order will be applied at cart';

export const membershipMultiCategoryCta = 'Save 15% when you subscribe to haircare and skincare';

// User has already a skincare subscription
export const membershipMultiCategoryWithSkincareSubscriptionCta =
  'Save 15% when you also subscribe to haircare';

// User has already a haircare subscription
export const membershipMultiCategoryWithHaircareSubscriptionCta =
  'Save 15% when you also subscribe to skincare';

export const accessoriesPromotion2023BannerText = 'Shop 35% off accessories for a limited time';
