import { productsCategories } from 'constants/products';

import haircareCategoryPicture from 'assets/images/consultation/categories/haircare_category.jpg';
import skincareCategoryPicture from 'assets/images/consultation/categories/skincare_category.jpg';

const consultationCategorySelection = {
  CardProps: {
    align: 'flex-start',
  },
  name: 'consultation-category',
  route: '/consultation/category',
  shouldBeIncluded: () => true,
  public: true,
  title: 'What can we customize for you today?',
  subtitle:
    "Choose which consultation you'd like to start with. After you complete one, you'll be able to take the other.",
  options: [
    {
      dataFrom: 'category-selection',
      dataClick: productsCategories.HAIRCARE,
      value: productsCategories.HAIRCARE,
      label: 'Haircare',
      description: 'Shampoo, conditioner, masks, stylers',
      imageUrl: haircareCategoryPicture,
      dataTestId: 'haircare-card-category',
    },
    {
      dataFrom: 'category-selection',
      dataClick: productsCategories.SKINCARE,
      value: productsCategories.SKINCARE,
      label: 'Skincare',
      description: 'Cleanser, serum, moisturizer',
      imageUrl: skincareCategoryPicture,
      dataTestId: 'skincare-card-category',
    },
  ],
  skipSave: true,
  showProgressTracker: false,
};

export default consultationCategorySelection;
