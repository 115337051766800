export const abandonedCart = productName =>
  productName ? `Order your ${productName}` : 'Order your Prose';
export const newUser = (productName = false) =>
  productName ? `Buy your ${productName}` : 'Get your formula';
export const newUserPDP = 'Get your formula';
export const oneConsultationNotStarted = 'Get your formula';
export const oneConsultationStarted = 'Finish your consultation';
export const reorder = 'Order your Prose';
export const posology = 'See your formula';
export const soldOut = 'Sold out';
export const waitingList = 'Join Waiting List';
