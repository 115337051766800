import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

import { getOptionsfromTextureAndTreatment, stylingGelValuesExtensions } from '../hairstylesUtils';

const myTreatmentsHairStyles = {
  name: 'hair-style',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/hair-style`,
  title: 'Do you wear any of these styles?',
  subtitle: 'Select all that apply. Include any style you plan to wear in the near future.',
  tip: answers => ({
    title: 'Why we ask?',
    content: getOptionsfromTextureAndTreatment(answers)?.tip,
  }),
  multi: true,
  options: answers => getOptionsfromTextureAndTreatment(answers)?.options,
  shouldBeIncluded: () => true,
  getInitialValue: answers => {
    const hasStylingGelValue = answers?.diag_extensions?.some(answer =>
      stylingGelValuesExtensions.includes(answer)
    );
    return hasStylingGelValue ? answers.diag_extensions : null;
  },
  getAnswers: ({ selected }) => ({ diag_extensions: selected }),
};

export default myTreatmentsHairStyles;
