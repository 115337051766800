import { MY_SKIN } from '../../constants';
import { Transition } from '../../constants/consultationComponents';
import { SKIN } from '../../constants/progressCategories';

import yourSkinDesktop from 'assets/images/consultation/transitionScreens/d-your-skin_skin.png';
import yourSkinMobile from 'assets/images/consultation/transitionScreens/m-your-skin_skin.png';

const consultationBackgroundImages = {
  mobile: yourSkinMobile,
  tablet: yourSkinDesktop,
  desktop: yourSkinDesktop,
};

const yourSkinTransitionScreen = {
  name: 'intro-your-skin',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/introduction`,
  title: 'Section 2',
  subtitle: 'How your skin feels',
  body: 'Tell us about your skin and current skincare routine so we can provide ever-evolving, custom-fitting care.',
  Component: Transition,
  skipSave: true,
  shouldBeIncluded: () => true,
  image: () => consultationBackgroundImages,
  public: true,
};

export default yourSkinTransitionScreen;
