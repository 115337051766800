import { HTTPError as KyHTTPError, type NormalizedOptions } from 'ky';
import { isKey } from 'types/predicates';

class HTTPError extends Error {
  override name = 'HTTPError';

  code: string;

  detail: string;

  extra: {};

  constructor(
    { code = 'server_error', detail = 'Server Error', ...extra } = {},
    options?: { cause?: unknown },
  ) {
    super(code, options);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }
    if (options?.cause) {
      this.cause = options.cause;
    }
    this.code = code;
    this.detail = detail;
    this.extra = extra;
  }
}

type KyArgs = {
  response: Response;
  request: Request;
  options: NormalizedOptions;
};

/**
 * INFO: This class exist in order to merge ky error format with our own error format.
 * ky error features are the request, response and options used in the request whcih errored are available.
 * Our error format is already deeply integrated within the codebase, so it's not question at the time this code
 * is written to change this behavior.
 * Thus HTTPInterceptedError to benefit from both.
 */
export class HTTPInterceptedError extends KyHTTPError {
  code: string;

  detail: string;

  extra: {};

  constructor(
    kyArgs: KyArgs,
    { code = 'server_error', detail = 'Server Error', ...extra } = {},
    options?: { cause?: unknown },
  ) {
    super(kyArgs.response, kyArgs.request, kyArgs.options);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }
    if (options?.cause) {
      this.cause = options.cause;
    }
    this.code = code;
    this.detail = detail;
    this.extra = extra;
  }
}

type HttpErrorPattern = {
  code: string;
  detail?: string;
};

const errorMatchesPattern =
  (pattern: HttpErrorPattern) =>
  (error: unknown = {}): error is HTTPError => {
    return (
      /* RTK's `createAsyncThunk` serializes Errors on `throw`, making them plain objects */
      (error instanceof HTTPError || typeof error === 'object') &&
      Object.entries(pattern).every(
        ([key, value]) => error && isKey(error, key) && error[key] === value,
      )
    );
  };

export const isTrialOutOfStock = errorMatchesPattern({
  code: 'trial_kit_out_of_stock',
});
export const isFirstOrderOnly = errorMatchesPattern({
  code: 'first_order_only',
});

export const isPaymentInvalid = (error: unknown) =>
  [
    'card_declined',
    'incorrect_cvc',
    'incorrect_number',
    'expired_card',
    'processing_error',
    'prepaid_card_error',
  ].some((code) => errorMatchesPattern({ code })(error));

// error signature for geo agressor service with unknown zipcode
export const isInvalidZipcode = errorMatchesPattern({
  code: 'invalid_hair_profile',
  detail: 'Cannot fetch aggressors from hair profile',
});

export const isInvalidHairProfile = errorMatchesPattern({
  code: 'invalid_hair_profile',
});

export const isInvalidAuthToken = errorMatchesPattern({
  code: 'auth_invalid_token',
});

export const isInvalidCoupon = (error: unknown) =>
  [
    'coupon_error',
    'coupon_already_redeemed',
    'coupon_inactive',
    'coupon_invalid',
    'coupon_invalid_precondition',
    'coupon_invalid_type',
    'coupon_too_many_usage',
  ].some((code) => errorMatchesPattern({ code })(error));

export const isUnknownUser = errorMatchesPattern({
  code: 'auth_unknown_user',
});

export const isExistingUser = errorMatchesPattern({
  code: 'auth_existing_user',
});

export const isValidationError = errorMatchesPattern({
  code: 'error',
});

export const isFetchError = errorMatchesPattern({
  code: 'failed_to_fetch',
});

export const isInvalidStylistSlug = errorMatchesPattern({
  code: 'invalid_stylist_slug',
  detail: 'Stylist not found',
});

export const isInvalidOrderFilter = errorMatchesPattern({
  code: 'invalid_order_filter',
});

export const isNotAuthenticated = errorMatchesPattern({
  code: 'not_authenticated',
});

export const isAbortError = errorMatchesPattern({
  code: 'request_arborted',
});

export default HTTPError;

export type ServerErrorResponse = {
  code: string;
  detail: string;
  extra?: {};
};
