export const HEAP_EVENT_PROPERTIES = [
  'can_see_feedback',
  'hair_profile_status',
  'has_accepted_terms',
  'has_active_subscription',
  'has_onhold_subscription',
  'has_redeemable_gift',
  'order_count',
] as const;

export const uiStepToString = {
  'account-details': 'Account/Shipping',
  payment: 'Payment',
  confirmation: 'Review',
  success: 'Order Success',
  'wait-payment-confirmation': 'Order Success',
  'shipping-address': 'Shipping address',
} as const;
