import { MY_LIFESTYLE, SKINCARE_QUESTION_SPORTS } from '../../constants';
import { HAIRCARE, SKINCARE } from '../../constants/consultationProductsCategories';
import { LIFESTYLE } from '../../constants/progressCategories';

const sports = {
  name: SKINCARE_QUESTION_SPORTS,
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  // this question is shared. route is explicitly defined within the questions sets importing it
  // route: '',
  multi: true,
  title: 'In an average week, where do you workout?',
  subtitle: 'Select all that apply, keeping in mind your life over the next 2 months.',
  tip: {
    title: 'Why we ask:',
    content: {
      [HAIRCARE]:
        'Sweating affects your hair a lot (as you’ve no doubt experienced), as does the environment that happens in. This gives us insight into both.',
      [SKINCARE]: `
Exercise can increase microcirculation and release endorphins, which lowers stress levels and boosts your glow.

&nbsp;

However, sweat can sometimes irritate the skin and cause breakouts due to excessive oil, dirt, and bacteria.

&nbsp;

Exposure to chlorinated or salt water can cause dehydration, while high-impact exercise can affect skin firmness over time. 

&nbsp;

We factor all of this into your custom formulas so you can focus on your workout, not a potential breakout.
`,
    },
  },
  options: [
    { value: 'indoors', label: 'Indoors / gym' },
    { value: 'outdoors', label: 'Outdoors / running course' },
    { value: 'swimming_pool', label: 'Chlorine swimming pool' },
    { value: 'sea_beach', label: 'Ocean / beachside' },
    {
      value: 'none',
      label: 'None of the above, or I don’t exercise often',
      unique: true,
    },
  ],
  // whether or not this Q should be logically included within the question set
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_sports,
  getAnswers: ({ selected }) => ({ profile_sports: selected }),
};

export default sports;
