import { MY_LIFESTYLE, SKINCARE_QUESTION_OUTDOOR_EXPOSURE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

import { ReactComponent as CloudIcon } from 'assets/images/skincare/cloudIcon.svg';
import pollutionIcon from 'assets/images/skincare/co2Icon.svg';
import sunIcon from 'assets/images/skincare/sunIcon.svg';
import windIcon from 'assets/images/skincare/windIcon.svg';

const outdoorExposure = {
  name: SKINCARE_QUESTION_OUTDOOR_EXPOSURE,
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/outdoor-exposure`,
  title: 'On a typical day, how much time do you spend outdoors?',
  subtitle: 'Consider what your life will look like over the next 2 months.',
  icon: <CloudIcon />,
  tip: {
    title: 'Why we ask:',
    content: `
<br />
<br />
UV rays, wind, pollution, and humidity all have a direct impact on skin health, and can trigger reactions like redness or hyperpigmentation.
<br />
<br />
<br />
<img src="${sunIcon}" alt="sun icon" />
<br />
<br />
UV light
<br />
<br />
<img src="${windIcon}" alt="wind icon" />
<br />
<br />
Wind
<br />
<br />
<img src="${pollutionIcon}" alt="co2 icon" />
<br />
<br />
Pollution and Smog
   `,
  },
  options: [
    { value: 'lt_1h', label: 'Less than an hour' },
    { value: 'le_4h', label: '1-4 hours' },
    { value: 'gt_4h', label: 'More than 4 hours' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_outdoor,
  getAnswers: ({ selected }) => ({
    profile_outdoor: selected,
  }),
};

export default outdoorExposure;
