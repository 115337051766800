import { MY_LIFESTYLE } from '../constants';
import { LIFESTYLE } from '../constants/progressCategories';

const myLifestyleSmokeExposure = {
  name: 'smoke-exposure',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/haircare/${MY_LIFESTYLE}/smoke-exposure`,
  title: 'Does your hair retain noticeable odors from food or smoke?',
  tip: {
    title: 'Why we ask:',
    content:
      'If your hair retains smells you don’t like, replace it with a scent you do. We have an entire portfolio of fragrances developed by French or American perfumers for you to pick from—choose one at the end of this consultation, and your hair will smell heavenly always.',
  },
  options: [
    { value: 'rarely', label: 'Never noticed' },
    { value: 'little', label: 'Yes, sometimes' },
    { value: 'often', label: 'All the time' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_smoke_level,
  getAnswers: ({ selected }) => ({ profile_smoke_level: selected }),
};

export default myLifestyleSmokeExposure;
