import PropTypes from 'prop-types';

import isString from 'lodash/fp/isString';

import Image from 'next/image';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

import getEnv from 'utils/getEnv';
import usePrefersReducedMotion from 'utils/usePrefersReducedMotion';
import useResponsiveVariant from 'utils/useResponsiveVariant';

const videoRoot = getEnv('REACT_APP_MEDIA_ROOT_URL');

const getVideoUrl = videoName =>
  isString(videoRoot) && Boolean(videoRoot) ? videoRoot + videoName : undefined;

const AutoPlayVideo = ({
  handleAutoPlayStatus,
  mobileVideo,
  desktopVideo,
  mobileImage,
  desktopImage,
  imgAlt,
  ...props
}) => {
  const isReducedMotion = usePrefersReducedMotion();
  const { isMobile } = useResponsiveVariant();

  let videoUrl = desktopVideo && getVideoUrl(desktopVideo);
  if (isMobile && mobileVideo) {
    videoUrl = getVideoUrl(mobileVideo);
  }

  let imageUrl = desktopImage?.src ?? desktopImage;
  if (isMobile && mobileImage) {
    imageUrl = mobileImage?.src ?? mobileImage;
  }

  return (
    <video
      autoPlay={!isReducedMotion}
      loop
      muted
      playsInline
      {...(imageUrl && { poster: imageUrl })}
      {...props}
    >
      <source src={`${videoUrl}.webm`} type="video/webm" />
      <source src={`${videoUrl}.mp4`} type="video/mp4" />
      {imageUrl ? <Image alt={imgAlt} fill src={imageUrl} /> : imgAlt}
    </video>
  );
};

AutoPlayVideo.propTypes = {
  handleAutoPlayStatus: PropTypes.func,
  mobileVideo: PropTypes.string.isRequired,
  desktopVideo: PropTypes.string.isRequired,
  mobileImage: srcOrNextImageProp, // requiring this could be good (e.g. for prefers-reduced-motion users)
  desktopImage: srcOrNextImageProp, // requiring this could be good (e.g. for prefers-reduced-motion users)
  imgAlt: PropTypes.string,
};

AutoPlayVideo.defaultProps = {
  handleAutoPlayStatus: () => {},
  mobileImage: '',
  desktopImage: '',
  imgAlt: '',
};
export default AutoPlayVideo;
