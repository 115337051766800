import climateNeutralIcon from 'assets/images/climateNeutral.png';
import bCorpIcon from 'assets/images/icon_bcorp.svg';

const footer = {
  message: (
    <>
      Custom care for
      <br /> skin and hair
    </>
  ),
  cta: 'get your formula',
  bCorp: {
    alt: 'Certified B Corporation',
    image: bCorpIcon,
    link: '/sustainability-and-social-impact',
  },
  climateNeutral: {
    alt: 'Climate Neutral',
    image: climateNeutralIcon,
  },
  doNotSell: {
    copy: 'your privacy choices',
    link: 'https://privacy.prose.com',
  },
};

export default footer;
