import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsStylesCadence = {
  name: 'styles-cadence',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/styles-cadence`,
  title: 'How often do you curl or flat-iron your hair?',
  tip: {
    title: 'Why we ask:',
    content:
      'If you’re a frequent heat-styler, we’ll customize your Leave-in Conditioner to protect your hair from heat up to 450°F,  so you can curl or flat-iron every day without the damage.',
  },
  options: [
    { value: 'every_day', label: 'Every day or every other day' },
    { value: 'once_week', label: 'Once or twice a week' },
    {
      value: 'time_to_time',
      label: 'Only on special occasions',
    },
  ],
  // Dynamic logic
  shouldBeIncluded: ({ answers }) =>
    ['straightener', 'curling'].some(item => answers.diag_styling?.includes(item)),
  getInitialValue: answers => answers.diag_styling_frequency,
  getAnswers: ({ selected }) => ({ diag_styling_frequency: selected }),
};

export default myTreatmentsStylesCadence;
