import slice from 'lodash/fp/slice';

// like a functional splice except it can't delete values, only insert one
const insertAt = (idx, val) => arr => {
  if (!Array.isArray(arr)) {
    return [];
  }
  return [...slice(0, idx)(arr), val, ...slice(idx, arr.length)(arr)];
};

export default insertAt;
