import * as hairTextures from 'constants/hairTextures';
import { MY_PREFERENCES } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

import preferencesType3Desktop from 'assets/images/consultation/transitionScreens/prose-model-with-curly-hair-desktop.jpg';
import preferencesType3Mobile from 'assets/images/consultation/transitionScreens/prose-model-with-curly-hair-mobile.jpg';
import preferencesType3Tablet from 'assets/images/consultation/transitionScreens/prose-model-with-curly-hair-tablet.jpg';
import preferencesType1Desktop from 'assets/images/consultation/transitionScreens/prose-model-with-straight-hair-desktop.jpg';
import preferencesType1Mobile from 'assets/images/consultation/transitionScreens/prose-model-with-straight-hair-mobile.jpg';
import preferencesType1Tablet from 'assets/images/consultation/transitionScreens/prose-model-with-straight-hair-tablet.jpg';
import preferencesType4Desktop from 'assets/images/consultation/transitionScreens/prose-model-with-type-4-hair-desktop.jpg';
import preferencesType4Mobile from 'assets/images/consultation/transitionScreens/prose-model-with-type-4-hair-mobile.jpg';
import preferencesType4Tablet from 'assets/images/consultation/transitionScreens/prose-model-with-type-4-hair-tablet.jpg';
import preferencesType2Desktop from 'assets/images/consultation/transitionScreens/prose-model-with-wavy-hair-desktop.jpg';
import preferencesType2Mobile from 'assets/images/consultation/transitionScreens/prose-model-with-wavy-hair-mobile.jpg';
import preferencesType2Tablet from 'assets/images/consultation/transitionScreens/prose-model-with-wavy-hair-tablet.jpg';

const consultationBackgroundImages = {
  // Preferences background dynamic based on hair texture
  preferencesType1: {
    desktop: preferencesType1Desktop,
    tablet: preferencesType1Tablet,
    mobile: preferencesType1Mobile,
  },
  preferencesType2: {
    desktop: preferencesType2Desktop,
    tablet: preferencesType2Tablet,
    mobile: preferencesType2Mobile,
  },
  preferencesType3: {
    desktop: preferencesType3Desktop,
    tablet: preferencesType3Tablet,
    mobile: preferencesType3Mobile,
  },
  preferencesType4: {
    desktop: preferencesType4Desktop,
    tablet: preferencesType4Tablet,
    mobile: preferencesType4Mobile,
  },
};

const myPreferencesTransitionScreen = {
  name: 'intro-preferences',
  category: MY_PREFERENCES,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/haircare/${MY_PREFERENCES}/introduction`,
  title: 'Section 4',
  subtitle: 'How you like it',
  shouldBeIncluded: () => true,
  Component: consultationComponents.Transition,
  skipSave: true,
  body: 'Last step! Tell us your hair goals and formula preferences, and the custom formula creation will commence.',
  image: ({ hairTexture }) => {
    switch (hairTexture) {
      case hairTextures.ONE:
        return consultationBackgroundImages.preferencesType1;
      case hairTextures.WAVY:
        return consultationBackgroundImages.preferencesType2;
      case hairTextures.DEPRECATED_CURLY:
      case hairTextures.THREE_A:
      case hairTextures.THREE_B:
      case hairTextures.THREE_C:
        return consultationBackgroundImages.preferencesType3;
      case hairTextures.VERY_CURLY:
      case hairTextures.FRIZZY:
      case hairTextures.ZIGZAG:
        return consultationBackgroundImages.preferencesType4;
      default:
        return consultationBackgroundImages.preferencesType3;
    }
  },
};

export default myPreferencesTransitionScreen;
