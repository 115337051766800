import { productsLabels, productsSlugs } from 'constants/products';

import bgPictureBrushBoar from 'assets/images/productPages/accessories-collection-boar-brush.jpg';
import bgPictureBrushBoar1 from 'assets/images/productPages/accessories-collection-boar-brush-1.jpg';
import bgPictureBrushBoar2 from 'assets/images/productPages/accessories-collection-boar-brush-2.jpg';
import bgPictureBrushBoar3 from 'assets/images/productPages/accessories-collection-boar-brush-3.jpg';
import bgPictureBrushDetangling from 'assets/images/productPages/accessories-collection-detangling-brush.jpg';
import bgPictureBrushDetangling1 from 'assets/images/productPages/accessories-collection-detangling-brush-1.jpg';
import bgPictureBrushDetangling2 from 'assets/images/productPages/accessories-collection-detangling-brush-2.jpg';
import bgPictureBrushDetangling3 from 'assets/images/productPages/accessories-collection-detangling-brush-3.jpg';
import bgPictureBrushMixed from 'assets/images/productPages/accessories-collection-mixed-brush.jpg';
import bgPictureBrushMixed1 from 'assets/images/productPages/accessories-collection-mixed-brush-1.jpg';
import bgPictureBrushMixed2 from 'assets/images/productPages/accessories-collection-mixed-brush-2.jpg';
import bgPictureBrushMixed3 from 'assets/images/productPages/accessories-collection-mixed-brush-3.jpg';
import bgPictureBrushValuesDesktop from 'assets/images/productPages/brush_values-d.jpg';
import bgPictureBrushValuesMobile from 'assets/images/productPages/brush_values-m.jpg';
import makeItYoursDesktopImg from 'assets/images/productPages/brush-make-it-yours-d.jpg';
import makeItYoursMobileImg from 'assets/images/productPages/brush-make-it-yours-m.jpg';

const brushesPageContent = {
  heroModule: {
    dataFrom: 'accessories-pdp-cta',
    selectedVariant: variant => `Material: ${variant.replace(' Brush', '')}`,
    subText: 'Complimentary monogram available in cart',
    hasDynamicLabel: true,
    hasDynamicCarousel: true,
    imageCarousel: {
      [productsSlugs.BRUSH_MIXED]: [
        {
          image: bgPictureBrushMixed,
          alt: 'Mixed brush',
        },
        {
          image: bgPictureBrushMixed1,
          alt: 'Mixed brush',
        },
        {
          image: bgPictureBrushMixed2,
          alt: 'Mixed brush',
        },
        {
          image: bgPictureBrushMixed3,
          alt: 'Mixed brush',
        },
      ],
      [productsSlugs.BRUSH_BOAR]: [
        {
          image: bgPictureBrushBoar,
          alt: 'Boar brush',
        },
        {
          image: bgPictureBrushBoar1,
          alt: 'Mixed brush',
        },
        {
          image: bgPictureBrushBoar2,
          alt: 'Mixed brush',
        },
        {
          image: bgPictureBrushBoar3,
          alt: 'Mixed brush',
        },
      ],
      [productsSlugs.BRUSH_DETANGLING]: [
        {
          image: bgPictureBrushDetangling,
          alt: 'Detangling brush',
        },
        {
          image: bgPictureBrushDetangling1,
          alt: 'Detangling brush',
        },
        {
          image: bgPictureBrushDetangling2,
          alt: 'Detangling brush',
        },
        {
          image: bgPictureBrushDetangling3,
          alt: 'Detangling brush',
        },
      ],
    },
  },
  variantsHero: [
    {
      slug: productsSlugs.BRUSH_BOAR,
      title: productsLabels[productsSlugs.BRUSH_BOAR].replace(' Brush', ''),
      subtitle: 'fine to medium hair',
      descriptions: [
        { title: 'Good for', descriptions: ['Softness + shine'] },
        {
          title: 'How it works',
          descriptions: [
            '100% boar bristle helps distribute natural oils for maximum shine and softness.',
          ],
        },
      ],
      images: {
        mobile: bgPictureBrushBoar2,
        desktop: bgPictureBrushBoar2,
        alt: 'Someone brushing white hair with boar bristle',
      },
      wideScreenOrder: '21',
    },
    {
      slug: productsSlugs.BRUSH_MIXED,
      title: productsLabels[productsSlugs.BRUSH_MIXED].replace(' Brush', ' Bristle'),
      subtitle: 'Medium to thick hair',
      descriptions: [
        { title: 'Good for', descriptions: ['Smoothness + shine'] },
        {
          title: 'How it works',
          descriptions: [
            'Dual-length bristles stimulate the scalp, detangle ends, and redistribute oils.',
          ],
        },
      ],
      images: {
        mobile: bgPictureBrushMixed2,
        desktop: bgPictureBrushMixed2,
        alt: 'Someone brushing hair with boar and nylon bristle',
      },
      wideScreenOrder: '12',
    },
    {
      slug: productsSlugs.BRUSH_DETANGLING,
      title: productsLabels[productsSlugs.BRUSH_DETANGLING].replace(' Brush', ' Bristle'),
      subtitle: 'Thick or curly hair',
      descriptions: [
        { title: 'Good for', descriptions: ['Gentle detangling'] },
        {
          title: 'How it works',
          descriptions: [
            'Designed to detangle and add definition. Good for removing fragile knots.',
          ],
        },
      ],
      images: {
        mobile: bgPictureBrushDetangling2,
        desktop: bgPictureBrushDetangling2,
        alt: 'Someone brushing hair with detangling bristle',
      },
      wideScreenOrder: '21',
    },
  ],
  values: {
    header: 'crafted with care + sustainably made to last',
    background: {
      desktop: bgPictureBrushValuesDesktop,
      mobile: bgPictureBrushValuesMobile,
    },
    list: [
      {
        title: 'naturally sourced rubber',
        titleEl: (
          <span>
            naturally
            <br />
            sourced rubber
          </span>
        ),
        subtitleEl: (
          <span>
            Hevea rubber cushion for the perfect
            <br />
            amount of give
          </span>
        ),
      },
      {
        title: 'sustainable beechwood',
        titleEl: (
          <span>
            sustainable
            <br />
            beechwood
          </span>
        ),
        subtitleEl: (
          <span>
            Lightweight and hand-polished with
            <br />
            an elegant grip
          </span>
        ),
      },
      {
        title: 'Handcrafted by artisans',
        titleEl: (
          <span>
            Handcrafted
            <br />
            by artisans
          </span>
        ),
        subtitleEl: (
          <span>
            Made with gold-standard boar
            <br />
            bristles
          </span>
        ),
      },
    ],
  },
  ticker: ['Crafted By Hand'],
  makeItYours: {
    title: 'Make it yours',
    subTitle:
      'Handcrafted with time-honored tradition and made with luxury in mind. Find your perfect match, then make it your own — opt for complimentary monogrammed initials in your cart.',
    image: { desktop: makeItYoursDesktopImg, mobile: makeItYoursMobileImg },
  },
  faq: {
    title: 'Frequently asked questions',
    questions: [
      {
        question:
          "I'm not seeing a recomended option when I select my brush—how do I choose the right one?",
        answer: (
          <>
            Simply log in or complete your Haircare consultation and you’ll see a recommended Brush
            that best fits your hair needs. If you are purchasing a Brush as a gift, you can select
            whichever one suits the recipient’s needs. We have an article{' '}
            <a href="https://prose.com/blog/expert-hair-brushing-guide.php" target="_blank">
              here
            </a>{' '}
            to help guide you.
          </>
        ),
      },
      {
        question: 'Are special characters allowed for monogramming?',
        answer:
          'Prose brushes can only be monogrammed in letters A-Z. Please note that all initials will be in uppercase.',
      },
      {
        question: 'How will my brush arrive? ',
        answer:
          'Your brush will ship in a dedicated box, separate from other products purchased and without an invoice making it a perfect gift for yourself or for loved ones. ',
      },
      {
        question: 'How should I care for my Prose brush?',
        answer:
          'Each Prose brush will arrive with detailed use instructions and care tips. But in all cases, limit exposure to high heat as your brush is crafted to be a care product rather than a styling tool. Also, be sure to store your brush on its back in a dry area.',
      },
      {
        question: 'What is the Prose brush return policy?',
        answer: (
          <>
            Every Prose brush is covered by The Prose Promise™ — Love your first order (or it’s on
            us) within 30 days of the delivery date. If you received your brush as a gift, it can be
            returned for credit to your Prose account—rest assured the purchaser won’t be notified.
            <br />
            In addition, each brush is backed by a full warranty should you notice a rare material
            defect or workmanship defect within 30 days. For any issues that fall under the
            warranty, just contact our team{' '}
            <a href="https://prose.com/faq/contact-us" target="_blank">
              here
            </a>{' '}
            to coordinate a complimentary replacement. Please note that the warranty does not cover
            normal wear and tear, misuse, or alteration of brushes—so be sure to follow the care
            tips provided with your order.
          </>
        ),
      },
    ],
  },
};

export default brushesPageContent;
