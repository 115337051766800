import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

import densityLittleImageUrl from 'assets/images/consultation_density_little.png';
import densityLotImageUrl from 'assets/images/consultation_density_lot.png';
import densitySomeImageUrl from 'assets/images/consultation_density_some.png';

const myHairDensity = {
  name: 'density',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/density`,
  title: 'How dense is your hair?',
  subtitle:
    'Part your hair and check the mirror—the more scalp you see, the less dense your hair is.',
  tip: {
    title: 'Why we ask:',
    content: `Density (how many hairs you have) and thickness (how thick a single strand is) aren’t the same thing, but the two have an important correlation—we’ll use this info to help manage your volume the way you want it.`,
  },
  options: [
    {
      value: 4,
      label: 'Very dense',
      description: 'I see very little to no scalp',
      imageUrl: densityLittleImageUrl,
    },
    {
      value: 2,
      label: 'Medium or not sure',
      description: 'I can see a little skin',
      imageUrl: densitySomeImageUrl,
    },
    {
      value: 0,
      label: 'Low density',
      description: 'I have a wide part',
      imageUrl: densityLotImageUrl,
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_density_level,
  getAnswers: ({ selected }) => ({ diag_density_level: selected }),
};

export default myHairDensity;
