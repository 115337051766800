import { MY_LIFESTYLE } from '../constants';
import { LIFESTYLE } from '../constants/progressCategories';

const myLifestyleDiet = {
  name: 'diet',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/haircare/${MY_LIFESTYLE}/diet`,
  multi: true,
  title: 'What makes up the majority of your diet?',
  subtitle: 'Select all that apply',
  tip: {
    title: 'Why we ask:',
    content:
      "What you eat literally powers your hair growth and scalp balance, and skimping out on nutrients like Vitamin B8 (biotin) can lead to brittle, weak strands or an irritated scalp. Be honest about your daily dietary habits and we'll supplement the gaps, personnalizating your formulas depending on your needs.",
  },
  options: [
    { value: `meat`, label: `Meat` },
    { value: `fish_seafood`, label: `Fish / Seafood` },
    { value: `vegetable`, label: `Vegetables` },
    { value: `fruit`, label: `Fruits` },
    { value: `dairy`, label: `Dairy` },
    { value: `processed_or_fast_food`, label: `Processed or fast food` },
    { value: `carb_starch`, label: `Carbs / Starches` },
    { value: `sweet`, label: `Sweets` },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_diet_composition,
  getAnswers: ({ selected }) => ({ profile_diet_composition: selected }),
};

export default myLifestyleDiet;
