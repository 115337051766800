import { Box, Text, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import Image from 'next/image';

import Button from 'Components/LegacyButton';

import bCorpIcon from 'assets/images/homeRevamp/b-corp-logo.png';
import climateNeutralIcon from 'assets/images/homeRevamp/climate-neutral-certified-logo.png';

const StyledButton = styled(Button)`
  color: ${theme.colors.neutral[100]};
  :hover {
    color: ${theme.colors.neutral[100]};
  }
  :visited {
    color: ${theme.colors.neutral[100]};
  }
`;

export const Sustainability = () => (
  <Box
    backgroundColor="primary.300"
    borderRadius="xs"
    display="flex"
    flexDirection={{ mobile: 'column', desktop: 'row' }}
    gap={{ mobile: '15x' }}
    justifyContent={{ desktop: 'space-between' }}
    paddingX={{
      desktop: '10x',
    }}
    paddingY={{ mobile: '8x', desktop: '12x' }}
  >
    <Box
      alignItems={{ mobile: 'center', desktop: 'flex-start' }}
      display="flex"
      flexDirection="column"
      gap="3x"
    >
      <Text as="h2" color="neutral.100" variant="heading5">
        Always made to order.
        <br /> Never made to waste.
      </Text>
      {/* @ts-expect-error Button not migrated to ts */}
      <StyledButton
        Component="a"
        data-click="sustainability"
        data-from="homepage-sustainability-module"
        href="/sustainability-and-social-impact"
        noMargin
        target="_blank"
        variant="underlined"
      >
        Learn more
      </StyledButton>
    </Box>

    <Box display="flex" gap="5x" justifyContent="center">
      <Image alt="Prose is a Certified B Corporation" height={116} src={bCorpIcon} width={77} />
      <Image
        alt="Prose is Climate Neutral Certified"
        height={112}
        src={climateNeutralIcon}
        width={80}
      />
    </Box>
  </Box>
);
