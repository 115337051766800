import { createSelector } from '@reduxjs/toolkit';

import {
  productsCategories,
  productsLabels,
  productsSlugs,
  productsSubCategories,
  supplementsVariantsSlugs,
} from 'constants/products';

import { getCartItemsWithoutCoupons, getItems as getCartItems } from 'dux/checkoutCart/selectors';
import { getOrderItems } from 'dux/checkoutOrder/selectors';
import { getGiftContent } from 'dux/gift/selectors';

// The data analysis team needs specific namings for some products
const productsDataTrackingMapping = {
  // Brushes
  [productsSlugs.BRUSH_BOAR]: 'Boar Bristle Brush',
  [productsSlugs.BRUSH_MIXED]: 'Boar & Nylon Bristle Brush',
  [productsSlugs.BRUSH_DETANGLING]: 'Tipped-Metal Bristle Brush',
  // Gifts
  [productsSlugs.ESSENTIALS_SET]: 'The Essentials',
  [productsSlugs.COMPLETE_SET]: 'The Complete Set',
  // Supplements
  [productsSlugs.SUPPLEMENT_CORE]: 'Supplements',
  [supplementsVariantsSlugs.SUPPLEMENT_0001]: 'Supplements',
  [supplementsVariantsSlugs.SUPPLEMENT_0002]: 'Supplements',
  [supplementsVariantsSlugs.SUPPLEMENT_0003]: 'Supplements',
  [supplementsVariantsSlugs.SUPPLEMENT_0004]: 'Supplements',
  [supplementsVariantsSlugs.SUPPLEMENT_0006]: 'Supplements',
  [supplementsVariantsSlugs.SUPPLEMENT_POUCH_0001]: 'Supplements Pouch',
  [supplementsVariantsSlugs.SUPPLEMENT_POUCH_0002]: 'Supplements Pouch',
  [supplementsVariantsSlugs.SUPPLEMENT_POUCH_0003]: 'Supplements Pouch',
  [supplementsVariantsSlugs.SUPPLEMENT_POUCH_0004]: 'Supplements Pouch',
  [supplementsVariantsSlugs.SUPPLEMENT_POUCH_0006]: 'Supplements Pouch',
  [productsSlugs.CLEANSER_MINI]: 'Cleanser Starter Set',
  [productsSlugs.MOISTURIZER_MINI]: 'Moisturizer Starter Set',
  [productsSlugs.SERUM_MINI]: 'Serum Starter Set',
};

/**
 * @Tiago:
 * Enhance with subscription data from cart
 * Note: this might be simplified if subscription information were available on the order items
 */
const getOrderItemsWithSubscription = createSelector(
  getOrderItems,
  getCartItems,
  (items, cartItems) =>
    items.map(item => {
      const correspondingCartItem = cartItems.find(
        cartItem => cartItem.type === item.variant?.product?.type
      );
      return {
        ...item,
        sku: correspondingCartItem?.sku,
        subscription: correspondingCartItem?.subscription?.active || false,
        subscriptionFrequency: correspondingCartItem?.subscription?.frequency,
      };
    })
);

// @Tiago: Get all order items that are of type formula (exclude coupons)
const getOrderFormulaItems = createSelector(getOrderItemsWithSubscription, items =>
  items.filter(
    item => item.variant?.is_formula || item.variant?.product?.type === productsSlugs.BRUSH
  )
);

const getCartGiftset = createSelector(getCartItems, items =>
  items.filter(item => item.type === productsSlugs.DIGITAL_GIFT)
);

// Get gift (as a gifter)
const getOrderGiftset = createSelector(getOrderItems, items =>
  items.filter(item => item.variant?.product?.type === productsSlugs.DIGITAL_GIFT)
);

// --------------------- GTM ------------------------

// Data available from the /checkout until the /checkout/confirmation page
export const getGtmCartItems = createSelector(
  getCartItemsWithoutCoupons,
  getCartGiftset,
  (items, giftset) => {
    const nameMap = {
      ...productsLabels,
      ...productsDataTrackingMapping,
    };

    const formulaProducts = items.map(item => ({
      item_brand: 'Prose',
      item_category: undefined,
      item_category2: undefined,
      item_id: item.sku ? item.sku : item.pubkey,
      item_name: nameMap[item.type] || item.label,
      price: item.total_price,
      quantity: item.quantity,
      subscription: item.subscription?.active,
    }));

    /**
     * For now, gifts can only be the 3 following haircare products:
     * - shampoo
     * - conditioner
     * - hair_mask
     */
    const giftsetProducts = giftset.map(item => ({
      item_brand: 'Prose',
      item_category: productsCategories.HAIRCARE,
      item_category2: productsSubCategories.TOPICALS,
      item_id: item.pubkey,
      item_name: nameMap[item.type] || item.label,
      price: item.total_price,
      quantity: item.quantity,
    }));

    return [...formulaProducts, ...giftsetProducts];
  }
);

// Data available on the /checkout/success page
export const getGtmProducts = createSelector(
  getOrderFormulaItems,
  getOrderGiftset,
  getGiftContent,
  (formulaItems, giftset, giftContent) => {
    // Keep nomenclature from old OMS to prevent analytics discrepancies
    const nameMap = {
      ...productsLabels,
      ...productsDataTrackingMapping,
    };

    const formulaProducts = formulaItems.map(item => ({
      brand: 'Prose',
      SKU: item.sku ? item.sku : item.pubkey,
      category: item.item_object?.gtm_category,
      name: nameMap[item.variant?.type] || nameMap[item.variant?.product?.type] || item.type,
      price: item.total_price,
      priceLessTax: item.total_price,
      quantity: item.quantity,
      variant: item.item_object?.gtm_variant,
      subscription: item.subscription,
      subscriptionFrequency: item.subscriptionFrequency,
    }));

    const giftsetProducts = giftset.map(item => ({
      brand: 'Prose',
      SKU: item.pubkey,
      category: giftContent.form.occasion.find(o => o.value === item.item_object.occasion).label,
      name: nameMap[item.variant?.type] || item.variant?.label,
      price: item.total_price,
      priceLessTax: item.total_price,
      quantity: item.quantity,
      variant: 'Gift Set',
    }));

    return [...formulaProducts, ...giftsetProducts];
  }
);

// Data available on the /checkout/success page
export const getGtmOrderItems = createSelector(
  getOrderItemsWithSubscription,
  getOrderGiftset,
  (items, giftset) => {
    const nameMap = {
      ...productsLabels,
      ...productsDataTrackingMapping,
    };

    const formulaProducts = items.map(item => {
      let category = item.variant?.product?.category;
      let subCategory = item.variant?.product?.sub_category;
      /**
       * Temporary mapping for accessories until API update its namings
       * The API considers accessories as "others"
       * But we need more granularity for data tracking
       */
      if (item.variant?.product?.category === productsCategories.OTHERS) {
        category = productsCategories.ACCESSORIES;
        subCategory = productsSubCategories.LIFESTYLE;
      }

      return {
        item_brand: 'Prose',
        item_category: category,
        item_category2: subCategory,
        item_id: item.sku ? item.sku : item.pubkey,
        item_name: nameMap[item.variant?.type] || nameMap[item.variant?.product?.type] || item.type,
        price: item.total_price,
        quantity: item.quantity,
        subscription: item.subscription,
      };
    });

    /**
     * For now, gifts can only be the 3 following haircare products:
     * - shampoo
     * - conditioner
     * - hair_mask
     */
    const giftsetProducts = giftset.map(item => ({
      item_brand: 'Prose',
      item_category: productsCategories.HAIRCARE,
      item_category2: productsSubCategories.TOPICALS,
      item_id: item.pubkey,
      item_name: nameMap[item.variant?.type] || item.variant?.label,
      price: item.total_price,
      quantity: item.quantity,
    }));

    return [...formulaProducts, ...giftsetProducts];
  }
);

// --------------------------------------------------
