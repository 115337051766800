import * as hairTextures from 'constants/hairTextures';
import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const holdLevel = {
  name: 'hold-level',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/hold-level`,
  title: 'What level of hold do you prefer when styling?',
  subtitle:
    'Use different levels of hold? Select the one you use the most and we’ll curate your routine to offer options based on the styles you wear.',
  body: '*These product features are specific to our Styling Gel, which is always formulated for flexible, soft-touch styles and no crunching.',
  tip: {
    title: 'Why we ask ?',
    content:
      'A styling product’s hold level is its strength in keeping a hairstyle in place. Hold can also be adapted to your preferences by adjusting the amount of product used. However, this tells us how much movement you expect from your styles and how long you want your look to last. ',
  },
  options: [
    {
      value: 'none',
      label: 'None',
      description: 'I don’t need hold!',
    },
    {
      value: 'light',
      label: 'Light',
      description: 'For minimal hold',
    },
    {
      value: 'medium',
      label: 'Medium*',
      description: 'Next-level hold',
    },
    {
      value: 'strong',
      label: 'Strong*',
      description: 'Our maximal hold level',
    },
    {
      value: 'not_sure',
      label: 'Not Sure',
      description:
        'We’ll give suggestions based on your hair needs, styling habits and your environment',
    },
  ],
  shouldBeIncluded: ({ answers }) =>
    answers?.diag_hair_texture !== hairTextures.ONE && answers?.diag_extensions !== 'none',
  getInitialValue: answers => answers.diag_hold_level,
  getAnswers: ({ selected }) => ({ diag_hold_level: selected }),
};

export default holdLevel;
