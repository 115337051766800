import { MY_LIFESTYLE } from '../../constants';
import { Transition } from '../../constants/consultationComponents';
import { LIFESTYLE } from '../../constants/progressCategories';

import lifestyleDesktop from 'assets/images/consultation/transitionScreens/d-lifestyle_skin.jpg';
import lifestyleMobile from 'assets/images/consultation/transitionScreens/m-lifestyle_skin.jpg';
import lifestyleTablet from 'assets/images/consultation/transitionScreens/t-lifestyle_skin.jpg';

const consultationBackgroundImages = {
  mobile: lifestyleMobile,
  tablet: lifestyleTablet,
  desktop: lifestyleDesktop,
};

const myLifestyleTransitionScreen = {
  name: 'intro-lifestyle',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/introduction`,
  title: 'Section 1',
  subtitle: 'How you live',
  body: 'Your lifestyle affects your skin and lets us know what kind of care it needs from season to season.',
  Component: Transition,
  skipSave: true,
  shouldBeIncluded: () => true,
  image: () => consultationBackgroundImages,
  public: true,
};

export default myLifestyleTransitionScreen;
