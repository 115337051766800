import { MY_LIFESTYLE } from '../../constants';
import * as consultationComponents from '../../constants/consultationComponents';
import { LIFESTYLE } from '../../constants/progressCategories';

const geoAggressors = {
  name: 'geo-aggressors',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  title: 'Here’s what affects your hair in __city__.',
  subtitle:
    'We’ll factor in these environmental elements (or geo-aggressors) into your custom formula, so even the weather can’t ruin your hair or skin.',
  shouldBeIncluded: () => true,
  Component: consultationComponents.GeoAggressorsContainer,
  skipSave: true, // The component does not need to save any date, we will just redirect
};

export default geoAggressors;
