import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const profileBackground = {
  name: 'profile-background',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/profile-background`,
  title: 'Has your skin ever reacted negatively to a skincare or makeup product?',
  subtitle: 'Choose what best reflects your skin’s typical reaction.',
  tip: {
    title: 'why we ask:',
    content:
      'This helps us assess your skin’s reactivity to cosmetics. Reactivity is different from sensitivity: Sensitivity is a general behavior, whereas reactivity is a response to a trigger. Learning which reactions you may be prone to helps us tailor your formula to you.',
  },
  options: [
    {
      value: 'warm_flush',
      label: 'Yes, a warm flush or redness',
    },
    {
      value: 'itching',
      label: 'Yes, tightness, itching or discomfort',
    },
    {
      value: 'breakouts',
      label: 'Yes, breakouts',
    },
    {
      value: 'none',
      label: "Not that I've noticed",
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_background,
  getAnswers: ({ selected }) => ({
    profile_background: selected,
  }),
};

export default profileBackground;
