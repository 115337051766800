import { MY_LIFESTYLE } from '../../constants';
import { HAIRCARE, SKINCARE } from '../../constants/consultationProductsCategories';
import { LIFESTYLE } from '../../constants/progressCategories';

import { ReactComponent as GlassIcon } from 'assets/images/skincare/glassIcon.svg';

const hydration = {
  name: 'hydration',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  // this route is defined at question set level
  // route: '',
  title: 'How much water do you drink a day? ',
  subtitle: 'Choose what best reflects your typical intake.',
  icon: <GlassIcon />,
  tip: {
    title: 'fun fact:',
    content: {
      [HAIRCARE]: `Fun fact: Hair is 10% water! Water is found in every layer of the hair's structure, and is essential for its strength, elasticity and damage-resistance, as well as scalp health. If you're reading this, refill your glass.`,
      [SKINCARE]: `Water makes up about 64% of our skin, and is key to maintaining elasticity and reinforcing your skin's moisture barrier. It’s easy to remember to drink water when it’s warm out, but the risk of dehydration is the same when the weather is cold. 

&nbsp;

So consider this a friendly reminder to refill your glass — you’ll thank us later.
`,
    },
  },
  options: [
    {
      value: 'less_than_3_glasses',
      label: 'Less than 3 glasses (24 oz)',
    },
    {
      value: '4_6_glasses',
      label: '4 - 6 glasses (up to 48 oz)',
    },
    {
      value: '7_8_glasses',
      label: '7 - 8 glasses (up to 64 oz)',
    },
    {
      value: 'more_than_8_glasses',
      label: 'Even more',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_hydration,
  getAnswers: ({ selected }) => ({ profile_hydration: selected }),
};

export default hydration;
