import PropTypes from 'prop-types';

import { legacyTheme, styled } from '@prose-ui/legacy';

import Typography from 'Components/Typography';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

import useResponsiveVariant from 'utils/useResponsiveVariant';

const SustainabilityWrapper = styled.div`
  background-color: ${legacyTheme.palette.common.beige.darker};
  min-height: 529px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 48px 38px;

  ${legacyTheme.breakpoints.match('md')} {
    min-height: 0px;
  }
`;

const SustainabilityDescription = styled(Typography)`
  margin: 28px 0px;
  max-width: 900px;
`;

const LogosContainer = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: 98px;
`;

const SustainanilityWithoutImage = ({ title, description, logos }) => {
  const { isDesktop } = useResponsiveVariant();
  return (
    <SustainabilityWrapper>
      <Typography variant="mono1">{title}</Typography>
      <SustainabilityDescription variant={isDesktop ? 'h2' : 'h3'}>
        {description}
      </SustainabilityDescription>
      <LogosContainer>
        <Logo alt="logo-bcorp" src={logos.bCorp.src} />
        <Logo alt="logo-climate" src={logos.climate.src} />
      </LogosContainer>
    </SustainabilityWrapper>
  );
};

SustainanilityWithoutImage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logos: PropTypes.objectOf(srcOrNextImageProp).isRequired,
};

export default SustainanilityWithoutImage;
