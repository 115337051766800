import { productsLabels, productsSlugs } from 'constants/products';

import bgPictureCandleArcadia from 'assets/images/productPages/accessories-collection-candle_arcadia.jpg';
import bgPictureCandleCorsica from 'assets/images/productPages/accessories-collection-candle_corsica.jpg';
import bgPictureCandlePrelude from 'assets/images/productPages/accessories-collection-candle_prelude.jpg';
import bgPictureHairTowel from 'assets/images/productPages/accessories-collection-hair_towel.jpg';
import upsellBoarBrush from 'assets/images/productPages/upsell-boar-brush.jpg';
import upsellDetanglingBrush from 'assets/images/productPages/upsell-detangling-brush.jpg';
import upsellMixedBrush from 'assets/images/productPages/upsell-mixed-brush.jpg';
import candleArcadiaPicture from 'assets/images/products/prose-arcadia-candle.png';
import boarImg from 'assets/images/products/prose-boar-brush.png';
import mixedImg from 'assets/images/products/prose-boar-nylon-brush.png';
import candleCorsicaPicture from 'assets/images/products/prose-corsica-candle.png';
import hairTowelPicture from 'assets/images/products/prose-hair-towel.png';
import detanglingImg from 'assets/images/products/prose-metal-tipped-brush.png';
import candlePreludePicture from 'assets/images/products/prose-prelude-candle.png';

import { CANDLE_ARCADIA, CANDLE_CORSICA, CANDLE_PRELUDE } from '../accessoriesProducts';

const catalog = {
  [CANDLE_ARCADIA]: {
    label: 'Arcadia Candle',
    description: '8.6 fl oz',
    picture: candleArcadiaPicture,
    upsale: {
      label: 'Arcadia Candle',
      underLabel: '8.6 fl oz',
      description: 'Up to 50 hours burn time',
      picture: bgPictureCandleArcadia,
      altPicture: 'Arcadia candle with number 3 on it.',
    },
  },
  [CANDLE_PRELUDE]: {
    label: 'Prelude Candle',
    description: '8.6 fl oz',
    picture: candlePreludePicture,
    upsale: {
      label: 'Prelude Candle',
      underLabel: '8.6 fl oz',
      description: 'Up to 50 hours burn time',
      picture: bgPictureCandlePrelude,
      altPicture: 'Prelude candle with number 2 on it.',
    },
  },
  [CANDLE_CORSICA]: {
    label: 'Corsica Candle',
    description: '8.6 fl oz',
    picture: candleCorsicaPicture,
    upsale: {
      label: 'Corsica Candle',
      underLabel: '8.6 fl oz',
      description: 'Up to 50 hours burn time',
      picture: bgPictureCandleCorsica,
      altPicture: 'Corsica candle with number 1 on it.',
    },
  },
  [productsSlugs.BRUSH_MIXED]: {
    label: productsLabels[productsSlugs.BRUSH_MIXED],
    description: 'medium to thick hair',
    picture: mixedImg,
    upsale: {
      label: productsLabels[productsSlugs.BRUSH_MIXED],
      underLabel: 'Monogram available',
      description: 'medium to thick hair',
      picture: upsellMixedBrush,
      altPicture: 'A wooden hair brush with metal pins and boar hair.',
    },
    hasMonogram: true,
  },
  [productsSlugs.BRUSH_BOAR]: {
    label: productsLabels[productsSlugs.BRUSH_BOAR],
    description: 'fine to medium hair',
    picture: boarImg,
    upsale: {
      label: productsLabels[productsSlugs.BRUSH_BOAR],
      underLabel: 'Monogram available',
      description: 'fine to medium hair',
      picture: upsellBoarBrush,
      altPicture: 'A wooden hair brush with metal pins.',
    },
    hasMonogram: true,
  },
  [productsSlugs.BRUSH_DETANGLING]: {
    label: productsLabels[productsSlugs.BRUSH_DETANGLING],
    description: 'thick or curly hair',
    picture: detanglingImg,
    upsale: {
      label: productsLabels[productsSlugs.BRUSH_DETANGLING],
      underLabel: 'Monogram available',
      description: 'thick or curly hair',
      picture: upsellDetanglingBrush,
      altPicture: 'A wooden hair brush with boar hair.',
    },
    hasMonogram: true,
  },
  [productsSlugs.HAIR_TOWEL_WRAP]: {
    label: 'Hair Towel Wrap',
    description: '23” x 37” in',
    picture: hairTowelPicture,
    upsale: {
      label: 'Hair Towel Wrap',
      underLabel: '23” x 37” in',
      description: 'For all textures & lengths',
      picture: bgPictureHairTowel,
      altPicture: 'Green hair towel wrap on a table.',
    },
  },
};

export default catalog;
