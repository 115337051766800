import * as hairTextures from 'constants/hairTextures';
import { MY_GOALS } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

// goals filters helpers
const elligibleToShowCurl = [
  hairTextures.WAVY,
  hairTextures.TWO_A,
  hairTextures.TWO_B,
  hairTextures.TWO_C,
  hairTextures.DEPRECATED_CURLY,
  hairTextures.THREE_A,
  hairTextures.THREE_B,
  hairTextures.THREE_C,
  hairTextures.VERY_CURLY,
  hairTextures.FRIZZY,
  hairTextures.ZIGZAG,
];

const elligibleToShowMoisture = [
  hairTextures.DEPRECATED_CURLY,
  hairTextures.THREE_A,
  hairTextures.THREE_B,
  hairTextures.THREE_C,
  hairTextures.VERY_CURLY,
  hairTextures.FRIZZY,
  hairTextures.ZIGZAG,
];

const myGoals = {
  name: 'goals',
  category: MY_GOALS,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/haircare/${MY_GOALS}/goals`,
  multi: true,
  title: 'What are your hair goals?',
  subtitle: 'Select all that apply',
  tip: {
    title: 'Not sure? Tap for a tip',
    // Order matters
    content: [
      {
        value: 'volume',
        content: `**More volume**

If hair feels limp or flat

&nbsp;

`,
      },
      {
        value: 'shine',
        content: `**More shine**

If hair looks dull or one-dimensional

&nbsp;

`,
      },
      {
        value: 'straight',
        content: `**More smoothness**

To fight frizz and flyaways

&nbsp;

`,
      },
      {
        value: 'curl',
        content: `**More curl definition**

If you want your curls to hold their shape better

&nbsp;

`,
      },
      {
        value: 'moisture',
        content: `**More moisture**

If hair feels dry, thirsty, or brittle

&nbsp;

`,
      },
      {
        value: 'antiBrass',
        content: `**Less brass**

To neutralize warm tones on blonde, white, or grey hair

&nbsp;

`,
      },
      {
        value: 'hairShedding',
        content: `**Less hair shedding**

If you notice you are shedding more hair than usual

&nbsp;

`,
      },
      {
        value: 'hairGrowth',
        content: `**More hair growth**

If you feel your hair is thining or prone to breakage
    `,
      },
    ],
  },
  options: [
    { value: 'volume', label: 'More Volume' },
    { value: 'shine', label: 'More Shine' },
    { value: 'straight', label: 'More Smoothness' },
    { value: 'curl', label: 'More Curl Definition' },
    { value: 'moisture', label: 'More Moisture' },
    { value: 'antiBrass', label: 'Less Brass*' },
    { value: 'hairGrowth', label: 'More hair growth †' },
    { value: 'hairShedding', label: 'Less shedding †' },
  ],
  shouldBeIncluded: () => true,
  optionsFilter: ({ answers }) => ({
    volume: true,
    shine: true,
    straight: true,
    curl:
      // See ch13966 for this one
      answers.goal_curl === 3 || elligibleToShowCurl.includes(answers?.diag_hair_texture),
    moisture:
      // See ch13966 for this one
      answers.goal_moisture === 3 || elligibleToShowMoisture.includes(answers?.diag_hair_texture),
    antiBrass:
      // See ch13966 for this one
      answers.goal_anti_brass === 3 ||
      (answers.diag_color_treatments === true &&
        answers.diag_color_treatments_type?.some(treatment => treatment === 'bleach')) ||
      ['full', 'half'].includes(answers?.diag_gray_hair),
    hairGrowth: true,
    hairShedding: true,
  }),
  getInitialValue: answers => ({
    curl: answers.goal_curl === 3,
    shine: answers.goal_shine === 3,
    straight: answers.goal_straight === 3,
    volume: answers.goal_volume === 3,
    moisture: answers.goal_moisture === 3,
    antiBrass: answers.goal_anti_brass === 3,
    hairGrowth: answers.goal_hair_growth === 3,
    hairShedding: answers.goal_hair_shedding === 3,
  }),
  getAnswers: ({ selected }) => ({
    goal_curl: selected.curl ? 3 : 0,
    goal_shine: selected.shine ? 3 : 0,
    goal_straight: selected.straight ? 3 : 0,
    goal_volume: selected.volume ? 3 : 0,
    goal_moisture: selected.moisture ? 3 : 0,
    goal_anti_brass: selected.antiBrass ? 3 : 0,
    goal_hair_growth: selected.hairGrowth ? 3 : 0,
    goal_hair_shedding: selected.hairShedding ? 3 : 0,
  }),
  Component: consultationComponents.Goals,
};

export default myGoals;
