import { styled } from '@prose-ui/legacy';

import { HybridLink } from 'Components/HybridLink';

const A11YLinks = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;

  a {
    position: absolute;

    display: block;

    width: 0;
    height: 0;

    text-indent: -1000000px;

    &:focus {
      z-index: 1101;
      top: 0;

      width: auto;
      height: auto;
      padding: 0 8px;

      line-height: 32px;
      color: black;
      text-indent: 0;

      background-color: white;
    }
  }
`;

type SkipLinkA11yProps = {
  nextMode?: boolean;
  showFooterLink: boolean;
};

export const SkipLinkA11y = ({ nextMode = false, showFooterLink = false }: SkipLinkA11yProps) => (
  <A11YLinks>
    <li>
      <a href="#maincontent">Skip to main content</a>
    </li>
    {showFooterLink && (
      <li>
        <a href="#footer">Skip to footer</a>
      </li>
    )}
    <li>
      <HybridLink nextMode={nextMode} to="/accessibility">
        Page
      </HybridLink>
    </li>
  </A11YLinks>
);
