import CA from 'assets/images/countriesFlag/CA.png';
import US from 'assets/images/countriesFlag/US.png';

export const countriesCode = /** @type {const} */ {
  CA: 'CA',
  US: 'US',
  GB: 'GB',
} as const;

export const availableCountries = [countriesCode.US, countriesCode.CA] as const;

export const stateLabelByCountries = {
  [countriesCode.US]: 'State',
  [countriesCode.CA]: 'Province',
} as const;

export const countriesFlags = {
  US,
  CA,
} as const;

const canadianPhoneNumberAreaCode = [
  204, 226, 236, 249, 250, 289, 306, 343, 365, 403, 416, 418, 431, 437, 438, 450, 506, 514, 519,
  579, 581, 587, 604, 613, 639, 647, 705, 709, 778, 780, 807, 819, 867, 873, 902, 905,
] as const;

const getFirstThreeDigits = (phoneNumber: string) =>
  parseInt(phoneNumber?.replace(/\(|\)/gm, '').substring(0, 3), 10);

export const getCountryFromPhoneNumber = (phoneNumber: string) => {
  if (canadianPhoneNumberAreaCode.includes(getFirstThreeDigits(phoneNumber))) {
    return countriesCode.CA;
  }
  return countriesCode.US;
};

const phoneCodeByCountryCode = {
  [countriesCode.CA]: '+1',
  [countriesCode.US]: '+1',
} as const;

export const formatPhoneForTracking = (phoneNumber: string) => {
  const country = getCountryFromPhoneNumber(phoneNumber);
  const phoneCode = phoneCodeByCountryCode[country];
  return `${phoneCode}${phoneNumber.replace(/[() -]/g, '')}`;
};
