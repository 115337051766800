export const ARCADIA = 'ARCADIA';
export const AURA = 'AURA';
export const BOTANICA = 'BOTANICA';
export const CANDELA = 'CANDELA';
export const CORSICA = 'CORSICA';
export const ELIXIR = 'ELIXIR';
export const FETE = 'FETE';
export const FRAGRANCE_FREE = 'FRAGRANCE_FREE';
export const FRUITISSIME = 'FRUITISSIME';
export const ISPAHAN = 'ISPAHAN';
export const MELENI = 'MELENI';
export const NAPOLI = 'NAPOLI';
export const NO_ADDED_FRAGRANCE = 'NO_ADDED_FRAGRANCE';
export const NONE = 'NONE';
export const OASIS = 'OASIS';
export const PALMA = 'PALMA';
export const PERLE = 'PERLE';
export const PRELUDE = 'PRELUDE';
export const RITUEL = 'RITUEL';
export const SIGNATURE = 'SIGNATURE';
export const VITAE = 'VITAE';
export const PARADISO = 'PARADISO';
export const SOLSTICE = 'SOLSTICE';
export const GLACIA = 'GLACIA';
