// code originally from https://www.joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion/

import { useEffect, useState } from 'react';

const QUERY = '(prefers-reduced-motion: no-preference)';

const isSSR = typeof window === 'undefined';

const mediaQueryList = isSSR ? undefined : window.matchMedia(QUERY);

const getInitialState = () => {
  // For our initial server render, we won't know if the user
  // prefers reduced motion, but it doesn't matter. This value
  // will be overwritten on the client, before any animations
  // occur.
  return isSSR ? true : !window.matchMedia(QUERY).matches;
};

function usePrefersReducedMotion() {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(getInitialState);

  useEffect(() => {
    const listener = event => {
      setPrefersReducedMotion(!event.matches);
    };
    mediaQueryList?.addEventListener('change', listener);

    return () => {
      mediaQueryList?.removeEventListener('change', listener);
    };
  }, []);

  if (isSSR) return true;

  return prefersReducedMotion;
}

export default usePrefersReducedMotion;
