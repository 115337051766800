import { MY_PREFERENCES } from '../../constants';
import { Transition } from '../../constants/consultationComponents';
import { PREFERENCES_GOALS } from '../../constants/progressCategories';

import preferencesDesktop from 'assets/images/consultation/transitionScreens/d-preferences_skin.jpg';
import preferencesMobile from 'assets/images/consultation/transitionScreens/m-preferences_skin.png';
import preferencesTablet from 'assets/images/consultation/transitionScreens/t-preferences_skin.jpg';

const consultationBackgroundImages = {
  mobile: preferencesMobile,
  tablet: preferencesTablet,
  desktop: preferencesDesktop,
};

const myPreferencesTransitionScreen = {
  name: 'intro-preferences',
  category: MY_PREFERENCES,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/skincare/${MY_PREFERENCES}/introduction`,
  title: 'Section 3',
  subtitle: 'How you like it',
  body: "Let us know your skin goals and we'll recommend products for your unique skin. If you prefer something else, we can tailor your routine to your liking.",
  Component: Transition,
  skipSave: true,
  shouldBeIncluded: () => true,
  image: () => consultationBackgroundImages,
  public: true,
};

export default myPreferencesTransitionScreen;
