import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsColorTreatments = {
  name: 'color',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/color`,
  title: 'Is your hair color-treated?',
  subtitle:
    'If any part of your hair has been lightened or darkened from your natural color, answer yes.',
  tip: {
    title: 'Fun fact no. 99',
    content:
      'Half of the women in the United States color their hair regularly, and two-thirds have tried it at least once. So either way, you’re definitely not alone.',
  },
  options: [
    { value: true, label: 'Yes', description: 'My hair is colored or lightened' },
    { value: false, label: 'No', description: 'Currently my hair color is 100% natural' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_color_treatments,
  getAnswers: ({ selected }) => ({
    diag_color_treatments: selected,
  }),
};

export default myTreatmentsColorTreatments;
