import type { Simplify } from 'type-fest';
import { isKey } from 'types/predicates';

import type { PublicEnv } from './initPublicEnvs';
import logSentryError from './logSentry';

type PublicEnvKey = keyof PublicEnv;
type ServerEnvKey = Simplify<keyof ServerEnv | 'NODE_ENV'>;
type EnvKey = PublicEnvKey | ServerEnvKey;
/**
 * Return an env value arccording to the environment detect (server or browser).
 * @param {string} envKey name of the env to retrieve
 *
 */
const getEnv = <K extends EnvKey>(envKey: K) => {
  if (typeof window !== 'undefined') {
    if (isKey(window.PROSE_ENV, envKey)) {
      return window.PROSE_ENV[envKey];
    }
    return undefined;
  }
  return process.env?.[envKey];
};

/**
 * Same as getEnv except it throws if the value is undefined
 */
export const getEnvOrThrow = <K extends EnvKey>(envKey: K) => {
  let err;
  if (typeof window !== 'undefined') {
    if (isKey(window.PROSE_ENV, envKey)) {
      const value = window.PROSE_ENV[envKey];

      if (value !== undefined) {
        return value;
      }

      err = new Error(`Missing ${envKey} value in window.PROSE_ENV`);
    } else {
      err = new Error(`Missing ${envKey} key in window.PROSE_ENV`);
    }

    logSentryError('[getEnvOrThrow]', err);
    throw err;
  }

  if (isKey(process.env, envKey)) {
    const value = process.env[envKey];
    if (value !== undefined) {
      return value;
    }
    err = new Error(`Missing ${envKey} value in process.env`);
  } else {
    err = new Error(`Missing ${envKey} key in process.env`);
  }

  logSentryError('[getEnvOrThrow]', err);
  throw err;
};

export default getEnv;
