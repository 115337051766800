import PropTypes from 'prop-types';

import { legacyTheme, styled } from '@prose-ui/legacy';

import AutoPlayVideo from 'Components/AutoPlayVideo';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${legacyTheme.palette.common.white};
  width: 100%;
  > video {
    object-fit: cover;
    width: 100%;
    height: 375px;
  }
  ${legacyTheme.breakpoints.up('sm')} {
    flex-direction: row;
    > video {
      flex: 1;
      width: 0;
      height: unset;
      min-height: 600px;
      max-height: calc(100vh - ${legacyTheme.props.headerHeight});
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const TextVideo = ({ className, children, video, fallbackImage }) => {
  return (
    <Container>
      <AutoPlayVideo
        desktopImage={fallbackImage?.src?.desktop}
        desktopVideo={video?.desktop}
        mobileImage={fallbackImage?.src?.mobile}
        mobileVideo={video?.mobile}
      />
      <Wrapper className={className}>{children}</Wrapper>
    </Container>
  );
};

TextVideo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  video: PropTypes.shape({
    mobile: PropTypes.string.isRequired,
    desktop: PropTypes.string.isRequired,
  }).isRequired,
  fallbackImage: PropTypes.shape({
    src: PropTypes.shape({
      mobile: srcOrNextImageProp,
      desktop: srcOrNextImageProp,
    }),
    alt: PropTypes.string.isRequired,
  }).isRequired,
};
TextVideo.defaultProps = {
  className: null,
};

export default TextVideo;
