import * as consultationComponents from '../../constants/consultationComponents';

import haircareIntroDesktop from 'assets/images/consultation/welcomeScreens/prose-custom-shampoo-and-conditioner-desktop.jpg';
import haircareIntroMobile from 'assets/images/consultation/welcomeScreens/prose-custom-shampoo-and-conditioner-mobile.jpg';
import haircareIntroTablet from 'assets/images/consultation/welcomeScreens/prose-custom-shampoo-and-conditioner-tablet.jpg';

const hairWelcome = {
  name: 'welcome',
  route: `/consultation/haircare`,
  Component: consultationComponents.Welcome,
  title: (
    <>
      We’re excited to get started on your <i>hair</i> consultation.
    </>
  ),
  subtitle:
    'We’ll ask a series of questions to better understand your lifestyle, environment, and unique hair needs so we can formulate your custom routine.',
  cta: 'Begin',
  image: {
    mobile: haircareIntroMobile,
    tablet: haircareIntroTablet,
    desktop: haircareIntroDesktop,
  },
  shouldBeIncluded: () => true,
  public: true,
  skipSave: true,
  showProgressTracker: false,
};

export default hairWelcome;
