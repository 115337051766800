import toString from 'lodash/fp/toString';
/*
This util was originally created because of a discrepancy coming from the API:

On the `/cart` endpoint, the API is sending us different values for `offerend_variant_type` for the same GWP:

- in `catalog` ==> `offered_variant_type` + `offered_variant_version`
(only if applicable, e.g. `_v2`)

- in `items` ==> `offered_variant_type` without the appended version
(`offered_variant_version` is a separated field)
*/

export const removeAppendedVersion = (str: string) =>
  toString(str).replace(
    /_v[0-9]{0,2}$/i, // remove '_v2' or similar at the end of the string
    '',
  );
