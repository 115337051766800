import facebookIcon from 'assets/images/icon_facebook.svg';
import instagramIcon from 'assets/images/icon_instagram.svg';
import pinterestIcon from 'assets/images/icon_pinterest.svg';
import twitterIcon from 'assets/images/icon_twitter.svg';

const company = {
  name: 'Prose',
  legalName: 'Prose Beauty Inc',
  email: 'founders@prose-hair.com',
  facebook: '//www.facebook.com/prosehair',
  instagram: '//www.instagram.com/prose',
  phone: '+33614194682',
  twitter: '//www.twitter.com/proseofficial',
  pinterest: 'https://www.pinterest.com/prosehair/',
  website: 'www.prose.com',
  locations: [
    {
      title: 'New York, USA',
      address1: '10 Grand Street',
      address2: '',
      city: 'Brooklyn',
      county: 'New York',
      state: 'NY',
      zip: '11249',
      coords: [40.7162248, -73.9663852],
    },
    {
      title: 'Paris, France',
      address1: '30 Rue de Gramont',
      address2: '4th floor',
      city: 'Paris',
      county: '',
      state: '',
      zip: '75002',
      coords: [48.871129, 2.337124],
    },
  ],
  social: [
    {
      name: 'facebook',
      url: '//www.facebook.com/prosehair',
      img: facebookIcon,
    },
    {
      name: 'instagram',
      url: '//www.instagram.com/prose',
      img: instagramIcon,
    },
    {
      name: 'pinterest',
      url: 'https://www.pinterest.com/prosehair/',
      img: pinterestIcon,
    },
    {
      name: 'twitter',
      url: '//www.twitter.com/proseofficial',
      img: twitterIcon,
    },
  ],
};

export default company;
