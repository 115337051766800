import { HAIRCARE_QUESTION_TYPE, MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

import { withSebumLevel } from '../utils';

const myHairType = {
  name: HAIRCARE_QUESTION_TYPE,
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/type`,
  title: 'Where would you put your hair on the oily/dry spectrum?',
  tip: {
    title: 'Why we ask:',
    content: `We know oiliness and dryness levels can vary throughout the year, so just answer with what you're feeling at this exact moment. We'll track the conditions in your area throughout the year and subtly adjust your formulas to anticipate the way your hair changes with the seasons.`,
  },
  options: [
    {
      value: 'oily',
      label: 'On the oily side',
      description: 'Roots get oily quickly, while lengths are balanced',
    },
    {
      value: 'normal',
      label: 'Medium',
      description: "Roots don’t get oily quickly, and lengths aren't dry either",
    },
    {
      value: 'oily_dry',
      label: 'Mixed',
      description: 'Roots get oily quickly while my lengths are dry or frizzy',
    },
    {
      value: 'dry',
      label: 'On the dry side',
      description: 'Roots don’t get oily quickly and my lengths are always dry or frizzy',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_hair_type,
  getAnswers: ({ selected, answers }) => withSebumLevel({ diag_hair_type: selected }, answers),
};

export default myHairType;
