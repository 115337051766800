import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import defaultImage from 'assets/images/promotionalModals/prose-assortment-of-haircare-and-skincare-products.jpg';
import haircareImage from 'assets/images/promotionalModals/prose-custom-hair-products.jpg';
import skincareImage from 'assets/images/promotionalModals/prose-custom-skincare-products-stacked.jpg';

const content = {
  contentDefault: {
    banner: 'Subscribe for 50% off haircare and skincare',
    popUpModal: {
      image: defaultImage,
      imageAlt: 'Prose assortment of custom haircare and skincare products',
      headerTitle: 'FIND YOUR FORMULA',
      title: 'Discover the custom difference',
      description: (
        <>
          500k 5-star product reviews*
          <br />
          Made with natural, clean, safe ingredients
          <br />
          Love your first order (or it’s on us)
        </>
      ),
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'trial-offer-v2-hc-sc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
        consultationNotCompleted: {
          linkTo: route => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'trial-offer-v2-hc-sc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'trial-offer-v2-hc-sc-pop-up',
      },
      conditions: (
        <>
          *on Review & Refine®
          <br />
          50% off your first subscription orders to haircare and skincare. Subsequent subscription
          orders will receive free shipping and 15% off. Cannot be applied to previous orders. Not
          valid for accessories or digital gift cards. Offer subject to expire.
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> haircare + skincare
          <br />+ <b>free shipping</b> when you subscribe
        </>
      ),
      conditions:
        '50% off your first subscription orders to haircare and skincare. Subsequent subscription orders will receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for accessories or digital gift cards. Offer subject to expire.',
    },
    checkoutCommunication: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> haircare + skincare
          <br />+ <b>free shipping</b> when you subscribe
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Subscribe for 50% off haircare',
    popUpModal: {
      image: haircareImage,
      imageAlt: 'Prose custom hair products',
      headerTitle: 'FIND YOUR FORMULA',
      title: 'Discover the custom difference',
      description: (
        <>
          500k 5-star product reviews*
          <br />
          Made with natural, clean, safe ingredients
          <br />
          Love your first order (or it’s on us)
        </>
      ),
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'trial-offer-v2-hc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
        consultationNotCompleted: {
          linkTo: route => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'trial-offer-v2-hc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'trial-offer-v2-hc-pop-up',
      },
      conditions: (
        <>
          *on Review & Refine®
          <br />
          50% off your first subscription order to haircare. Subsequent subscription orders will
          receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for
          accessories or digital gift cards. Offer subject to expire.
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> haircare + <b>free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      conditions:
        '50% off your first subscription order to haircare. Subsequent subscription orders will receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for accessories or digital gift cards. Offer subject to expire.',
    },
    checkoutCommunication: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> haircare + <b>free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Subscribe for 50% off skincare',
    popUpModal: {
      image: skincareImage,
      imageAlt: 'Prose custom skincare products',
      headerTitle: 'FIND YOUR FORMULA',
      title: 'Discover the custom difference',
      description: (
        <>
          500k 5-star product reviews*
          <br />
          Made with natural, clean, safe ingredients
          <br />
          Love your first order (or it’s on us)
        </>
      ),
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'trial-offer-v2-sc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'trial-offer-v2-sc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'trial-offer-v2-sc-pop-up',
      },
      conditions: (
        <>
          *on Review & Refine®
          <br />
          50% off your first subscription order to skincare. Subsequent subscription orders will
          receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for
          accessories or digital gift cards. Offer subject to expire.
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> skincare + <b>free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      conditions:
        '50% off your first subscription order to skincare. Subsequent subscription orders will receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for accessories or digital gift cards. Offer subject to expire.',
    },
    checkoutCommunication: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> skincare + <b>free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
