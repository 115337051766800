import { createABTestHook } from 'abTesting/hooks';
import { PostPurchasePopupExperiment } from 'abTesting/testConfigs';

import {
  getActiveOrVariantForPostPurchasePopupAbTest,
  isPostPurchasePopupAbTestInExperiment,
} from 'dux/featureFlags/selectors';

export const usePostPurchasePopupAbTest = createABTestHook({
  isInExperimentSelector: isPostPurchasePopupAbTestInExperiment,
  variantNameSelector: getActiveOrVariantForPostPurchasePopupAbTest,
  experimentConfig: PostPurchasePopupExperiment,
});
