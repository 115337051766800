import { breakpoints } from '@prose-ui/foundations/grid';

/**
 * @example
 * `
 * font-size: 14px;
 * ${mediaMinWidth('sm')} {
 *   font-size: 16px;
 * }
 * `
 */
export const mediaMinWidth = (breakpoint: keyof typeof breakpoints) =>
  `@media (min-width: ${breakpoints[breakpoint]})`;
