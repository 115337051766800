import { MY_HAIR } from '../constants';
import { HAIR_SCALP } from '../constants/progressCategories';

import thicknessFineImageUrl from 'assets/images/consultation_thickness_fine.png';
import thicknessMediumImageUrl from 'assets/images/consultation_thickness_medium.png';
import thicknessThickImageUrl from 'assets/images/consultation_thickness_thick.png';

const myHairThickness = {
  name: 'thickness',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/thickness`,
  title: 'What does a single strand of hair feel like?',
  subtitle: 'Roll a single hair between your fingers. If you…',
  tip: {
    title: 'Why we ask:',
    content:
      'This info about individual hair thickness is crucial to supporting overall strength and addressing frizz and flyaways.',
  },
  options: [
    {
      value: 'thin',
      label: 'Barely feel it = fine/thin hair',
      imageUrl: thicknessFineImageUrl,
    },
    {
      value: 'average',
      label: 'Not sure or feel it slightly = medium hair',
      imageUrl: thicknessMediumImageUrl,
    },
    {
      value: 'thick',
      label: 'Feels like sewing thread = thick/coarse hair',
      imageUrl: thicknessThickImageUrl,
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_hair_thickness,
  getAnswers: ({ selected }) => ({ diag_hair_thickness: selected }),
};

export default myHairThickness;
