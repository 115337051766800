import * as Sentry from '@sentry/nextjs';
import jwtDecode from 'jwt-decode';

export const AUTH_KEY = 'authentication';
export const GUEST_AUTH_KEY = 'guest-authentication';

export type JWTResponse = { access: string; refresh: string };
export type JWT = { accessToken: string; refreshToken: string };

/**
 * storage
 */

export const getTokens = () => {
  try {
    const value = window.localStorage.getItem(AUTH_KEY);
    return (value && (JSON.parse(value) as JWT)) || null;
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      if (e instanceof Error) {
        Sentry.captureMessage(`[Auth getTokens] error: ${e.toString()}`);
      }
    });
  }
  return null;
};

export const setTokens = (auth: JWT) => window.localStorage.setItem(AUTH_KEY, JSON.stringify(auth));

export const clearTokens = () => {
  window.localStorage.removeItem(AUTH_KEY);
  window.localStorage.removeItem(GUEST_AUTH_KEY);
};

export const getAccessToken = () => {
  const tokens = getTokens();
  return tokens && tokens.accessToken;
};

export const getGuestTokens = () => {
  try {
    const value = window.localStorage.getItem(GUEST_AUTH_KEY);
    return (value && (JSON.parse(value) as JWT)) || null;
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      if (e instanceof Error) {
        Sentry.captureMessage(`[AuthService getGuestAuth] error: ${e.toString()}`);
      }
    });
    return null;
  }
};

export const setGuestTokens = (auth: JWT) =>
  window.localStorage.setItem(GUEST_AUTH_KEY, JSON.stringify(auth));

/**
 * utils
 */

export const isTokenExpired = (token: string) => {
  try {
    const payload = jwtDecode<{ exp: number }>(token);
    const epoch = Math.round(Date.now() / 1000);
    return payload.exp <= epoch;
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      if (err instanceof Error) {
        Sentry.captureMessage(`[Auth isTokenExpired] error: ${err.toString()}`);
      }
    });
    return true;
  }
};

// sync check if cookie is present
// helpful to define synchronously if there is a chance use can be authenticated
export const isCookied = () => {
  const auth = getTokens();
  return Boolean(auth);
};

/**
 * errors
 */

export class AuthError extends Error {
  override name = 'AuthError';
}

export class AuthRefreshError extends AuthError {
  override name = 'AuthRefreshError';
}
