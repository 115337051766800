import { MY_TREATMENTS } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsStyles = {
  name: 'styles',
  category: MY_TREATMENTS,
  Component: consultationComponents.RoutineProducts,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/styles`,
  multi: true,
  title: 'What products and styling tools do you use?',
  subtitle: 'Select all that apply, even ones you use infrequently.',
  tip: {
    title: 'Why we ask:',
    content:
      'This tells us what level of heat protection you need, how much build-up you might be experiencing, and helps us create a personalized routine that factors in your styling and product preferences.',
  },
  answersGroup: [
    { title: 'CLEANSE + CONDITION', slug: 'cleanse' },
    { title: 'STYLING PRODUCTS + TOOLS', slug: 'styling' },
    { title: 'TREATMENTS', slug: 'treatments' },
  ],
  options: [
    { label: 'Shampoo', value: 'shampoo', answersGroupSlug: 'cleanse' },
    { label: 'Conditioner', value: 'conditioner', answersGroupSlug: 'cleanse' },
    { label: 'Leave-in Conditioner', value: 'leavein_conditioner', answersGroupSlug: 'cleanse' },
    { label: 'Straightening iron', value: 'straightener', answersGroupSlug: 'styling' },
    { label: 'Curling iron / wand', value: 'curling', answersGroupSlug: 'styling' },
    { label: 'Blow dryer', value: 'hair_dryer', answersGroupSlug: 'styling' },
    { label: 'Hairspray', value: 'hairspray', answersGroupSlug: 'styling' },
    { label: 'Curl Cream', value: 'curl_cream', answersGroupSlug: 'styling' },
    { label: 'Paste / Pomade', value: 'paste_pomade', answersGroupSlug: 'styling' },
    { label: 'Mousse', value: 'mousse', answersGroupSlug: 'styling' },
    { label: 'Gel / Jelly', value: 'gel_jelly', answersGroupSlug: 'styling' },
    { label: 'Dry Shampoo', value: 'dry_shampoo', answersGroupSlug: 'styling' },
    { label: 'Hair Oil', value: 'hair_oil', answersGroupSlug: 'styling' },
    { label: 'Scalp Mask', value: 'scalp_mask', answersGroupSlug: 'treatments' },
    { label: 'Hair Mask', value: 'hair_mask', answersGroupSlug: 'treatments' },
    { label: 'Scalp Serum', value: 'scalp_serum', answersGroupSlug: 'treatments' },
    { label: 'Hair Butter', value: 'hair_butter', answersGroupSlug: 'treatments' },
    {
      label: 'Hair Vitamins & Supplements',
      value: 'hair_supplements',
      answersGroupSlug: 'treatments',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_styling,
  getAnswers: ({ selected }) => ({ diag_styling: selected }),
};

export default myTreatmentsStyles;
