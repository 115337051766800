import { productsCategories, productsSlugs } from 'constants/products';

import squaredCleanserPicture from 'assets/images/homeRevamp/prose-custom-cleanser.jpg';
import squaredCleanserPictureHover from 'assets/images/homeRevamp/prose-custom-cleanser-texture.jpg';
import squaredMoisturizerPicture from 'assets/images/homeRevamp/prose-custom-moisturizer.jpg';
import squaredMoisturizerPictureHover from 'assets/images/homeRevamp/prose-custom-mositurizer-texture.jpg';
import squaredSerumPicture from 'assets/images/homeRevamp/Prose-custom-serum.jpg';
import squaredSerumPictureHover from 'assets/images/homeRevamp/prose-custom-serum-texture.jpg';
import bgPictureCleanser from 'assets/images/productPages/prose-custom-cleanser.jpg';
import bgPictureCleanserHover from 'assets/images/productPages/prose-custom-cleanser-texture.jpg';
import bgPictureMoisturizer from 'assets/images/productPages/prose-custom-moisturizer.jpg';
import bgPictureMoisturizerHover from 'assets/images/productPages/prose-custom-moisturizer-in-use.jpg';
import bgPictureSerum from 'assets/images/productPages/prose-custom-serum.jpg';
import bgPictureSerumHover from 'assets/images/productPages/prose-custom-serum-dropper.jpg';
import cleanserPicture from 'assets/images/products/prose-custom-cleanser-bottle.png';
import cleanserPictureCap from 'assets/images/products/prose-custom-cleanser-bottle-cap.png';
import moisturizerPicture from 'assets/images/products/prose-custom-moisturizer-jar.png';
import serumPicture from 'assets/images/products/prose-custom-serum-bottle.png';
import serumPictureCap from 'assets/images/products/prose-custom-serum-bottle-cap.png';

import cleanserMiniPicture from '../images/checkout_cleanser_mini.png';
import moisturizerMiniPicture from '../images/checkout_moisturizer_mini.png';
import serumMiniPicture from '../images/checkout_serum_mini.png';

export const cleanser_mini = { label: 'Cleanser', picture: cleanserMiniPicture };
export const serum_mini = { label: 'Serum', picture: serumMiniPicture };
export const moisturizer_mini = { label: 'Moisturizer', picture: moisturizerMiniPicture };

export const cleanser = {
  capacity: '5.07 fl oz',
  cat: 'core',
  description:
    'A gentle yet effective face cleanser tailored to your unique skin goals and skin type, including oily, sensitive, and dry. Formulated at a low pH to support a healthy skin barrier and scientifically proven to preserve skin hydration.*',
  label: 'Cleanser',
  picture: cleanserPicture,
  pictureLabels: [
    {
      descriptionBold: '95%',
      description: 'say their skin feels soft\u00a0¹',
    },
    {
      descriptionBold: '91%',
      description: 'say their skin looks clear\u00a0¹',
    },
  ],
  pictureWithCap: cleanserPictureCap,
  bgPicture: bgPictureCleanser,
  bgLabelledPicture: bgPictureCleanserHover,
  bgPictureAlt: 'Prose custom cleanser packaging',
  productSquaredPicture: squaredCleanserPicture,
  productSquaredPictureHover: squaredCleanserPictureHover,
  productPageUrl: '/products/custom-cleanser',
  price: 29,
  subscribed_price: 24.65,
  currency: 'USD',
  is_subscribable: true,
  slug: productsSlugs.CLEANSER,
  tag: 'Cleanse + Balance',
  type: productsCategories.SKINCARE,
  usage: 'lorem ipsum dolor sit amet',
  usageB: 'Daily',
  cardTag: 'New',
  keyBenefits: (
    <>
      Non-stripping formula
      <br />
      Dermatologist tested + approved
      <br />
      Suitable for all skin types, even sensitive
    </>
  ),
  disclaimer: '*Instrumental evaluation of 10 subjects after single product application',
};

export const serum = {
  capacity: '1 fl oz',
  cat: 'core',
  description:
    'A dewy, highly concentrated face serum for intensive care tailored to your specific skin concerns. Clinically proven to hydrate skin, boost radiance, and visibly reduce the appearance of fine lines.*',
  label: 'Serum',
  picture: serumPicture,
  pictureLabels: [
    {
      descriptionBold: '91%',
      description: 'say their skin is firmer\u00a0²',
    },
    {
      descriptionBold: '82%',
      description: 'say wrinkle appearance is reduced\u00a0²',
    },
  ],
  pictureWithCap: serumPictureCap,
  bgPicture: bgPictureSerum,
  bgLabelledPicture: bgPictureSerumHover,
  bgPictureAlt: 'Prose custom serum packaging',
  productSquaredPicture: squaredSerumPicture,
  productSquaredPictureHover: squaredSerumPictureHover,
  productPageUrl: '/products/custom-serum',
  price: 64,
  slug: productsSlugs.SERUM,
  tag: 'Brighten + Replenish',
  subscribed_price: 54.4,
  currency: 'USD',
  is_subscribable: true,
  type: productsCategories.SKINCARE,
  usage: 'lorem ipsum dolor sit amet',
  usageB: 'Daily',
  cardTag: 'New',
  keyBenefits: (
    <>
      Fast-absorbing formula
      <br />
      Dermatologist tested + approved
      <br />
      Suitable for all skin types, even sensitive
    </>
  ),
  disclaimer:
    '*Based on a 4-week clinical study of 22 subjects with sensitive skin aged from 45 to 65 years old who tested Prose serum twice a day',
};

export const moisturizer = {
  capacity: '1.7 fl oz',
  cat: 'core',
  description:
    'A nourishing face moisturizer that targets your specific skin needs, providing powerful protection against environmental stressors. Scientifically proven to provide 8-hour hydration* and boost skin firmness and elasticity.†',
  label: 'Moisturizer',
  picture: moisturizerPicture,
  pictureLabels: [
    {
      descriptionBold: '100%',
      description: 'say their skin is hydrated\u00a0³',
    },
    {
      descriptionBold: '92%',
      description: 'say their skin looks healthy\u00a0⁴',
    },
  ],
  bgPicture: bgPictureMoisturizer,
  bgLabelledPicture: bgPictureMoisturizerHover,
  bgPictureAlt: 'Prose custom moisturizer packaging',
  productSquaredPicture: squaredMoisturizerPicture,
  productSquaredPictureHover: squaredMoisturizerPictureHover,
  productPageUrl: '/products/custom-moisturizer',
  price: 52,
  subscribed_price: 44.2,
  currency: 'USD',
  is_subscribable: true,
  slug: productsSlugs.MOISTURIZER,
  tag: 'Hydrate + Protect',
  type: productsCategories.SKINCARE,
  usage: 'lorem ipsum dolor sit amet',
  usageB: 'Daily',
  cardTag: 'New',
  keyBenefits: (
    <>
      Fast-absorbing formula
      <br />
      Dermatologist tested + approved
      <br />
      Suitable for all skin types, even sensitive
    </>
  ),
  disclaimer: (
    <>
      *Instrumental evaluation of 10 subjects after single Prose moisturizer application
      <br />
      †Based on a 4-week clinical study of 22 subjects with sensitive skin aged from 45 to 65 years
      old that tested Prose moisturizer twice a day
    </>
  ),
};

export const productSlugToProductSkincare = {
  [productsSlugs.SERUM]: serum,
  [productsSlugs.CLEANSER]: cleanser,
  [productsSlugs.MOISTURIZER]: moisturizer,
  [productsSlugs.SERUM_MINI]: serum_mini,
  [productsSlugs.CLEANSER_MINI]: cleanser_mini,
  [productsSlugs.MOISTURIZER_MINI]: moisturizer_mini,
};
