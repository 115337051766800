import { MY_HAIR } from '../constants';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { HAIR_SCALP } from '../constants/progressCategories';

import tipGrayFew from 'assets/images/consultation/gray/few.jpg';
import tipGrayFull from 'assets/images/consultation/gray/full.jpg';
import tipGrayHalf from 'assets/images/consultation/gray/half.jpg';

const myHairGray = {
  name: 'gray',
  category: MY_HAIR,
  progressCategory: HAIR_SCALP,
  route: `/consultation/haircare/${MY_HAIR}/gray`,
  title: 'How much of your hair is gray?',
  subtitle: 'If your hair is color-treated, answer with your natural gray percentage.',
  tip: {
    title: 'More of a visual learner? Tap for pics',
    richContent: {
      Component: consultationTipsComponents.ListOfImages,
      images: [
        {
          alt: 'Picture of hair with a few grays',
          legend: '10-30%',
          src: tipGrayFew,
        },
        {
          alt: 'Picture of hair with about half gray',
          legend: '40-60%',
          src: tipGrayHalf,
        },
        {
          alt: 'Picture of hair with all or nearly-all gray',
          legend: '70-100%',
          src: tipGrayFull,
        },
      ],
    },
  },
  options: [
    { value: 'few', label: '10-30%', description: 'A few grays' },
    { value: 'half', label: '40-60%', description: 'About half gray' },
    { value: 'full', label: '70-100%', description: 'All or nearly-all gray' },
    { value: 'none', label: 'None' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_gray_hair,
  getAnswers: ({ selected }) => ({ diag_gray_hair: selected }),
};

export default myHairGray;
